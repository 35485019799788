import { get, has, isEqual } from 'lodash';

import { AccurityFilterType, Column, ColumnTogglerItemType,FilterValue } from 'ts-components';

import { AccurityFilter, AccurityPinFilter, AccuritySearch, AccuritySort, AccuritySortType, EmailNotificationActionType } from '../types/accurityTypes';
import { ColumnFilterData, ColumnSettings, ListSettings } from './types/types';
import { BUSINESS_TERM_TYPE } from '../../businessGlossary/businessTerm/types/businessTermTypes';
import { DATA_FIELD_TYPE } from '../../businessGlossary/dataField/types/dataFieldTypes';
import { DATA_STRUCTURE_TYPE } from '../../businessGlossary/dataStructure/types/dataStructureTypes';
import { DATA_SET_TYPE } from '../../businessGlossary/dataSet/types/dataSetTypes';
import { CUSTOM_PROPERTY_TYPE } from '../customProperties/customProperty/types/customPropertyTypes';
import { CUSTOM_PROPERTY_GROUP_TYPE } from '../customProperties/customPropertyGroup/types/customPropertyGroupTypes';
import { useCustomPropertyColumns } from '../customProperties/customPropertyHooks';
import { useHasFeature } from '../userSettings/hooks/features';
import { Feature } from '../userSettings/features/features';
import { REQUIREMENT_TYPE } from '../../businessGlossary/requirement/types/requirementTypes';
import { ATTRIBUTE_TYPE } from '../../businessGlossary/attribute/types/attributeTypes';
import { ENTITY_TYPE } from '../../businessGlossary/entity/types/entityTypes';
import { ATTRIBUTE_DEFINITION_TYPE } from '../../businessGlossary/attributeDefinition/types/attributeDefinitionTypes';
import { COMPOSITE_TYPE_TYPE } from '../../businessGlossary/compositeType/types/compositeTypeTypes';
import { VALUE_TYPE_TYPE } from '../../businessGlossary/valueType/types/valueTypeTypes';

export const transformColumnsForToggler = (columns: Column[]): ColumnTogglerItemType[] => columns.map((column, index) =>
  ({ ...column, defaultOrder: index }));

export const getDefaultListSettings = (type: string, columns: Column[], customSort?: AccuritySort): ListSettings => ({
    type: type,
    pinFilters: [],
    columnSettings: columns.map(columnSettingFromColumn),
    maxResults: 10,
    sort: customSort || {
      property: 'name',
      type: AccuritySortType.ASCENDING,
    },
  }
);

const columnSettingFromColumn = (column: Column): ColumnSettings => ({
  name: column.name,
  hidden: column.hidden,
  width: column.width,
});

export const mergeStoredListSettingsOntoDefault = (defaultListSettings: ListSettings, storedListSetting?: ListSettings): ListSettings => {
  if (!storedListSetting) {
    return defaultListSettings;
  }

  const mergedColumnSettings: ColumnSettings[] = mergeColumnSettings(defaultListSettings.columnSettings, storedListSetting.columnSettings);

  // Revert any settings about properties that no longer exist (eg. property removed from object type after app update or after CustomProperty deletion)
  const validColumnProperties = mergedColumnSettings.map(setting => setting.name);
  const mergedSortSetting = storedListSetting.sort && validColumnProperties.includes(storedListSetting.sort.property)
    ? storedListSetting.sort
    : defaultListSettings.sort;
  const mergedPinFilters = storedListSetting.pinFilters
    ? storedListSetting.pinFilters.filter(pinFilter => validColumnProperties.includes(pinFilter.property))
    : defaultListSettings.pinFilters;

  return {
    ...defaultListSettings,
    ...storedListSetting,
    columnSettings: mergedColumnSettings,
    sort: mergedSortSetting,
    pinFilters: mergedPinFilters,
  };
};

const mergeColumnSettings = (defaultColumnSettings: ColumnSettings[], rememberedColumnSettings: ColumnSettings[] = []): ColumnSettings[] => {
  if (rememberedColumnSettings.length === 0) {
    return defaultColumnSettings;
  }
  const mutableRememberedColumnSettings = [...rememberedColumnSettings];

  // Add default Setting in correct order if was added to List definition
  const rememberedNames = mutableRememberedColumnSettings.map(colSetting => colSetting.name);
  defaultColumnSettings.forEach((defaultSetting, index) => {
    if (!rememberedNames.includes(defaultSetting.name)) {
      mutableRememberedColumnSettings.splice(index, 0, defaultSetting);
    }
  });

  // Remove remembered Setting if was removed from List definition, and
  // return remembered settings merged over default settings, sorted in order of remembered settings
  const defaultNames = defaultColumnSettings.map(setting => setting.name);
  return mutableRememberedColumnSettings.filter(setting => defaultNames.includes(setting.name))
    .map((rememberedSetting) => {
      const defaultSetting = defaultColumnSettings.find(defSetting => defSetting.name === rememberedSetting.name);
      return {
        ...defaultSetting,
        ...rememberedSetting,
      }
    });
};

const getInitialFilterSettings = (initialColumns: Column[], column: Column): ColumnFilterData | undefined => {
  const initialColumn = initialColumns.find(q => q.name === column.name);
  return initialColumn ? {
    columnName: initialColumn.name,
    filter: initialColumn.filter,
    filterType: initialColumn.filterType,
    filterValueProperty: initialColumn.filterValueProperty,
    searchProperty: initialColumn.searchProperty
  } as ColumnFilterData : undefined;
};

export const mergeSettingsAndFiltersOntoColumns = (currentColumns: Column[], initialColumns: Column[], columnSettings: ColumnSettings[], columnFilters: ColumnFilterData[], currentSort: AccuritySort): Column[] =>
  columnSettings.map(settings => {
    const column = currentColumns.find(column => column.name === settings.name);
    // use initial filter settings if there is not filter set (e.g. user did not type to search field)
    const filter = columnFilters.find(filter => filter.columnName === settings.name) || getInitialFilterSettings(initialColumns, column as Column);
    const sort = currentSort.property === settings.name ? currentSort : undefined;
    return {
      ...column,
      ...settings,
      ...filter,
      sort: sort?.type,
    } as Column;
  });

export const assembleParametersForSearch = (
  listType: string,
  columnFilterData: ColumnFilterData[],
  referenceFieldFilters: AccurityFilter[],
  globalPinFilters: AccurityPinFilter[],
  pinFilters: AccurityPinFilter[],
  startFrom: number,
  maxResults: number,
  sort: AccuritySort)
  : AccuritySearch => {

  const columnFilters = columnFilterData.map(columnFilterDataToAccurityFilter);
  const validGlobalPinFilters = globalPinFilters.filter(globalPin => isValidGlobalPinFilterForListType(globalPin, listType));
  const enabledPinFilters = [...validGlobalPinFilters, ...pinFilters].filter(pinFilter => !pinFilter.disabled);
  const filters: AccurityFilter[] = [...columnFilters, ...enabledPinFilters, ...referenceFieldFilters];

  return {
    filters: filters,
    startFrom: startFrom,
    maxResults: maxResults,
    sort: sort,
  }
};

export const columnFilterDataToAccurityFilter = (filterData: ColumnFilterData): AccurityFilter => {
  const property = filterData.searchProperty ?? filterData.columnName;

  let filterValue = '';
  if (filterData.filter) {
    if (filterData.filterValueProperty && has(filterData.filter, filterData.filterValueProperty)) {
      filterValue = get(filterData.filter, filterData.filterValueProperty);
    } else {
      filterValue = String(filterData.filter);
    }
  }

  return ({
    type: filterData.filterType ?? AccurityFilterType.SIMPLE_QUERY,
    property,
    value: filterValue,
    timezone: filterData.timezone,
  })
};

export const changeFilterData = (filters: ColumnFilterData[], column: Column, filterValue?: FilterValue, timezone?: string) => {
  const newFilters = filters.filter(filter => !(filter.columnName === column.name && filter.filterValueProperty === column.filterValueProperty));
  if (filterValue) {
    newFilters.push({
      columnName: column.name,
      searchProperty: column.searchProperty,
      filterValueProperty: column.filterValueProperty,
      filter: filterValue,
      timezone: timezone,
      filterType: column.filterType,
    })
  }
  return newFilters;
};

export const areFiltersEqual = (oldFilters: ColumnFilterData[], newFilters: ColumnFilterData[]) => {
  return oldFilters.length === newFilters.length && newFilters.every(newFilter =>
    oldFilters.findIndex(oldFilter => isEqual(oldFilter, newFilter)) !== -1
  );
};

export const isValidGlobalPinFilterForListType = (pinFilter: AccurityPinFilter, listType?: string): boolean => {
  // TODO this should be attached to List definitions or otherwise injected from Business Glossary, but it would mean
  //  some awkward passing to share data from list to app bar. Because it seems like Global Filters will not
  //  survive much longer anyway, I am taking the path of least resistance here and using constants from BuGlo.
  const validPropertiesForListType = () => {
    switch (listType) {
      case BUSINESS_TERM_TYPE:
        return [BUSINESS_TERM_TYPE, REQUIREMENT_TYPE];
      case DATA_FIELD_TYPE:
        return [BUSINESS_TERM_TYPE, DATA_SET_TYPE, DATA_STRUCTURE_TYPE];
      case DATA_STRUCTURE_TYPE:
        return [BUSINESS_TERM_TYPE, DATA_SET_TYPE];
      case CUSTOM_PROPERTY_TYPE:
        return [CUSTOM_PROPERTY_GROUP_TYPE];
      case ATTRIBUTE_TYPE:
        return [REQUIREMENT_TYPE, ATTRIBUTE_TYPE, ENTITY_TYPE, ATTRIBUTE_DEFINITION_TYPE];
      case ATTRIBUTE_DEFINITION_TYPE:
        return [BUSINESS_TERM_TYPE, ENTITY_TYPE, COMPOSITE_TYPE_TYPE, VALUE_TYPE_TYPE, ATTRIBUTE_DEFINITION_TYPE];
      case ENTITY_TYPE:
        return [BUSINESS_TERM_TYPE, ENTITY_TYPE];
      case COMPOSITE_TYPE_TYPE:
        return [ENTITY_TYPE, VALUE_TYPE_TYPE];
      default:
        return [];
    }
  };

  return validPropertiesForListType().includes(pinFilter.property);
};

/**
 * Small util hook which returns currently valid List Columns for an object type
 *
 * Starts with the default set of columns for an object type, and
 *  - adds columns for each custom property applied to that object type
 *  - removes columns which belong to features that are not enabled for the current user
 *
 */
export const useValidColumns = (initialColumns: Column[], customPropertyObjectType: string, prefix?: string, group?: string, icon?: string): Column[] => {
  const hasFeature = useHasFeature();
  const customPropertyColumns = useCustomPropertyColumns(customPropertyObjectType, prefix, group, icon);

  const allColumns = [
    ...initialColumns,
    ...customPropertyColumns
  ];

  return filterOutInaccessibleColumns(allColumns, hasFeature);
};

const filterOutInaccessibleColumns = (columns: Column[], hasFeature: (feature: string) => boolean): Column[] => {
  if (!hasFeature(Feature.COMMENTS)) {
    columns = columns.filter(q => !q.name.endsWith(EmailNotificationActionType.COMMENT_UPDATE));
  }
  if (!hasFeature(Feature.PHYSICAL_DATA_SETS)) {
    columns = columns.filter(q => !q.name.endsWith('dataSource.name'));
  }
  if (!hasFeature(Feature.REQUIREMENTS)) {
    columns = columns.filter(q => !q.name.endsWith('requirements.name'));
  }
  return columns;
};
