import { CoreEntityState } from '../../../common/redux/types';
import { AccurityCoreEntity, AccurityReference } from '../../../common/types/accurityTypes';
import { CustomPropertyValues } from '../../../common/customProperties/customProperty/types/customPropertyTypes';
import { WithChildrenCounts } from '../../../common/childrenCountField/types';
import { EnumOption, Tag } from 'ts-components';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Feature } from '../../../common/userSettings/features/features';
import { DataCatalogSyncResult, HasSyncResult } from '../../../common/dataCatalogSync/types';
import { DataSourceReference } from '../../dataSource/types/dataSourceTypes';

export const DATA_SET_TYPE = 'data-set';
export const DATA_SET_LABEL = 'Data Set';
export const DATA_SET_TYPE_LABEL = 'Data Set Type';
export const DATA_SET_PLURAL_LABEL = 'Data Sets';
export const DATA_SET_ICON = 'dataSet';
export const DATA_SET_FIELD_NAME = 'dataSet';

export interface DataSetState extends CoreEntityState<DataSet> {
}

export enum DataSetType {
  LOGICAL = 'LOGICAL',
  PHYSICAL = 'PHYSICAL'
}

export const useDataSetTypeEnumOptions = (): EnumOption[] => {
  const hasFeature = useHasFeature();

  return [
    ...hasFeature(Feature.LOGICAL_DATA_SETS) ?
      [{ value: DataSetType.LOGICAL, label: 'Logical' }] : [],
    ...hasFeature(Feature.PHYSICAL_DATA_SETS) ?
      [{ value: DataSetType.PHYSICAL, label: 'Physical' }] : [],
  ];
};

export interface DataSet extends AccurityCoreEntity, WithChildrenCounts, HasSyncResult {
  status: AccurityReference;
  dataSetType: DataSetType;
  dataSource: AccurityReference;
  tags: Tag[];
  customPropertyValues: CustomPropertyValues;
  syncResult?: DataCatalogSyncResult
}

export interface DataSetReference extends AccurityReference {
  dataSetType: DataSetType;
  dataSource: DataSourceReference;
}

export const isDataSet = (object: unknown): object is DataSet => {
  return (object as DataSet).objectType === DATA_SET_TYPE;
};