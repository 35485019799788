import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import React from 'react';
import {
  BUSINESS_MODEL_MAPPING_BASED_ON_LABEL,
  BUSINESS_MODEL_MAPPING_BUSINESS_MODEL_MAPPING_FIELD,
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_TYPE,
  BusinessModelMapping,
  BusinessModelMappingType,
  BusinessModelMappingTypeOptions
} from '../types/businessModelMappingTypes';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../common/detail/types/types';
import { FormikProps } from 'formik';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import { useDataSetTypeEnumOptions } from '../../dataSet/types/dataSetTypes';
import EnumerationFieldWithFormik from '../../../common/detail/formik/EnumerationFieldWithFormik';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import BasedOnEntity from './basedOnEntity/BasedOnEntity';
import BasedOnAttribute from './basedOnAttribute/BasedOnAttribute';
import BasedOnDataStructure from './basedOnDataStructure/BasedOnDataStructure';
import BasedOnDataField from './basedOnDataField/BasedOnDataField';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import { resetValuesOnBusinessModelMappingTypeChange } from './businessModelMappingDetailUtils';
import { DetailHeader, EnumOption } from 'ts-components';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface BusinessModelMappingDetailProps {
  id?: string,
}

const BusinessModelMappingDetail = ({ id }: BusinessModelMappingDetailProps) => {

  const hasFeature = useHasFeature();
  const multipleDataSetTypesAllowedFeature = hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED);
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  return (
    <AccurityDetailContainer<BusinessModelMapping>
      objectType={BUSINESS_MODEL_MAPPING_TYPE}
      id={id}
    >
      {(formik, detailBag) => {
        const businessModelMappingDetailBag: DetailBag<BusinessModelMapping> = {
          ...detailBag,
          copyAction: () => detailBag.copyAction({ name: undefined })
        };

        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, businessModelMappingDetailBag);
        } else {
          return (
            <>
              {getDetailFields(formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions)}
              <DefaultDetailBottomBar objectType={BUSINESS_MODEL_MAPPING_TYPE} detailBag={businessModelMappingDetailBag}/>
            </>
          );
        }
      }}

    </AccurityDetailContainer>
  )
};

const getDetailFields = (formik: FormikProps<BusinessModelMapping>,
                         multipleDataSetTypesAllowedFeature: boolean,
                         dataSetTypeEnumOptions: EnumOption[]) => {


  return (
    <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
      <DetailHeader iconName={BUSINESS_MODEL_MAPPING_ICON}>
        <NameFieldWithFormik
          label={'Business Model Mapping ID'}
          readOnly={true}
        />
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={BUSINESS_MODEL_MAPPING_TYPE}/>
      <EnumerationFieldWithFormik
        name={BUSINESS_MODEL_MAPPING_BUSINESS_MODEL_MAPPING_FIELD}
        label={BUSINESS_MODEL_MAPPING_BASED_ON_LABEL}
        options={BusinessModelMappingTypeOptions}
        readOnly={formik.status.isUpdateDetail}
        onChange={() => {
          resetValuesOnBusinessModelMappingTypeChange(formik, true);
        }}
      />
      {formik.values.businessModelMappingType && formik.values.businessModelMappingType === BusinessModelMappingType.ENTITY &&
      <BasedOnEntity
        formik={formik}
        multipleDataSetTypesAllowedFeature={multipleDataSetTypesAllowedFeature}
        dataSetTypeEnumOptions={dataSetTypeEnumOptions}
      />}
      {formik.values.businessModelMappingType && formik.values.businessModelMappingType === BusinessModelMappingType.ATTRIBUTE &&
      <BasedOnAttribute
        formik={formik}
        multipleDataSetTypesAllowedFeature={multipleDataSetTypesAllowedFeature}
        dataSetTypeEnumOptions={dataSetTypeEnumOptions}
      />}
      {formik.values.businessModelMappingType && formik.values.businessModelMappingType === BusinessModelMappingType.DATA_STRUCTURE &&
      <BasedOnDataStructure
        formik={formik}
        multipleDataSetTypesAllowedFeature={multipleDataSetTypesAllowedFeature}
        dataSetTypeEnumOptions={dataSetTypeEnumOptions}
      />}
      {formik.values.businessModelMappingType && formik.values.businessModelMappingType === BusinessModelMappingType.DATA_FIELD &&
      <BasedOnDataField
        formik={formik}
        multipleDataSetTypesAllowedFeature={multipleDataSetTypesAllowedFeature}
        dataSetTypeEnumOptions={dataSetTypeEnumOptions}
      />}
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.BUSINESS_MODEL_MAPPING}/>
    </FeatureChecker>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<BusinessModelMapping>, detailBag: DetailBag<BusinessModelMapping>) => (
  <ConfirmRemovalDetail
    iconName={BUSINESS_MODEL_MAPPING_ICON}
    detailBag={detailBag}
  >

  </ConfirmRemovalDetail>
);

export default BusinessModelMappingDetail;
