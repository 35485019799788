import React from 'react';
import AccurityDetailContainer from '../detail/redux/AccurityDetailContainer';
import {
  ROLE_FIELD_NAME,
  ROLE_ICON,
  ROLE_LABEL,
  ROLE_TYPE,
  User,
  USER_ICON,
  USER_TYPE
} from '../../businessGlossary/user/types/userTypes';
import { AccurityButton, DetailBottomBar, DetailHeader } from 'ts-components';
import SingleLineFieldWithFormik from '../detail/formik/SinglelineFieldWithFormik';
import EnumerationFieldWithFormik from '../detail/formik/EnumerationFieldWithFormik';
import { useDispatch, useSelector } from 'react-redux';
import { useAccurityNavigation } from '../navigation/hooks';
import { USER_PROFILE } from './types';
import { clearAllUISettingsInStore, updateUserProfileInStore } from '../userSettings/redux/userSettingsSlice';
import { timezoneOptions } from '../userSettings/types/timezones';
import CollectionFieldWithFormik from '../detail/formik/CollectionFieldWithFormik';
import ReferenceFieldWithFormik from '../detail/formik/ReferenceFieldWithFormik';
import { useHasFeature } from '../userSettings/hooks/features';
import { Feature } from '../userSettings/features/features';
import { CustomReloadSteps } from '../detail/actions/detailReload';
import { CustomSaveSteps, getDefaultSaveSteps } from '../detail/actions/detailSave';
import { setDetailOpenedAt } from '../redux/commonReducers';
import { usePermissionsForObjectType } from '../userSettings/hooks/permissions';
import { createUniqueWithinCollectionFilters } from '../referenceField/utils/filters';
import { getRolesLabel } from '../../businessGlossary/user/detail/UserDetail';
import {
  disableEmailNotificationsForUser,
  restoreDefaultSettings
} from '../../businessGlossary/user/redux/userActions';
import DetailTopBar from '../detail/components/DetailTopBar';

const UserProfileDetail = () => {
  const dispatch = useDispatch();
  const navigationController = useAccurityNavigation();
  const userId = useSelector((state: any) => state.userSettings.id);
  const hasFeature = useHasFeature();

  const isEmailEditable = hasFeature(Feature.ALWAYS_EDITABLE_EMAIL);
  const hasUsersManagementFeature = hasFeature(Feature.USERS_MANAGEMENT);

  const {
    hasUpdatePermission,
  } = usePermissionsForObjectType(USER_TYPE);

  const customSaveSteps: CustomSaveSteps<User> = {
    addSavedEntityToStore: (savedUser: User) => {
      getDefaultSaveSteps<User>().addSavedEntityToStore(savedUser);
      dispatch(updateUserProfileInStore(savedUser));
    },
    refreshDetail: () => {
      // User Profile is only case where "Detail Type" and "Object Type" are different, therefore we must
      // set the detailOpenedAt manually and then refresh the Detail manually
      dispatch(setDetailOpenedAt({ objectType: USER_TYPE, openedAt: Date.now() }));
      navigationController.openDetail(USER_PROFILE);
    },
  };

  const customReloadSteps: CustomReloadSteps<User> = {
    refreshDetail: () => {
      // User Profile is only case where "Detail Type" and "Object Type" are different, therefore we must
      // set the detailOpenedAt manually and then refresh the Detail manually
      dispatch(setDetailOpenedAt({ objectType: USER_TYPE, openedAt: Date.now() }));
      navigationController.openDetail(USER_PROFILE)
    },
  };

  const restoreDefaultUiSettings = () => {
    if (userId) {
      restoreDefaultSettings(userId)
        .then(() => {
          dispatch(clearAllUISettingsInStore());
          navigationController.openDetail(USER_PROFILE);
          navigationController.reopenCurrentList();
        });
    }
  };
  const disableNotificationsForUser = () => userId ? disableEmailNotificationsForUser(userId) : null;

  return (
    <AccurityDetailContainer<User>
      objectType={USER_TYPE}
      id={userId}
      customSaveSteps={customSaveSteps}
      customReloadSteps={customReloadSteps}
    >
      {(formik, detailBag) => (
        <>
          <DetailHeader iconName={USER_ICON}>
            <SingleLineFieldWithFormik name={'email'} label={'Email'} readOnly={!isEmailEditable || !hasUsersManagementFeature} />
            <DetailTopBar allowComments={false} allowEmailNotification={false} />
          </DetailHeader>
          <SingleLineFieldWithFormik name={'firstName'} label={'First Name'} readOnly={!hasUsersManagementFeature} />
          <SingleLineFieldWithFormik name={'lastName'} label={'Last Name'} readOnly={!hasUsersManagementFeature} />
          <EnumerationFieldWithFormik
            name={'timezone'}
            label={'Timezone'}
            options={timezoneOptions}
          />
          <CollectionFieldWithFormik
            name={ROLE_FIELD_NAME}
            title={getRolesLabel(formik)}
            icon={ROLE_ICON}
            rowValidation={(value) => value && value.id}
            disabled={!hasUpdatePermission || !hasUsersManagementFeature}
            renderRow={(elementName) =>
              <ReferenceFieldWithFormik
                name={elementName}
                label={ROLE_LABEL}
                objectType={ROLE_TYPE}
                readOnly={!hasUpdatePermission || !hasUsersManagementFeature}
                additionalFilters={createUniqueWithinCollectionFilters('name', formik.values.roles)}
                hideRemoveButton={true}
                showDefaultTooltip={false}
              />
            }
          />
          {userId && (
            <AccurityButton
            className="userPopup__button"
              onClick={restoreDefaultUiSettings}
              disabled={formik.status.isEntityInDetailDeleted}
              variant="outlined"
              fullWidth
              aria-label="restore-default-settings"
            >
              Restore Default Settings
            </AccurityButton>
          )}
          {userId && (
            <AccurityButton
              className="userPopup__button"
              onClick={disableNotificationsForUser}
              disabled={formik.status.isEntityInDetailDeleted}
              variant="outlined"
              fullWidth
              aria-label="turn-off-email-notifications"
            >
              Turn Off All Email Notifications
            </AccurityButton>
          )}
          <DetailBottomBar>
            <AccurityButton
              onClick={detailBag.reloadAction}
              color="secondary"
            >
              Reload
            </AccurityButton>
            <AccurityButton
              onClick={() => detailBag.saveAction()}
            >
              Save
            </AccurityButton>
          </DetailBottomBar>
        </>
      )}
    </AccurityDetailContainer>
  );
};

export default UserProfileDetail;
