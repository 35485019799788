import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import {
  DataCatalogRelatedBusinessTermRelationTypeOptions,
  RELATED_BUSINESS_TERM_RELATION_TYPES_LABEL,
  RELATED_BUSINESS_TERMS_LABEL
} from '../../businessTerm/types/businessTermTypes';
import { DATA_SET_LABEL, DATA_SET_TYPE_LABEL, useDataSetTypeEnumOptions } from '../../dataSet/types/dataSetTypes';
import { DATA_FIELD_PLURAL_LABEL } from '../../dataField/types/dataFieldTypes';
import { Feature } from '../../../common/userSettings/features/features';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { BUSINESS_MODEL_MAPPING_PLURAL_LABEL } from '../../businessModelMapping/types/businessModelMappingTypes';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { TECHNICAL_DATA_MAPPING_PLURAL_LABEL } from '../../technicalModelMapping/types/technicalDataMappingTypes';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';
import { DATA_STRUCTURE_TYPE_FIELD, DATA_STRUCTURE_TYPE_FIELD_LABEL, dataStructureTypeOptions } from '../types/dataStructureTypes';
import { dataCatalogSyncStatusOptions } from '../../../common/dataCatalogSync/types';

export const useDataStructureListColumns = () => {
  const hasFeature = useHasFeature();
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const dataStructureListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description(!isOracle),
    columnFactory.createEnumColumn(DATA_STRUCTURE_TYPE_FIELD, DATA_STRUCTURE_TYPE_FIELD_LABEL, dataStructureTypeOptions, { hidden: true }),
    commonColumns.tags(navigationController),
    columnFactory.createTextColumn('dataSet.name', DATA_SET_LABEL),
    // todo uncomment when comments are implemented #26083
    // columnFactory.createTextColumn(DATA_STRUCTURE_COMMENT_FIELD, DATA_STRUCTURE_COMMENT_FIELD_LABEL, { hidden: true }), // 7
    ...(hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED) ?
      [columnFactory.createEnumColumn('dataSet.dataSetType', DATA_SET_TYPE_LABEL, dataSetTypeEnumOptions)] : []),
    ...(hasFeature(Feature.BUSINESS_TERMS) ?
      [columnFactory.createEnumColumn('relatedBusinessTerms.relationType', RELATED_BUSINESS_TERM_RELATION_TYPES_LABEL, DataCatalogRelatedBusinessTermRelationTypeOptions, {
        hidden: true,
        sortable: false
      }),
        commonColumns.collection('relatedBusinessTerms.relatedBusinessTerm.name', RELATED_BUSINESS_TERMS_LABEL)] : []),
    ...(hasFeature(Feature.DATA_FIELDS) ?
      [columnFactory.createNumberColumn('childrenCounts.dataFieldsCount', DATA_FIELD_PLURAL_LABEL, undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.BUSINESS_MODEL_MAPPINGS) ?
      [columnFactory.createNumberColumn('childrenCounts.businessModelMappingsCount', BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
        undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.TECHNICAL_DATA_MAPPINGS) ?
      [columnFactory.createNumberColumn('childrenCounts.technicalDataMappingsCount', TECHNICAL_DATA_MAPPING_PLURAL_LABEL,
        undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    ...(hasFeature(Feature.PHYSICAL_DATA_SETS) ?
      [
        columnFactory.createEnumColumn('syncResult.status', 'Last Sync. Result', dataCatalogSyncStatusOptions, { hidden: true }),
        columnFactory.createDateColumn('syncResult.syncRun.triggeredDate', 'Last Sync. Triggered at', { hidden: true }),
        columnFactory.createTextColumn('syncResult.syncRun.triggeredBy.name', 'Last Sync. Triggered by', { hidden: true }),
        columnFactory.createTextColumn('syncResult.syncRun.triggeredFrom.name', 'Last Sync. Triggered from', { hidden: true }),
      ] : []),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ];

  return useValidColumns(dataStructureListColumns, GlossaryCustomPropertyObjectType.DATA_STRUCTURE);

};
