import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { Align, Column, NumericFormat } from 'ts-components';
import {
  ATTRIBUTE_DESCRIPTION_LABEL,
  ATTRIBUTE_LABEL,
  ATTRIBUTE_PLURAL_LABEL,
  CALCULATION_RULE_LABEL,
  CALCULATION_RULE_PLURAL_LABEL,
  CHILD_ATTRIBUTE_PLURAL_LABEL,
  REFERENCE_ATTRIBUTE_PLURAL_LABEL
} from '../types/attributeTypes';
import {
  BASED_ON_DESCRIPTION_LABEL,
  BASED_ON_LABEL,
  CHILD_ENTITY_PLURAL_LABEL,
  ENTITY_DESCRIPTION,
  ENTITY_ICON,
  ENTITY_LABEL,
  ENTITY_TYPE_LABEL,
  EntityTypeOptions,
  PARENT_ENTITY_LABEL
} from '../../entity/types/entityTypes';
import {
  ATTRIBUTE_DEFINITION_DESCRIPTION_LABEL,
  ATTRIBUTE_DEFINITION_ICON,
  ATTRIBUTE_DEFINITION_LABEL,
  ATTRIBUTE_DEFINITION_TYPE_LABEL,
  AttributeDefinitionTypeOptions,
  COMPONENT_ATTRIBUTE_DEFINITION_PLURAL_LABEL_SHORT,
  COMPONENT_OF_DESCRIPTION_LABEL,
  COMPONENT_OF_LABEL,
  PARENT_ATTRIBUTE_LABEL
} from '../../attributeDefinition/types/attributeDefinitionTypes';
import { COMPOSITE_TYPE_LABEL, TARGET_ENTITY_LABEL } from '../../compositeType/types/compositeTypeTypes';
import { VALUE_TYPE_LABEL } from '../../valueType/types/valueTypeTypes';
import { REQUIREMENT_PLURAL_LABEL } from '../../requirement/types/requirementTypes';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Feature } from '../../../common/userSettings/features/features';
import { BUSINESS_MODEL_MAPPING_PLURAL_LABEL } from '../../businessModelMapping/types/businessModelMappingTypes';
import { PROCESS_MAPPING_PLURAL_LABEL } from '../../process/mapping/types/processMappingTypes';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';
import { BUSINESS_RULE_PLURAL_LABEL } from '../../businessRule/types/businessRuleTypes';

export const useEmbeddedEntityColumns = () => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();

  const isOracle = useIsOracleDb();

  const embeddedEntityColumns: Column[] = [
    columnFactory.createTextColumn('entity.id', 'ID', { hidden: true }),
    columnFactory.createTextColumn('entity.name', ENTITY_LABEL),
    columnFactory.createTextColumn('entity.description.plainTextValue', ENTITY_DESCRIPTION, { hidden: true, sortable: !isOracle }),
    columnFactory.createTextColumn('entity.basedOnBusinessTerm.name', BASED_ON_LABEL, { hidden: true }),
    columnFactory.createTextColumn('entity.basedOnBusinessTerm.description.plainTextValue', BASED_ON_DESCRIPTION_LABEL, { hidden: true, sortable: !isOracle }),
    columnFactory.createTagsColumn('entity.tags', 'Tags', 'entity.tags.id', navigationController, { hidden: true }),
    columnFactory.createEnumColumn('entity.entityType', ENTITY_TYPE_LABEL, EntityTypeOptions, { hidden: true }),
    columnFactory.createTextColumn('entity.parent.name', PARENT_ENTITY_LABEL, { hidden: true }),
    // children counts
    columnFactory.createNumberColumn('entity.childrenCounts.entitiesCount', CHILD_ENTITY_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createNumberColumn('entity.childrenCounts.attributesCount', ATTRIBUTE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createNumberColumn('entity.childrenCounts.referenceAttributeTargetEntitiesCount', REFERENCE_ATTRIBUTE_PLURAL_LABEL,
      { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    ...(hasFeature(Feature.BUSINESS_MODEL_MAPPINGS) ?
      [columnFactory.createNumberColumn('entity.childrenCounts.businessModelMappingsCount', BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
        { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.PROCESS_MAPPING_TARGET_ENTITIES) ?
      [columnFactory.createNumberColumn('entity.childrenCounts.processMappingsCount', PROCESS_MAPPING_PLURAL_LABEL,
        { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),

    // audit group
    columnFactory.createDateColumn('entity.createdDate', 'Created', { hidden: true }),
    columnFactory.createTextColumn('entity.createdBy.name', 'Created By', { hidden: true }),
    columnFactory.createDateColumn('entity.changedDate', 'Last Changed', { hidden: true }),
    columnFactory.createTextColumn('entity.changedBy.name', 'Changed By', { hidden: true }),

    columnFactory.createTextColumn('entity.status.name', 'Status', { hidden: true }),
  ].map(column => {
    return { ...column, group: ENTITY_LABEL, icon: ENTITY_ICON };
  });

  return useValidColumns(embeddedEntityColumns, GlossaryCustomPropertyObjectType.ENTITY, 'entity', ENTITY_LABEL, ENTITY_ICON);
};

export const useEmbeddedAttributeDefinitionColumns = () => {
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const embeddedAttributeDefinitionColumns: Column[] = [
    columnFactory.createNumberColumn('attributeDefinition.id', 'ID', {
      hidden: true,
      align: Align.LEFT,
      hideComparisonSelector: true,
    }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createTextColumn('attributeDefinition.name', ATTRIBUTE_DEFINITION_LABEL),
    columnFactory.createTextColumn('attributeDefinition.description.plainTextValue', ATTRIBUTE_DEFINITION_DESCRIPTION_LABEL, { hidden: true, sortable: !isOracle }),
    columnFactory.createTextColumn('attributeDefinition.basedOnBusinessTerm.name', BASED_ON_LABEL, { hidden: true }),
    columnFactory.createTextColumn('attributeDefinition.basedOnBusinessTerm.description.plainTextValue', BASED_ON_DESCRIPTION_LABEL, { hidden: true, sortable: !isOracle }),
    columnFactory.createTextColumn('attributeDefinition.parent.name', COMPONENT_OF_LABEL, { hidden: true }),
    columnFactory.createTextColumn('attributeDefinition.parent.description.plainTextValue', COMPONENT_OF_DESCRIPTION_LABEL, { hidden: true, sortable: !isOracle }),
    columnFactory.createTagsColumn('attributeDefinition.tags', 'Tags', 'attributeDefinition.tags.id', navigationController, { hidden: true }),
    columnFactory.createEnumColumn('attributeDefinition.attributeDefinitionType', ATTRIBUTE_DEFINITION_TYPE_LABEL, AttributeDefinitionTypeOptions),
    columnFactory.createTextColumn('attributeDefinition.compositeType.name', COMPOSITE_TYPE_LABEL),
    columnFactory.createTextColumn('attributeDefinition.targetEntity.name', TARGET_ENTITY_LABEL),
    columnFactory.createTextColumn('attributeDefinition.valueType.name', VALUE_TYPE_LABEL),
    // children counts
    columnFactory.createNumberColumn('attributeDefinition.childrenCounts.attributesCount', ATTRIBUTE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createNumberColumn('attributeDefinition.childrenCounts.attributeDefinitionsCount',
      COMPONENT_ATTRIBUTE_DEFINITION_PLURAL_LABEL_SHORT, { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    // audit group
    columnFactory.createDateColumn('attributeDefinition.createdDate', 'Created', { hidden: true }),
    columnFactory.createTextColumn('attributeDefinition.createdBy.name', 'Created By', { hidden: true }),
    columnFactory.createDateColumn('attributeDefinition.changedDate', 'Last Changed', { hidden: true }),
    columnFactory.createTextColumn('attributeDefinition.changedBy.name', 'Changed By', { hidden: true }),

    columnFactory.createTextColumn('attributeDefinition.status.name', 'Status', { hidden: true }),
  ].map(column => {
    return { ...column, group: ATTRIBUTE_DEFINITION_LABEL, icon: ATTRIBUTE_DEFINITION_ICON };
  });

  return useValidColumns(embeddedAttributeDefinitionColumns, GlossaryCustomPropertyObjectType.ATTRIBUTE_DEFINITION, 'attributeDefinition', ATTRIBUTE_DEFINITION_LABEL, ATTRIBUTE_DEFINITION_ICON);
};

export const useAttributeListColumns = () => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const attributeListColumns: Column[] = [
    commonColumns.id(),
    columnFactory.createBooleanColumn('inherited', 'Inherited'),
    columnFactory.createTextColumn('name', 'Name', { hidden: true }),
    columnFactory.createTextColumn('description.plainTextValue', ATTRIBUTE_DESCRIPTION_LABEL, { hidden: true, sortable: !isOracle }),
    ...(hasFeature(Feature.CALCULATION_RULES) ? [columnFactory.createTextColumn('calculationRule.plainTextValue', CALCULATION_RULE_LABEL, {
      hidden: true,
      sortable: !isOracle
    })] : []),
    commonColumns.tags(navigationController, { hidden: true }),
    columnFactory.createTextColumn('parent.name', PARENT_ATTRIBUTE_LABEL, { hidden: true }),
    columnFactory.createCollectionColumn('requirements.name', REQUIREMENT_PLURAL_LABEL, { hidden: true }),
    columnFactory.createNumberColumn('childrenCounts.attributesCount', CHILD_ATTRIBUTE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC),
    ...(hasFeature(Feature.BUSINESS_RULES) ? [columnFactory.createNumberColumn('childrenCounts.businessRulesCount', BUSINESS_RULE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.CALCULATION_RULES) ? [columnFactory.createNumberColumn('childrenCounts.calculationRulesCount', CALCULATION_RULE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.BUSINESS_MODEL_MAPPINGS) ? [columnFactory.createNumberColumn('childrenCounts.businessModelMappingsCount', BUSINESS_MODEL_MAPPING_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.PROCESS_MAPPING_TARGET_ATTRIBUTES) ? [columnFactory.createNumberColumn('childrenCounts.processMappingsCount', PROCESS_MAPPING_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ].map(column => {
    return { ...column, group: ATTRIBUTE_LABEL };
  });

  return useValidColumns(attributeListColumns, GlossaryCustomPropertyObjectType.ATTRIBUTE, undefined, ATTRIBUTE_LABEL);

};
