import React from 'react';

import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { User, USER_ICON, USER_PLURAL_LABEL, USER_TYPE } from '../types/userTypes';
import { userListColumns } from './userListColumns';
import { AccuritySortType } from '../../../common/types/accurityTypes';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useSelector } from 'react-redux';
import { USER_PROFILE } from '../../../common/userPopup/types';
import { usePermissionsForObjectType } from '../../../common/userSettings/hooks/permissions';

const defaultListSettings = getDefaultListSettings(USER_TYPE, userListColumns, { property: 'lastName', type: AccuritySortType.ASCENDING, });

const UserList = () => {

  const navigationController = useAccurityNavigation();
  const userId = useSelector((state: any) => state.userSettings.id);
  const {
    hasReadPermission,
  } = usePermissionsForObjectType(USER_TYPE);

  const handleRowSelect = (row: User) => {
    if (String(row.id) === String(userId) && hasReadPermission) {
      navigationController.openDetail(USER_PROFILE);
    } else {
      navigationController.openDetailWithObject(USER_TYPE, row.id);
    }
  };

  return (
    <AccurityListContainer
      objectType={USER_TYPE}
      title={USER_PLURAL_LABEL}
      icon={USER_ICON}
      allowImport={false}
      allowExport={false}
      columns={userListColumns}
      defaultListSettings={defaultListSettings}
      handleRowSelectOverwrite={handleRowSelect}
    />
  );
};

export default UserList;
