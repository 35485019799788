import React from 'react';
import { HorizontalTreeDiagram } from 'ts-components';
import { useBackendDiagramDataFor } from '../useBackendData';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useLocation } from 'react-router-dom';
import { AccurityLocationState } from '../../../common/types/accurityTypes';
import { DiagramLineageType } from '../types/types';

interface DataLineageDiagramProps {
  id: string;
  objectType: string;
  lineageType: DiagramLineageType;
  nodesCollapsibleFromColumn?: number;
  nodesExpandedFromColumn?: number;
}

const DataLineageDiagram = ({ id, objectType, lineageType, nodesCollapsibleFromColumn, nodesExpandedFromColumn }: DataLineageDiagramProps) => {
  const { closeDiagram } = useAccurityNavigation();
  const location = useLocation<AccurityLocationState>();
  const diagramOpenedAt = location?.state?.diagram?.openedAt || -1;
  const diagramData = useBackendDiagramDataFor(objectType, id, lineageType, diagramOpenedAt);

  if (diagramData === null) {
    return null;
  }

  console.log('diagramData.nodes: ', diagramData.nodes)

  return (
    <HorizontalTreeDiagram
      key={diagramOpenedAt}
      nodes={diagramData.nodes}
      startNodeId={diagramData.startNodeId}
      numColumns={diagramData.columnCount}
      nodesCollapsibleFromColumn={nodesCollapsibleFromColumn}
      nodesExpandedFromColumn={nodesExpandedFromColumn}
      onCloseClick={closeDiagram}
    />
  );
};

export default DataLineageDiagram;
