import React from 'react';
import CloseDetailWithFormik from '../formik/CloseDetailWithFormik';
import CommentsToggleWithFormik from '../formik/CommentsToggleWithFormik';
import EmailNotificationToggleWithFormik from '../formik/EmailNotificationToggleWithFormik';

export interface DetailTopBarProps {
  allowComments?: boolean;
  allowEmailNotification?: boolean;
}

const DetailTopBar = ({ allowComments = true, allowEmailNotification = true }: DetailTopBarProps) => {
  return (
    <div className="detailTopBar">
      {allowComments && <CommentsToggleWithFormik/>}
      {allowEmailNotification && <EmailNotificationToggleWithFormik/>}
      <CloseDetailWithFormik/>
    </div>
  );
};

export default DetailTopBar;