import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import { BUSINESS_MODEL_MAPPING_ICON, BUSINESS_MODEL_MAPPING_PLURAL_LABEL, BUSINESS_MODEL_MAPPING_TYPE } from '../types/businessModelMappingTypes';
import {
  useBusinessModelMappingBaseListColumns,
  useBusinessModelMappingEndListColumns,
  useBusinessModelMappingJoinsListColumns,
  useBusinessModelMappingSelectionsListColumns,
  useBusinessModelMappingStartListColumns,
  useBusinessModelMappingTargetListColumns
} from './businessModelMappingListColumns';
import { AccuritySortType } from '../../../common/types/accurityTypes';

const BusinessModelMappingList = () => {
  const businessModelMappingColumns = [
    ...useBusinessModelMappingStartListColumns(),
    ...useBusinessModelMappingBaseListColumns(),
    ...useBusinessModelMappingTargetListColumns(),
    ...useBusinessModelMappingSelectionsListColumns(),
    ...useBusinessModelMappingJoinsListColumns(),
    ...useBusinessModelMappingEndListColumns()
  ];

  const businessModelMappingListSettings = getDefaultListSettings(
    BUSINESS_MODEL_MAPPING_TYPE,
    businessModelMappingColumns,
    { property: 'id', type: AccuritySortType.ASCENDING }
  );

  return (
    <AccurityListContainer
      objectType={BUSINESS_MODEL_MAPPING_TYPE}
      title={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
      icon={BUSINESS_MODEL_MAPPING_ICON}
      columns={businessModelMappingColumns}
      defaultListSettings={businessModelMappingListSettings}
      partialImportOnly={false}
      allowBulkOperations={true}
    />
  );
};

export default BusinessModelMappingList;
