import React from 'react';
import { useDataSetListColumns } from './dataSetListColumns';
import { DATA_SET_ICON, DATA_SET_PLURAL_LABEL, DATA_SET_TYPE } from '../types/dataSetTypes';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';

const DataSetList = () => {
  const dataSetColumns = useDataSetListColumns();
  const dataSetListSettings = getDefaultListSettings(DATA_SET_TYPE, dataSetColumns);

  return (
    <AccurityListContainer
      objectType={DATA_SET_TYPE}
      title={DATA_SET_PLURAL_LABEL}
      icon={DATA_SET_ICON}
      columns={dataSetColumns}
      defaultListSettings={dataSetListSettings}
      allowBulkOperations={true}
    />
  );
};

export default DataSetList;
