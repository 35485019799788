import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import {
  BUSINESS_TERM_ALSO_REFERENCES_LABEL,
  RELATED_BUSINESS_TERM_RELATION_TYPE_LABEL,
  RELATED_VALUE_DESCRIPTIONS_LABEL,
  RELATED_VALUE_NAMES_LABEL,
  ReverseRelationTypeOptions
} from '../types/businessTermTypes';
import { Feature } from '../../../common/userSettings/features/features';
import { DATA_STRUCTURE_PLURAL_LABEL } from '../../dataStructure/types/dataStructureTypes';
import { DATA_FIELD_PLURAL_LABEL } from '../../dataField/types/dataFieldTypes';
import { ENTITY_PLURAL_LABEL } from '../../entity/types/entityTypes';
import { ATTRIBUTE_DEFINITION_PLURAL_LABEL } from '../../attributeDefinition/types/attributeDefinitionTypes';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';
import { BUSINESS_RULE_PLURAL_LABEL } from '../../businessRule/types/businessRuleTypes';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';

export const useReferencingBusinessTermListColumns = (): Column[] => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const referencingBusinessTermListColumns: Column[] = [
    commonColumns.id(),
    columnFactory.createEnumColumn('relationType', RELATED_BUSINESS_TERM_RELATION_TYPE_LABEL, ReverseRelationTypeOptions,
      { searchProperty: 'relatedBusinessTerms.relationType', sortable: !isOracle }
    ),
    commonColumns.name(),
    commonColumns.description(!isOracle),
    commonColumns.tags(navigationController),
    columnFactory.createTextColumn('alsoReferences.relatedBusinessTerm.name', BUSINESS_TERM_ALSO_REFERENCES_LABEL,
      { sortable: false, searchProperty: 'relatedBusinessTerms.relatedBusinessTerm.name' }
    ),
    ...(hasFeature(Feature.BUSINESS_TERMS_RELATED_VALUES) ?
      [columnFactory.createCollectionColumn('relatedValues.name', RELATED_VALUE_NAMES_LABEL, { hidden: false, })] : []),
    ...(hasFeature(Feature.BUSINESS_TERMS_RELATED_VALUES) ?
      [columnFactory.createCollectionColumn('relatedValues.description.plainTextValue', RELATED_VALUE_DESCRIPTIONS_LABEL, { hidden: true, sortable: !isOracle })] : []),
    ...(hasFeature(Feature.BUSINESS_RULES) ?
      [columnFactory.createNumberColumn('childrenCounts.businessRulesCount', BUSINESS_RULE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.DATA_STRUCTURES) ?
      [columnFactory.createNumberColumn('childrenCounts.dataStructuresCount', DATA_STRUCTURE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.DATA_FIELDS) ?
      [columnFactory.createNumberColumn('childrenCounts.dataFieldsCount', DATA_FIELD_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.ENTITIES) ?
      [columnFactory.createNumberColumn('childrenCounts.entitiesCount', ENTITY_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.ATTRIBUTE_DEFINITIONS) ?
      [columnFactory.createNumberColumn('childrenCounts.attributeDefinitionsCount', ATTRIBUTE_DEFINITION_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
  ];

  return useValidColumns(referencingBusinessTermListColumns, GlossaryCustomPropertyObjectType.BUSINESS_TERM);
};
