import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { DATA_SET_TYPE_LABEL, useDataSetTypeEnumOptions } from '../types/dataSetTypes';
import { DATA_SOURCE_LABEL } from '../../dataSource/types/dataSourceTypes';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { Feature } from '../../../common/userSettings/features/features';
import { BUSINESS_MODEL_MAPPING_PLURAL_LABEL } from '../../businessModelMapping/types/businessModelMappingTypes';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { TECHNICAL_DATA_MAPPING_PLURAL_LABEL } from '../../technicalModelMapping/types/technicalDataMappingTypes';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';
import { dataCatalogSyncStatusOptions } from '../../../common/dataCatalogSync/types';

export const useDataSetListColumns = () => {
  const hasFeature = useHasFeature();
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const dataSetListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description(!isOracle),
    commonColumns.tags(navigationController),
    ...(hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED) ?
      [columnFactory.createEnumColumn('dataSetType', DATA_SET_TYPE_LABEL, dataSetTypeEnumOptions)] : []),
    ...(hasFeature(Feature.PHYSICAL_DATA_SETS) ?
      [columnFactory.createTextColumn('dataSource.name', DATA_SOURCE_LABEL, { hidden: true })] : []),
    ...(hasFeature(Feature.DATA_STRUCTURES) ?
      [columnFactory.createNumberColumn('childrenCounts.dataStructuresCount', 'Data Structures', undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.DATA_FIELDS) ?
      [columnFactory.createNumberColumn('childrenCounts.dataFieldsCount', 'Data Fields', undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.DATA_ASSETS) ?
      [columnFactory.createNumberColumn('childrenCounts.dataAssetsCount', 'Data Assets', { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.BUSINESS_MODEL_MAPPINGS) ?
      [columnFactory.createNumberColumn('childrenCounts.businessModelMappingsCount', BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
        undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.TECHNICAL_DATA_MAPPINGS) ?
      [columnFactory.createNumberColumn('childrenCounts.technicalDataMappingsCount', TECHNICAL_DATA_MAPPING_PLURAL_LABEL,
        undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    ...(hasFeature(Feature.PHYSICAL_DATA_SETS) ?
      [
        columnFactory.createEnumColumn('syncResult.status', 'Last Sync. Result', dataCatalogSyncStatusOptions, { hidden: true }),
        columnFactory.createDateColumn('syncResult.syncRun.triggeredDate', 'Last Sync. Triggered at', { hidden: true }),
        columnFactory.createTextColumn('syncResult.syncRun.triggeredBy.name', 'Last Sync. Triggered by', { hidden: true }),
        columnFactory.createTextColumn('syncResult.syncRun.triggeredFrom.name', 'Last Sync. Triggered from', { hidden: true }),
      ] : []),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ];

  return useValidColumns(dataSetListColumns, GlossaryCustomPropertyObjectType.DATA_SET);

};
