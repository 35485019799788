import React from 'react';
import { getDefaultListSettings } from '../../../list/listUtils';
import AccurityListContainer from '../../../list/redux/AccurityListContainer';
import { CUSTOM_PROPERTY_ICON, CUSTOM_PROPERTY_PLURAL_LABEL, CUSTOM_PROPERTY_TYPE } from '../types/customPropertyTypes';
import { useCustomPropertyListColumns } from './customPropertyListColumns';
import { EnumOption } from 'ts-components';

interface CustomPropertyListProps {
  customPropertyObjectTypeOptions: EnumOption[],
  customPropertyPropertyTypeOptions: EnumOption[],
  customPropertyTargetObjectTypeOptions: EnumOption[],
}

const CustomPropertyList = ({ customPropertyObjectTypeOptions, customPropertyPropertyTypeOptions, customPropertyTargetObjectTypeOptions }: CustomPropertyListProps) => {
  const customPropertyColumns = useCustomPropertyListColumns(customPropertyObjectTypeOptions, customPropertyPropertyTypeOptions, customPropertyTargetObjectTypeOptions);
  const customPropertyListSettings = getDefaultListSettings(CUSTOM_PROPERTY_TYPE, customPropertyColumns);

  return (
    <AccurityListContainer
      objectType={CUSTOM_PROPERTY_TYPE}
      title={CUSTOM_PROPERTY_PLURAL_LABEL}
      icon={CUSTOM_PROPERTY_ICON}
      columns={customPropertyColumns}
      defaultListSettings={customPropertyListSettings}
      allowBulkOperations={true}
    />
  );
};

export default CustomPropertyList;
