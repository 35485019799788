import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../../common/list/commonColumns/columnDefinitions';
import AccurityListContainer from '../../../../common/list/redux/AccurityListContainer';
import React from 'react';
import { getDefaultListSettings, useValidColumns } from '../../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../../customProperties/types';
import { useAccurityNavigation } from '../../../../common/navigation/hooks';
import { PROCESS_STEP_ICON, PROCESS_STEP_PLURAL_LABEL, PROCESS_STEP_TYPE } from '../types/processStepTypes';
import { PROCESS_LABEL } from '../../types/processTypes';
import { Feature } from '../../../../common/userSettings/features/features';
import { PROCESS_MAPPING_PLURAL_LABEL } from '../../mapping/types/processMappingTypes';
import { useHasFeature } from '../../../../common/userSettings/hooks/features';
import { useIsOracleDb } from '../../../../common/appSettings/hooks/environmentInfoHooks';

const ProcessStepList = () => {
  const navigationController = useAccurityNavigation();
  const hasFeature = useHasFeature();
  const isOracle = useIsOracleDb();

  const processListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description(!isOracle),
    commonColumns.tags(navigationController),
    columnFactory.createTextColumn('process.name', PROCESS_LABEL),
    ...(hasFeature(Feature.PROCESS_MAPPINGS) ?
      [columnFactory.createNumberColumn('childrenCounts.processMappingsCount', PROCESS_MAPPING_PLURAL_LABEL,
        undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ];

  const processStepColumns = useValidColumns(processListColumns, GlossaryCustomPropertyObjectType.PROCESS_STEP);
  const processStepListSettings = getDefaultListSettings(PROCESS_STEP_TYPE, processStepColumns);

  return (
    <AccurityListContainer
      objectType={PROCESS_STEP_TYPE}
      title={PROCESS_STEP_PLURAL_LABEL}
      icon={PROCESS_STEP_ICON}
      columns={processStepColumns}
      defaultListSettings={processStepListSettings}
      allowBulkOperations={true}
    />
  );
};

export default ProcessStepList;
