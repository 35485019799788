import { DATA_SET_TYPE, DataSetState } from '../types/dataSetTypes';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialCoreEntityState } from '../../../common/redux/types';
import { commonReducers } from '../../../common/redux/commonReducers';

const initialState: DataSetState = getInitialCoreEntityState('data-set');

const dataSetSlice = createSlice({
  name: DATA_SET_TYPE,
  initialState: initialState,
  reducers: {},
  extraReducers: {
    ...commonReducers,
  }
});

export default dataSetSlice.reducer;
