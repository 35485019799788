import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { ATTRIBUTE_DEFINITION_ICON, ATTRIBUTE_DEFINITION_PLURAL_LABEL, ATTRIBUTE_DEFINITION_TYPE } from '../types/attributeDefinitionTypes';
import { useAttributeDefinitionListColumns } from './attributeDefinitionListColumns';

const AttributeDefinitionList = () => {
  const attributeDefinitionColumns = useAttributeDefinitionListColumns();
  const attributeDefinitionListSettings = getDefaultListSettings(ATTRIBUTE_DEFINITION_TYPE, attributeDefinitionColumns);

  return (
    <AccurityListContainer
      objectType={ATTRIBUTE_DEFINITION_TYPE}
      title={ATTRIBUTE_DEFINITION_PLURAL_LABEL}
      icon={ATTRIBUTE_DEFINITION_ICON}
      columns={attributeDefinitionColumns}
      defaultListSettings={attributeDefinitionListSettings}
      partialImportOnly={true}
      allowBulkOperations={true}
    />
  );
};

export default AttributeDefinitionList;
