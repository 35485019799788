import React from 'react';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import CollectionFieldWithFormik from '../../../../common/detail/formik/CollectionFieldWithFormik';
import { ENTITY_ICON, ENTITY_LABEL, ENTITY_TYPE } from '../../../entity/types/entityTypes';
import { FormikProps, useField } from 'formik';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import {
  BUSINESS_MODEL_MAPPING_BASE_ENTITY_FIELD,
  BUSINESS_MODEL_MAPPING_BASE_FIELD,
  BUSINESS_MODEL_MAPPING_BASE_LABEL,
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ICON,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL,
  BusinessModelMapping,
  TargetDataStructureDataField
} from '../../types/businessModelMappingTypes';
import { createReferenceFieldFilter, createUniqueWithinCollectionFilters } from '../../../../common/referenceField/utils/filters';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE } from '../../../dataStructure/types/dataStructureTypes';
import DataStructureDataFieldSelection from '../misc/DataStructureDataFieldSelection';
import { getBusinessModelMappingCountLabel, resetValuesOnTargetDataSetChange } from '../businessModelMappingDetailUtils';
import { CollapsingGroup, DetailInlineGroup, EnumOption } from 'ts-components';
import DataStructureDataFieldJoin from '../misc/DataStructureDataFieldJoin';
import { defaultTestDraggedItem, defaultTestDroppedItem } from '../../../../common/referenceField/utils/dragAndDrop';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL } from '../../../dataSet/types/dataSetTypes';
import EnumerationFieldWithFormik from '../../../../common/detail/formik/EnumerationFieldWithFormik';

interface BasedOnEntityProps {
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<BusinessModelMapping>;
  multipleDataSetTypesAllowedFeature: boolean;
  dataSetTypeEnumOptions: EnumOption[];
}

const BasedOnEntity = ({
                         additionalFilters = [],
                         formik,
                         multipleDataSetTypesAllowedFeature,
                         dataSetTypeEnumOptions
                       }: BasedOnEntityProps) => {

  const selections = formik.getFieldProps(BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD).value;
  const isVersionBrowser = formik.status.isVersionBrowser;

  const [targetField] = useField<TargetDataStructureDataField[]>(BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD);

  const dataStructures = targetField.value.map(row => row?.dataStructure);

  const dataStructuresFilter = [
    createReferenceFieldFilter('dataSet.id', formik.values.targetDataSet?.id),
    ...additionalFilters,
    ...createUniqueWithinCollectionFilters('name', dataStructures),
  ];

  return (
    <>
      <CollapsingGroup
        name={BUSINESS_MODEL_MAPPING_BASE_FIELD}
        title={BUSINESS_MODEL_MAPPING_BASE_LABEL}
        icon={BUSINESS_MODEL_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        <ReferenceFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_BASE_ENTITY_FIELD}
          label={ENTITY_LABEL}
          icon={ENTITY_ICON}
          objectType={ENTITY_TYPE}
        />
      </CollapsingGroup>
      <CollapsingGroup
        name={BUSINESS_MODEL_MAPPING_TARGET_FIELD}
        title={BUSINESS_MODEL_MAPPING_TARGET_LABEL}
        icon={BUSINESS_MODEL_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        {formik.values.businessModelMappingType &&
        <DetailInlineGroup
          childGridSizes={[8, 4]}
        >
          <ReferenceFieldWithFormik
            name={BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_FIELD}
            label={DATA_SET_LABEL}
            objectType={DATA_SET_TYPE}
            icon={DATA_SET_ICON}
            setValue={(newValue) => {
              resetValuesOnTargetDataSetChange(formik, false);
              formik.setFieldValue(BUSINESS_MODEL_MAPPING_TARGET_DATA_SET_FIELD, newValue);
            }}
          />
          {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
            name={'targetDataSet.dataSetType'}
            label={DATA_SET_TYPE_LABEL}
            options={dataSetTypeEnumOptions}
            readOnly={true}
          />}
        </DetailInlineGroup>}
        {formik.values.targetDataSet && <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_DATA_FIELDS_FIELD}
          title={getBusinessModelMappingCountLabel(dataStructures,
            BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_LABEL,
            BUSINESS_MODEL_MAPPING_TARGET_DATA_STRUCTURE_PLURAL_LABEL)}
          icon={DATA_STRUCTURE_ICON}
          testDraggedItem={defaultTestDraggedItem(DATA_STRUCTURE_TYPE)}
          testDroppedItem={defaultTestDroppedItem({ objectType: DATA_STRUCTURE_TYPE, additionalFilters: dataStructuresFilter })}
          onValidDrop={(addRow, rowItem) => addRow({ dataStructure: rowItem })}
          rowValidation={(value) => value && value.dataStructure && value.dataStructure.id}
          renderRow={(elementName) =>
            <TargetDataStructureRow
              elementName={elementName}
              dataStructuresFilter={dataStructuresFilter}
            />
          }
        />}
        {formik.values.targetDataSet && <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD}
          title={getBusinessModelMappingCountLabel(selections,
            BUSINESS_MODEL_MAPPING_TARGET_SELECTION_LABEL,
            BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL)}
          icon={BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ICON}
          expandedByDefault={isVersionBrowser}
          rowValidation={(value) => value &&
            value.dataStructure && value.dataStructure.id &&
            value.dataField && value.dataField.id &&
            value.criteria
          }
          renderRow={(elementName, index) =>
            <DataStructureDataFieldSelection
              elementName={elementName}
              index={index}
              formik={formik}
            />
          }
        />}
        {formik.values.targetDataSet && <DataStructureDataFieldJoin
          additionalFilters={additionalFilters}
          formik={formik}
        />}
      </CollapsingGroup>
    </>
  )
};

type TargetDataStructureRowProps = {
  elementName: string;
  dataStructuresFilter?: AccurityFilter[];
}

const TargetDataStructureRow = ({ elementName, dataStructuresFilter }: TargetDataStructureRowProps) => {
  return (
    <ReferenceFieldWithFormik
      name={elementName + '.dataStructure'}
      label={DATA_STRUCTURE_LABEL}
      icon={DATA_STRUCTURE_ICON}
      objectType={DATA_STRUCTURE_TYPE}
      additionalFilters={dataStructuresFilter}
      hideRemoveButton={true}
      coloringOverwrite={elementName}
    />
  );
};

export default BasedOnEntity;
