import React from 'react';
import { AccurityMenuItem, DetailHeader } from 'ts-components';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import {
  BASED_ON_LABEL,
  CHILD_ENTITY_LABEL,
  CHILD_ENTITY_PLURAL_LABEL,
  CONFIRM_ENTITY_PARENT_CHANGE_SETTINGS_DETAIL,
  Entity,
  ENTITY_DESCRIPTION,
  ENTITY_ICON,
  ENTITY_TYPE,
  ENTITY_TYPE_LABEL,
  EntityTypeOptions,
  PARENT_ENTITY_LABEL
} from '../types/entityTypes';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../common/detail/types/types';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import EnumerationFieldWithFormik from '../../../common/detail/formik/EnumerationFieldWithFormik';
import ReferenceFieldWithFormik from '../../../common/detail/formik/ReferenceFieldWithFormik';
import { BUSINESS_TERM_ICON, BUSINESS_TERM_TYPE } from '../../businessTerm/types/businessTermTypes';
import {
  ATTRIBUTE_ICON,
  ATTRIBUTE_LABEL,
  ATTRIBUTE_PLURAL_LABEL,
  ATTRIBUTE_TYPE,
  REFERENCE_ATTRIBUTE_LABEL,
  REFERENCE_ATTRIBUTE_PLURAL_LABEL,
} from '../../attribute/types/attributeTypes';
import {
  ATTRIBUTE_DEFINITION_ICON,
  ATTRIBUTE_DEFINITION_LABEL,
  ATTRIBUTE_DEFINITION_PLURAL_LABEL,
  ATTRIBUTE_DEFINITION_TYPE
} from '../../attributeDefinition/types/attributeDefinitionTypes';
import { COMPOSITE_TYPE_ICON, COMPOSITE_TYPE_LABEL, COMPOSITE_TYPE_PLURAL_LABEL, COMPOSITE_TYPE_TYPE } from '../../compositeType/types/compositeTypeTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import { excludeFieldValueFilter } from '../../../common/referenceField/utils/filters';
import EntityConfirmParentChangeDetail from './EntityConfirmParentChangeDetail';
import EntityConfirmRemovalDetail from './EntityConfirmRemovalDetail';
import RichTextEditorFieldWithFormik from '../../../common/detail/formik/RichTextEditorFieldWithFormik';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../../common/userSettings/features/features';
import {
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_LABEL,
  BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TYPE
} from '../../businessModelMapping/types/businessModelMappingTypes';
import { PROCESS_MAPPING_ICON, PROCESS_MAPPING_LABEL, PROCESS_MAPPING_PLURAL_LABEL, PROCESS_MAPPING_TYPE } from '../../process/mapping/types/processMappingTypes';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import { doFetch } from '../../../common/rest/FetchService';
import { getCreateBmdBasedOnEntityEndpoint, getGetEndpoint } from '../../../common/rest/endpoints';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { BUSINESS_MODEL_DIAGRAM_TYPE } from '../../businessModelDiagram/types/businessModelDiagramTypes';
import { showErrorMessageFromResponse } from '../../../common/userMessages/actions';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { usePermissionsForObjectType } from '../../../common/userSettings/hooks/permissions';
import { ProcessDiagramBasedOnType } from '../../processDiagram/types/processDiagramTypes';
import GenerateProcessDiagramButton from '../../processDiagram/detail/GenerateProcessDiagramButton';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';
import BusinessModelMappingDiagramButton from '../../../common/detail/commonFields/BusinessModelMappingDiagramButton';
import { DATA_STRUCTURE_TYPE } from '../../dataStructure/types/dataStructureTypes';

interface EntityDetailProps {
  id?: string,
}

const EntityDetail = ({ id }: EntityDetailProps) => {
  const navigationController = useAccurityNavigation();
  const hasFeature = useHasFeature();
  const saveAction = (formik: FormikProps<Entity>, detailBag: DetailBag<Entity>) => (fieldOverwrites?: { [fieldName: string]: any }) => {
    const isConfirmSaveDetail = formik.status.settingsDetail === CONFIRM_ENTITY_PARENT_CHANGE_SETTINGS_DETAIL;

    const oldParentId = formik.initialValues?.parent?.id;

    const didParentChange =
      formik.status.isUpdateDetail &&
      oldParentId !== undefined &&
      oldParentId !== formik.values?.parent?.id;

    const hasAttributes = formik.values.childrenCounts.attributesCount > 0;

    if (!isConfirmSaveDetail && didParentChange && hasAttributes) {
      // when entity has no inherited attributes, we don't need to show `CONFIRM_ENTITY_PARENT_CHANGE_SETTINGS_DETAIL` detail
      // the decision whether or not to display the confirmation detail is made by checking the number of attributes of the parent entity
      detailBag.setIsDetailLoading(true);
      doFetch(getGetEndpoint(ENTITY_TYPE, oldParentId), 'GET')
        .then((oldParent: Entity) => oldParent.childrenCounts.attributesCount > 0)
        .then(oldParentHasAttributes => {
          detailBag.setIsDetailLoading(false);
          oldParentHasAttributes
            ? detailBag.setSettingsDetail(CONFIRM_ENTITY_PARENT_CHANGE_SETTINGS_DETAIL)
            : detailBag.saveAction(fieldOverwrites);
        });
    } else {
      detailBag.saveAction(fieldOverwrites);
    }
  };

  const generateBusinessModelDiagram = !id ? undefined : () => {
    doFetch(getCreateBmdBasedOnEntityEndpoint(id), 'POST')
      .then((businessModelDiagramId: string) => {
        navigationController.openDetailAndDiagram(BUSINESS_MODEL_DIAGRAM_TYPE, businessModelDiagramId, businessModelDiagramId);
      })
      .catch(showErrorMessageFromResponse);
  };

  const hasBusinessModelDiagramCreatePermission = usePermissionsForObjectType(BUSINESS_MODEL_DIAGRAM_TYPE).hasCreatePermission;
  const generateBusinessModelDiagramButton = hasFeature(Feature.BUSINESS_MODEL_DIAGRAMS) && hasBusinessModelDiagramCreatePermission
    ? (<AccurityMenuItem key="modelDiagramButton" onClick={() => generateBusinessModelDiagram?.()}>
        Generate Business Model Diagram
      </AccurityMenuItem>)
    : undefined;

  const generateProcessDiagramButton =
    <GenerateProcessDiagramButton
      key="processDiagramButton"
      basedOnType={ProcessDiagramBasedOnType.ENTITIES}
      objectType={ENTITY_TYPE}
      id={id}
    />;

  return (
    <AccurityDetailContainer<Entity>
      objectType={ENTITY_TYPE}
      id={id}
    >
      {(formik, defaultDetailBag) => {
        const detailBag: DetailBag<Entity> = {
          ...defaultDetailBag,
          saveAction: saveAction(formik, defaultDetailBag),
        };

        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return <EntityConfirmRemovalDetail formik={formik} detailBag={detailBag}/>;
        } else if (formik.status.settingsDetail === CONFIRM_ENTITY_PARENT_CHANGE_SETTINGS_DETAIL) {
          return <EntityConfirmParentChangeDetail detailBag={detailBag}/>;
        } else {
          return (
            <>
              {getEntityDetailFields(formik, false)}
              <DefaultDetailBottomBar
                objectType={ENTITY_TYPE}
                detailBag={detailBag}
                customMenuItems={[generateBusinessModelDiagramButton, generateProcessDiagramButton]}
              />
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

export const getEntityDetailFields = (formik: FormikProps<Entity>,
                                      isEmbedded?: boolean,
                                      editingInEmbedded?: boolean) => {
  return (
    <>
      {!isEmbedded &&
      <DetailHeader iconName={ENTITY_ICON}>
        <NameFieldWithFormik label={'Entity Name'}/>
        <DetailTopBar/>
      </DetailHeader>}
      <DescriptionFieldWithFormik
        label={ENTITY_DESCRIPTION}
      />
      <FeatureChecker featureId={Feature.BUSINESS_TERMS}>
        <ReferenceFieldWithFormik
          name={'basedOnBusinessTerm'}
          label={BASED_ON_LABEL}
          objectType={BUSINESS_TERM_TYPE}
          icon={BUSINESS_TERM_ICON}
          showDefaultTooltip={false}
        />
        {!!formik.values.basedOnBusinessTerm &&
        <RichTextEditorFieldWithFormik
          key={formik.values.basedOnBusinessTerm.id}
          name={'basedOnBusinessTerm.description'}
          label={'Business Term Description'}
          allowedChipTypes={[BUSINESS_TERM_TYPE]}
          chipsCollectionName={'basedOnBusinessTerm.chips'}
          readOnly={true}
        />}
      </FeatureChecker>
      <HistoryGroupWithFormik
        VersionBrowserButton={!isEmbedded && <VersionBrowserButton
          getDetailFields={formik => getEntityDetailFields(formik)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={ENTITY_TYPE}/>
      <EnumerationFieldWithFormik
        name={'entityType'}
        label={ENTITY_TYPE_LABEL}
        options={EntityTypeOptions}
      />
      <ReferenceFieldWithFormik
        name={'parent'}
        label={PARENT_ENTITY_LABEL}
        objectType={ENTITY_TYPE}
        icon={ENTITY_ICON}
        showDefaultTooltip={false}
        customEndpoint={formik.values.id ? `${ENTITY_TYPE}/parent-reference/${formik.values.id}` : undefined}
        additionalFilters={[excludeFieldValueFilter('name', formik.initialValues.name)]}
      />
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.ENTITY}/>
      <FeatureChecker featureId={Feature.ENTITIES}>
        <ChildrenCountFieldWithFormik
          singularLabel={CHILD_ENTITY_LABEL}
          pluralLabel={CHILD_ENTITY_PLURAL_LABEL}
          value={formik.values.childrenCounts.entitiesCount}
          iconName={ENTITY_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={ENTITY_TYPE}
          childrenCountWording={ChildrenCountWording.HAS}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.ATTRIBUTES}>
        <ChildrenCountFieldWithFormik
          singularLabel={ATTRIBUTE_LABEL}
          pluralLabel={ATTRIBUTE_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributesCount}
          iconName={ATTRIBUTE_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={ATTRIBUTE_TYPE}
          childrenCountWording={ChildrenCountWording.HAS}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
        <ChildrenCountFieldWithFormik
          singularLabel={BUSINESS_MODEL_MAPPING_LABEL}
          pluralLabel={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.businessModelMappingsCount}
          iconName={BUSINESS_MODEL_MAPPING_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={BUSINESS_MODEL_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.PROCESS_MAPPING_TARGET_ENTITIES}>
        <ChildrenCountFieldWithFormik
          singularLabel={PROCESS_MAPPING_LABEL}
          pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.processMappingsCount}
          iconName={PROCESS_MAPPING_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={PROCESS_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.TARGET_OF}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.ATTRIBUTES}>
        <ChildrenCountFieldWithFormik
          singularLabel={REFERENCE_ATTRIBUTE_LABEL}
          pluralLabel={REFERENCE_ATTRIBUTE_PLURAL_LABEL}
          value={formik.values.childrenCounts.referenceAttributeTargetEntitiesCount}
          iconName={ATTRIBUTE_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={ATTRIBUTE_TYPE}
          relationType={'REFERENCE_ATTRIBUTE_TARGET_ENTITY'}
          childrenCountWording={ChildrenCountWording.TARGET_OF}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.ATTRIBUTE_DEFINITIONS}>
        {!isEmbedded && <ChildrenCountFieldWithFormik
          singularLabel={ATTRIBUTE_DEFINITION_LABEL}
          pluralLabel={ATTRIBUTE_DEFINITION_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributeDefinitionsCount}
          iconName={ATTRIBUTE_DEFINITION_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={ATTRIBUTE_DEFINITION_TYPE}
          childrenCountWording={ChildrenCountWording.USED_BY}
        />}
      </FeatureChecker>
      <FeatureChecker featureId={Feature.COMPOSITE_TYPES}>
        {!isEmbedded && <ChildrenCountFieldWithFormik
          singularLabel={COMPOSITE_TYPE_LABEL}
          pluralLabel={COMPOSITE_TYPE_PLURAL_LABEL}
          value={formik.values.childrenCounts.compositeTypesCount}
          iconName={COMPOSITE_TYPE_ICON}
          pinIconName={ENTITY_ICON}
          childFieldType={COMPOSITE_TYPE_TYPE}
          childrenCountWording={ChildrenCountWording.USED_BY}
        />}
      </FeatureChecker>
      <BusinessModelMappingDiagramButton
        objectType={ENTITY_TYPE}
        id={formik.values.id}
        disabled={formik.status.isEntityInDetailDeleted}
      />
    </>
  );
};

export default EntityDetail;
