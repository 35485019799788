export type BackendDiagramEntity = {
  name: string;
  id: string;
  objectType: string;
  depth: number;
  groupParent?: BackendDiagramEntity;
  linkedEntities: BackendDiagramLink[];
  hasCyclicLink?: boolean;
};

export type BackendDiagramLink = {
  linkedEntity: BackendDiagramEntity;
  linkLabel?: string;
}

export type BackendDiagramResponse = {
  diagramEntities: BackendDiagramEntity[];
  startEntity: BackendDiagramEntity;
  columnCount: number;
};

export enum DiagramLineageType {
  DATA_CATALOG = 'DATA_CATALOG',
  BUSINESS_TERM = 'BUSINESS_TERM',
  TECHNICAL_DATA_MAPPING = 'TECHNICAL_DATA_MAPPING',
  BUSINESS_MODEL_MAPPING = 'BUSINESS_MODEL_MAPPING',
}

export const BUSINESS_TERM_DIAGRAM_TYPE = 'business-term-diagram';
export const DATA_LINEAGE_DIAGRAM_TYPE = 'data-lineage-diagram';
export const TECHNICAL_DATA_MAPPING_DIAGRAM_TYPE = 'technical-data-mapping-diagram-type';
export const BUSINESS_MODEL_MAPPING_DIAGRAM_TYPE = 'business-model-mapping-diagram-type';

