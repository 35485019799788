import React from 'react';
import { ChipReference, DetailHeader, StaticField } from 'ts-components';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_PLURAL_LABEL, DATA_STRUCTURE_TYPE } from '../../dataStructure/types/dataStructureTypes';
import { DATA_FIELD_ICON, DATA_FIELD_LABEL, DATA_FIELD_PLURAL_LABEL, DATA_FIELD_TYPE } from '../../dataField/types/dataFieldTypes';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, IT_IS_CURRENTLY_LABEL } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { excludeFieldValueFilter } from '../../../common/referenceField/utils/filters';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import DataLineageDiagramButton from '../../../common/detail/commonFields/DataLineageDiagramButton';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import RelatedValuesFieldWithFormik from '../../../common/detail/formik/RelatedValuesFieldWithFormik';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { ENTITY_ICON, ENTITY_LABEL, ENTITY_PLURAL_LABEL, ENTITY_TYPE } from '../../entity/types/entityTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import {
  ATTRIBUTE_DEFINITION_ICON,
  ATTRIBUTE_DEFINITION_LABEL,
  ATTRIBUTE_DEFINITION_PLURAL_LABEL,
  ATTRIBUTE_DEFINITION_TYPE
} from '../../attributeDefinition/types/attributeDefinitionTypes';
import BusinessTermDetailBottomBar from '../components/BusinessTermDetailBottomBar';
import { BUSINESS_RULE_ICON, BUSINESS_RULE_LABEL, BUSINESS_RULE_PLURAL_LABEL, BUSINESS_RULE_TYPE } from '../../businessRule/types/businessRuleTypes';
import RequirementsFieldWithFormik from '../../../common/detail/formik/RequirementsFieldWithFormik';
import RelatedBusinessTermReferencesFieldWithFormik from '../../../common/detail/formik/RelatedBusinessTermReferencesFieldWithFormik';
import RichTextEditorFieldWithFormik from '../../../common/detail/formik/RichTextEditorFieldWithFormik';
import { AccurityNavigationController, useAccurityNavigation } from '../../../common/navigation/hooks';
import {
  BUSINESS_TERM_ICON,
  BUSINESS_TERM_LABEL,
  BUSINESS_TERM_PLURAL_LABEL,
  BUSINESS_TERM_TYPE,
  BusinessTerm,
  BusinessTermRelatedBusinessTermRelationType,
  BusinessTermRelatedBusinessTermRelationTypeOptions,
  RELATED_BUSINESS_TERM_REFERENCED_BY_ICON,
  RelatedBusinessTerm
} from '../types/businessTermTypes';
import { PROCESS_MAPPING_ICON, PROCESS_MAPPING_LABEL, PROCESS_MAPPING_PLURAL_LABEL, PROCESS_MAPPING_TYPE } from '../../process/mapping/types/processMappingTypes';
import SingleLineFieldWithFormik from '../../../common/detail/formik/SinglelineFieldWithFormik';
import BusinessTermDiagramButton from '../components/BusinessTermDiagramButton';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface BusinessTermDetailProps {
  id?: string,
}

const BusinessTermDetail = ({ id }: BusinessTermDetailProps) => {
  const navigationController = useAccurityNavigation();

  return (
    <AccurityDetailContainer<BusinessTerm>
      objectType={BUSINESS_TERM_TYPE}
      id={id}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik, navigationController)}
              <BusinessTermDetailBottomBar detailBag={detailBag} formik={formik}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<BusinessTerm>, detailBag: DetailBag<BusinessTerm>) => (
  <ConfirmRemovalDetail
    iconName={BUSINESS_TERM_ICON}
    detailBag={detailBag}
  >
    <StaticField
      name={'removalDescription'}
      value={IT_IS_CURRENTLY_LABEL}
      inverted={true}
    />
    <FeatureChecker featureId={Feature.BUSINESS_TERMS}>
      <ChildrenCountFieldWithFormik
        singularLabel={BUSINESS_TERM_LABEL}
        pluralLabel={BUSINESS_TERM_PLURAL_LABEL}
        value={formik.values.childrenCounts.businessTermsCount}
        iconName={BUSINESS_TERM_ICON}
        pinIconName={BUSINESS_TERM_ICON}
        childFieldType={BUSINESS_TERM_TYPE}
        childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        inverted={true}
      />
    </FeatureChecker>
    <FeatureChecker featureId={Feature.BUSINESS_RULES}>
      <ChildrenCountFieldWithFormik
        singularLabel={BUSINESS_RULE_LABEL}
        pluralLabel={BUSINESS_RULE_PLURAL_LABEL}
        value={formik.values.childrenCounts.businessRulesCount}
        iconName={BUSINESS_RULE_ICON}
        pinIconName={BUSINESS_TERM_ICON}
        childFieldType={BUSINESS_RULE_TYPE}
        childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        inverted={true}
      />
    </FeatureChecker>
    <FeatureChecker featureId={Feature.DATA_STRUCTURES}>
      <ChildrenCountFieldWithFormik
        singularLabel={DATA_STRUCTURE_LABEL}
        pluralLabel={DATA_STRUCTURE_PLURAL_LABEL}
        value={formik.values.childrenCounts.dataStructuresCount}
        iconName={DATA_STRUCTURE_ICON}
        pinIconName={BUSINESS_TERM_ICON}
        childFieldType={DATA_STRUCTURE_TYPE}
        childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        inverted={true}
      />
    </FeatureChecker>
    <FeatureChecker featureId={Feature.DATA_FIELDS}>
      <ChildrenCountFieldWithFormik
        singularLabel={DATA_FIELD_LABEL}
        pluralLabel={DATA_FIELD_PLURAL_LABEL}
        value={formik.values.childrenCounts.dataFieldsCount}
        iconName={DATA_FIELD_ICON}
        pinIconName={BUSINESS_TERM_ICON}
        childFieldType={DATA_FIELD_TYPE}
        childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        inverted={true}
      />
    </FeatureChecker>
    <FeatureChecker featureId={Feature.ENTITIES}>
      <ChildrenCountFieldWithFormik
        singularLabel={ENTITY_LABEL}
        pluralLabel={ENTITY_PLURAL_LABEL}
        value={formik.values.childrenCounts.entitiesCount}
        iconName={ENTITY_ICON}
        pinIconName={BUSINESS_TERM_ICON}
        childFieldType={ENTITY_TYPE}
        childrenCountWording={ChildrenCountWording.BASE_FOR}
        inverted={true}
      />
    </FeatureChecker>
    <FeatureChecker featureId={Feature.ATTRIBUTE_DEFINITIONS}>
      <ChildrenCountFieldWithFormik
        singularLabel={ATTRIBUTE_DEFINITION_LABEL}
        pluralLabel={ATTRIBUTE_DEFINITION_PLURAL_LABEL}
        value={formik.values.childrenCounts.attributeDefinitionsCount}
        iconName={ATTRIBUTE_DEFINITION_ICON}
        pinIconName={BUSINESS_TERM_ICON}
        childFieldType={ATTRIBUTE_DEFINITION_TYPE}
        childrenCountWording={ChildrenCountWording.BASE_FOR}
        inverted={true}
      />
    </FeatureChecker>
    <FeatureChecker featureId={Feature.PROCESS_MAPPINGS_TARGET_BT}>
      <ChildrenCountFieldWithFormik
        singularLabel={PROCESS_MAPPING_LABEL}
        pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
        value={formik.values.childrenCounts.processMappingsCount}
        iconName={PROCESS_MAPPING_ICON}
        pinIconName={BUSINESS_TERM_ICON}
        childFieldType={PROCESS_MAPPING_TYPE}
        childrenCountWording={ChildrenCountWording.TARGET_OF}
        inverted={true}
      />
    </FeatureChecker>
  </ConfirmRemovalDetail>
);

const getDetailFields = (formik: FormikProps<BusinessTerm>,
                         navigationController: AccurityNavigationController) => {

  // Responsible for adding to Related Business Terms collection when User adds Chip to Description
  const onChipCollectionChange = (previousChips: ChipReference[], newChips: ChipReference[]) => {
    if (newChips.length <= previousChips.length) {
      return;
    }

    newChips.filter(chip => !previousChips.includes(chip))
      .forEach(chip => {
        const chipIsBusinessTerm = chip.objectType === BUSINESS_TERM_TYPE;
        const chipIsNotObjectInDetail = chip.id !== formik.values.id;
        const chipIsNotAlreadyInReferencesCollection = !formik.values.relatedBusinessTerms
          .find(relatedBusinessTerm => relatedBusinessTerm.relatedBusinessTerm?.id === chip.id);

        if (chipIsBusinessTerm && chipIsNotObjectInDetail && chipIsNotAlreadyInReferencesCollection) {
          const newRelatedBusinessTerm: RelatedBusinessTerm = {
            relationType: BusinessTermRelatedBusinessTermRelationType.RELATED_TO,
            relatedBusinessTerm: { ...chip },
          };
          formik.setFieldValue('relatedBusinessTerms', [...formik.values.relatedBusinessTerms, newRelatedBusinessTerm]);
        }
      });
  };

  return (
    <>
      <DetailHeader iconName={BUSINESS_TERM_ICON}>
        <NameFieldWithFormik label={'Business Term Name'}/>
        <DetailTopBar/> 
      </DetailHeader>
      <SingleLineFieldWithFormik
        name={'synonym'}
        label={'Synonym'}
      />
      <RichTextEditorFieldWithFormik
        name={'description'}
        label={'Description'}
        allowedChipTypes={[BUSINESS_TERM_TYPE]}
        onChipCollectionChange={onChipCollectionChange}
      />
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={(formik) => getDetailFields(formik, navigationController)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={BUSINESS_TERM_TYPE}/>
      <RelatedBusinessTermReferencesFieldWithFormik
        additionalFilters={[excludeFieldValueFilter('name', formik.initialValues.name)]}
        relationTypeOptions={BusinessTermRelatedBusinessTermRelationTypeOptions}
        relationTypeDefaultValue={BusinessTermRelatedBusinessTermRelationType.RELATED_TO}
      />
      <FeatureChecker featureId={Feature.BUSINESS_TERMS_RELATED_VALUES}>
        <RelatedValuesFieldWithFormik/>
      </FeatureChecker>
      <FeatureChecker featureId={Feature.REQUIREMENTS}>
        <RequirementsFieldWithFormik/>
      </FeatureChecker>
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.BUSINESS_TERM}/>
      <FeatureChecker featureId={Feature.BUSINESS_TERMS}>
        <ChildrenCountFieldWithFormik
          singularLabel={BUSINESS_TERM_LABEL}
          pluralLabel={BUSINESS_TERM_PLURAL_LABEL}
          value={formik.values.childrenCounts.businessTermsCount}
          iconName={RELATED_BUSINESS_TERM_REFERENCED_BY_ICON}
          pinIconName={BUSINESS_TERM_ICON}
          childFieldType={BUSINESS_TERM_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
          onClick={() => navigationController.openList(BUSINESS_TERM_TYPE, formik.values.id)}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.BUSINESS_RULES}>
        <ChildrenCountFieldWithFormik
          singularLabel={BUSINESS_RULE_LABEL}
          pluralLabel={BUSINESS_RULE_PLURAL_LABEL}
          value={formik.values.childrenCounts.businessRulesCount}
          iconName={BUSINESS_RULE_ICON}
          pinIconName={BUSINESS_TERM_ICON}
          childFieldType={BUSINESS_RULE_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.DATA_STRUCTURES}>
        <ChildrenCountFieldWithFormik
          singularLabel={DATA_STRUCTURE_LABEL}
          pluralLabel={DATA_STRUCTURE_PLURAL_LABEL}
          value={formik.values.childrenCounts.dataStructuresCount}
          iconName={DATA_STRUCTURE_ICON}
          pinIconName={BUSINESS_TERM_ICON}
          childFieldType={DATA_STRUCTURE_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.DATA_FIELDS}>
        <ChildrenCountFieldWithFormik
          singularLabel={DATA_FIELD_LABEL}
          pluralLabel={DATA_FIELD_PLURAL_LABEL}
          value={formik.values.childrenCounts.dataFieldsCount}
          iconName={DATA_FIELD_ICON}
          pinIconName={BUSINESS_TERM_ICON}
          childFieldType={DATA_FIELD_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.ENTITIES}>
        <ChildrenCountFieldWithFormik
          singularLabel={ENTITY_LABEL}
          pluralLabel={ENTITY_PLURAL_LABEL}
          value={formik.values.childrenCounts.entitiesCount}
          iconName={ENTITY_ICON}
          pinIconName={BUSINESS_TERM_ICON}
          childFieldType={ENTITY_TYPE}
          childrenCountWording={ChildrenCountWording.BASE_FOR}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.ATTRIBUTE_DEFINITIONS}>
        <ChildrenCountFieldWithFormik
          singularLabel={ATTRIBUTE_DEFINITION_LABEL}
          pluralLabel={ATTRIBUTE_DEFINITION_PLURAL_LABEL}
          value={formik.values.childrenCounts.attributeDefinitionsCount}
          iconName={ATTRIBUTE_DEFINITION_ICON}
          pinIconName={BUSINESS_TERM_ICON}
          childFieldType={ATTRIBUTE_DEFINITION_TYPE}
          childrenCountWording={ChildrenCountWording.BASE_FOR}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.PROCESS_MAPPINGS_TARGET_BT}>
        <ChildrenCountFieldWithFormik
          singularLabel={PROCESS_MAPPING_LABEL}
          pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.processMappingsCount}
          iconName={PROCESS_MAPPING_ICON}
          pinIconName={BUSINESS_TERM_ICON}
          childFieldType={PROCESS_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.TARGET_OF}
        />
      </FeatureChecker>
      <DataLineageDiagramButton
        objectType={BUSINESS_TERM_TYPE}
        id={formik.values.id}
        disabled={formik.status.isEntityInDetailDeleted}
      />
      <BusinessTermDiagramButton
        id={formik.values.id}
        disabled={formik.status.isEntityInDetailDeleted}
      />
    </>
  );
};

export default BusinessTermDetail;
