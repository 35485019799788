import BusinessGlossaryMenuItem from './BusinessGlossaryMenuItem';
import { ATTRIBUTE_ICON, ATTRIBUTE_PLURAL_LABEL, ATTRIBUTE_TYPE } from '../attribute/types/attributeTypes';
import { ATTRIBUTE_DEFINITION_ICON, ATTRIBUTE_DEFINITION_PLURAL_LABEL, ATTRIBUTE_DEFINITION_TYPE } from '../attributeDefinition/types/attributeDefinitionTypes';
import { BUSINESS_RULE_ICON, BUSINESS_RULE_PLURAL_LABEL, BUSINESS_RULE_TYPE } from '../businessRule/types/businessRuleTypes';
import { BUSINESS_TERM_ICON, BUSINESS_TERM_PLURAL_LABEL, BUSINESS_TERM_TYPE } from '../businessTerm/types/businessTermTypes';
import { COMPOSITE_TYPE_ICON, COMPOSITE_TYPE_PLURAL_LABEL, COMPOSITE_TYPE_TYPE } from '../compositeType/types/compositeTypeTypes';
import { CUSTOM_PROPERTY_ICON, CUSTOM_PROPERTY_PLURAL_LABEL, CUSTOM_PROPERTY_TYPE } from '../../common/customProperties/customProperty/types/customPropertyTypes';
import {
  CUSTOM_PROPERTY_GROUP_ICON,
  CUSTOM_PROPERTY_GROUP_PLURAL_LABEL,
  CUSTOM_PROPERTY_GROUP_TYPE
} from '../../common/customProperties/customPropertyGroup/types/customPropertyGroupTypes';
import { ENTITY_ICON, ENTITY_PLURAL_LABEL, ENTITY_TYPE } from '../entity/types/entityTypes';
import { DATA_FIELD_ICON, DATA_FIELD_PLURAL_LABEL, DATA_FIELD_TYPE } from '../dataField/types/dataFieldTypes';
import { DATA_SET_ICON, DATA_SET_PLURAL_LABEL, DATA_SET_TYPE } from '../dataSet/types/dataSetTypes';
import { DATA_SOURCE_ICON, DATA_SOURCE_PLURAL_LABEL, DATA_SOURCE_TYPE } from '../dataSource/types/dataSourceTypes';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_PLURAL_LABEL, DATA_STRUCTURE_TYPE } from '../dataStructure/types/dataStructureTypes';
import { REQUIREMENT_ICON, REQUIREMENT_PLURAL_LABEL, REQUIREMENT_TYPE } from '../requirement/types/requirementTypes';
import { STATUS_ICON, STATUS_PLURAL_LABEL, STATUS_TYPE } from '../status/types/statusTypes';
import { VALUE_TYPE_ICON, VALUE_TYPE_PLURAL_LABEL, VALUE_TYPE_TYPE } from '../valueType/types/valueTypeTypes';
import { USER_ICON, USER_PLURAL_LABEL, USER_TYPE } from '../user/types/userTypes';
import React from 'react';
import { Feature } from '../../common/userSettings/features/features';
import { DATA_ASSET_ICON, DATA_ASSET_PLURAL_LABEL, DATA_ASSET_TYPE } from '../dataAsset/types/dataAssetTypes';
import { BUSINESS_MODEL_MAPPING_ICON, BUSINESS_MODEL_MAPPING_PLURAL_LABEL, BUSINESS_MODEL_MAPPING_TYPE } from '../businessModelMapping/types/businessModelMappingTypes';
import { BUSINESS_MODEL_DIAGRAM_ICON, BUSINESS_MODEL_DIAGRAM_PLURAL_LABEL, BUSINESS_MODEL_DIAGRAM_TYPE } from '../businessModelDiagram/types/businessModelDiagramTypes';
import { PROCESS_ICON, PROCESS_PLURAL_LABEL, PROCESS_TYPE } from '../process/types/processTypes';
import { PROCESS_STEP_ICON, PROCESS_STEP_PLURAL_LABEL, PROCESS_STEP_TYPE } from '../process/step/types/processStepTypes';
import { PROCESS_MAPPING_ICON, PROCESS_MAPPING_PLURAL_LABEL, PROCESS_MAPPING_TYPE } from '../process/mapping/types/processMappingTypes';
import { TECHNICAL_DATA_MAPPING_ICON, TECHNICAL_DATA_MAPPING_PLURAL_LABEL, TECHNICAL_DATA_MAPPING_TYPE } from '../technicalModelMapping/types/technicalDataMappingTypes';
import { PROCESS_DIAGRAM_ICON, PROCESS_DIAGRAM_PLURAL_LABEL, PROCESS_DIAGRAM_TYPE } from '../processDiagram/types/processDiagramTypes';

export const AttributeMenuItem =
  <BusinessGlossaryMenuItem
    type={ATTRIBUTE_TYPE}
    label={ATTRIBUTE_PLURAL_LABEL}
    icon={ATTRIBUTE_ICON}
    features={[Feature.ATTRIBUTES]}
  />;

export const AttributeDefinitionMenuItem =
  <BusinessGlossaryMenuItem
    type={ATTRIBUTE_DEFINITION_TYPE}
    label={ATTRIBUTE_DEFINITION_PLURAL_LABEL}
    icon={ATTRIBUTE_DEFINITION_ICON}
    features={[Feature.ATTRIBUTE_DEFINITIONS]}
  />;

export const BusinessModelDiagramMenuItem =
  <BusinessGlossaryMenuItem
    type={BUSINESS_MODEL_DIAGRAM_TYPE}
    label={BUSINESS_MODEL_DIAGRAM_PLURAL_LABEL}
    icon={BUSINESS_MODEL_DIAGRAM_ICON}
    features={[Feature.BUSINESS_MODEL_DIAGRAMS]}
  />;

export const BusinessRuleMenuItem =
  <BusinessGlossaryMenuItem
    type={BUSINESS_RULE_TYPE}
    label={BUSINESS_RULE_PLURAL_LABEL}
    icon={BUSINESS_RULE_ICON}
    features={[Feature.BUSINESS_RULES]}
  />;

export const BusinessTermMenuItem =
  <BusinessGlossaryMenuItem
    type={BUSINESS_TERM_TYPE}
    label={BUSINESS_TERM_PLURAL_LABEL}
    icon={BUSINESS_TERM_ICON}
    features={[Feature.BUSINESS_TERMS]}
  />;

export const CompositeTypeMenuItem =
  <BusinessGlossaryMenuItem
    type={COMPOSITE_TYPE_TYPE}
    label={COMPOSITE_TYPE_PLURAL_LABEL}
    icon={COMPOSITE_TYPE_ICON}
    features={[Feature.COMPOSITE_TYPES]}
  />;

export const CustomPropertyMenuItem =
  <BusinessGlossaryMenuItem
    label={CUSTOM_PROPERTY_PLURAL_LABEL}
    type={CUSTOM_PROPERTY_TYPE}
    icon={CUSTOM_PROPERTY_ICON}
  />;

export const CustomPropertyGroupMenuItem =
  <BusinessGlossaryMenuItem
    type={CUSTOM_PROPERTY_GROUP_TYPE}
    label={CUSTOM_PROPERTY_GROUP_PLURAL_LABEL}
    icon={CUSTOM_PROPERTY_GROUP_ICON}
  />;

export const EntityMenuItem =
  <BusinessGlossaryMenuItem
    type={ENTITY_TYPE}
    label={ENTITY_PLURAL_LABEL}
    icon={ENTITY_ICON}
    features={[Feature.ENTITIES]}
  />;

export const DataFieldMenuItem =
  <BusinessGlossaryMenuItem
    type={DATA_FIELD_TYPE}
    label={DATA_FIELD_PLURAL_LABEL}
    icon={DATA_FIELD_ICON}
    features={[Feature.DATA_FIELDS]}
  />;

export const TechnicalDataMappingMenuItem =
  <BusinessGlossaryMenuItem
    type={TECHNICAL_DATA_MAPPING_TYPE}
    label={TECHNICAL_DATA_MAPPING_PLURAL_LABEL}
    icon={TECHNICAL_DATA_MAPPING_ICON}
    features={[Feature.TECHNICAL_DATA_MAPPINGS]}
  />;

export const DataAssetMenuItem =
  <BusinessGlossaryMenuItem
    type={DATA_ASSET_TYPE}
    label={DATA_ASSET_PLURAL_LABEL}
    icon={DATA_ASSET_ICON}
    features={[Feature.DATA_ASSETS]}
  />;

export const DataSetMenuItem =
  <BusinessGlossaryMenuItem
    type={DATA_SET_TYPE}
    label={DATA_SET_PLURAL_LABEL}
    icon={DATA_SET_ICON}
    features={[Feature.LOGICAL_DATA_SETS, Feature.PHYSICAL_DATA_SETS]}
  />;

export const DataSourceMenuItem =
  <BusinessGlossaryMenuItem
    type={DATA_SOURCE_TYPE}
    label={DATA_SOURCE_PLURAL_LABEL}
    icon={DATA_SOURCE_ICON}
    features={[Feature.DATA_SOURCES]}
  />;

export const DataStructureMenuItem =
  <BusinessGlossaryMenuItem
    type={DATA_STRUCTURE_TYPE}
    label={DATA_STRUCTURE_PLURAL_LABEL}
    icon={DATA_STRUCTURE_ICON}
    features={[Feature.DATA_STRUCTURES]}
  />;

export const RequirementMenuItem =
  <BusinessGlossaryMenuItem
    type={REQUIREMENT_TYPE}
    label={REQUIREMENT_PLURAL_LABEL}
    icon={REQUIREMENT_ICON}
    features={[Feature.REQUIREMENTS]}
  />;

export const BusinessModelMappingMenuItem =
  <BusinessGlossaryMenuItem
    type={BUSINESS_MODEL_MAPPING_TYPE}
    label={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
    icon={BUSINESS_MODEL_MAPPING_ICON}
    features={[Feature.BUSINESS_MODEL_MAPPINGS]}
  />;

export const ProcessMenuItem =
  <BusinessGlossaryMenuItem
    type={PROCESS_TYPE}
    label={PROCESS_PLURAL_LABEL}
    icon={PROCESS_ICON}
    features={[Feature.PROCESSES]}
  />;

export const ProcessDiagramMenuItem =
  <BusinessGlossaryMenuItem
    type={PROCESS_DIAGRAM_TYPE}
    label={PROCESS_DIAGRAM_PLURAL_LABEL}
    icon={PROCESS_DIAGRAM_ICON}
    features={[Feature.PROCESS_DIAGRAMS]}
  />;

export const ProcessStepMenuItem =
  <BusinessGlossaryMenuItem
    type={PROCESS_STEP_TYPE}
    label={PROCESS_STEP_PLURAL_LABEL}
    icon={PROCESS_STEP_ICON}
    features={[Feature.PROCESS_STEPS]}
  />;

export const ProcessMappingMenuItem =
  <BusinessGlossaryMenuItem
    type={PROCESS_MAPPING_TYPE}
    label={PROCESS_MAPPING_PLURAL_LABEL}
    icon={PROCESS_MAPPING_ICON}
    features={[Feature.PROCESS_MAPPINGS]}
  />;

export const StatusTypeMenuItem =
  <BusinessGlossaryMenuItem
    type={STATUS_TYPE}
    label={STATUS_PLURAL_LABEL}
    icon={STATUS_ICON}
  />;

export const ValueTypeMenuItem =
  <BusinessGlossaryMenuItem
    type={VALUE_TYPE_TYPE}
    label={VALUE_TYPE_PLURAL_LABEL}
    icon={VALUE_TYPE_ICON}
    features={[Feature.VALUE_TYPES]}
  />;

export const UserMenuItem =
  <BusinessGlossaryMenuItem
    type={USER_TYPE}
    label={USER_PLURAL_LABEL}
    icon={USER_ICON}
    features={[Feature.USERS_MANAGEMENT]}
  />;
