import i18n from 'i18next';
import { DATA_STRUCTURE_TYPE } from '../../businessGlossary/dataStructure/types/dataStructureTypes';
import { PROCESS_TYPE } from '../../businessGlossary/process/types/processTypes';

export const getBulkNotificationsUpdatedMessage = (updatedObjectsCnt: number, objectType: string, isActive: boolean) =>
  i18n.t('0030', { count: updatedObjectsCnt, objectType, onoff: isActive ? 'ON' : 'OFF' });

export const getBulkRemovalInProgressMessage = (objectType: string) =>
  i18n.t('0120', { objectType });

export const getBulkRemovalFinishedMessage = (objectType: string) =>
  i18n.t('0121', { objectType });

export const getBulkUpdateInProgressMessage = (objectType: string) =>
  i18n.t('0122', { objectType });

export const getBulkUpdateFinishedMessage = (objectType: string) =>
  i18n.t('0123', { objectType });

export const getRemovalDialogMessage = (listType: string, selectedRowsCnt: number) => {
  const genericRemovalMsg = i18n.t('bulkOperations.genericRemoval', { count: selectedRowsCnt, listType });
  switch (listType) {
    case DATA_STRUCTURE_TYPE:
      return genericRemovalMsg + i18n.t('bulkOperations.dependencyRemoval', { dependencies: 'Data Fields' });
    // Currently there is no cascade removal for Data Set/CPG and its related objects. This might come in future
    // case DATA_SET_TYPE:
    //   return genericRemovalMsg + i18n.t('bulkOperations.dependencyRemoval', { dependencies: 'Data Structures and Data Fields' });
    // case CUSTOM_PROPERTY_GROUP_TYPE:
    //   return genericRemovalMsg + i18n.t('bulkOperations.dependencyRemoval', { dependencies: 'Custom Properties' });
    case PROCESS_TYPE:
      return genericRemovalMsg + i18n.t('bulkOperations.dependencyRemoval', { dependencies: 'Process Steps' });
    default:
      return genericRemovalMsg;
  }
};
