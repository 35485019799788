import React from 'react';

import { CHILD_DATA_ASSET_LABEL, CHILD_DATA_ASSETS_LABEL, DATA_ASSET_ICON, DATA_ASSET_TYPE, DataAsset, PARENT_DATA_ASSET_LABEL } from '../types/dataAssetTypes';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import { DetailHeader, EnumOption } from 'ts-components';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import ReferenceFieldWithFormik from '../../../common/detail/formik/ReferenceFieldWithFormik';
import { createUniqueWithinCollectionFilters, excludeFieldValueFilter } from '../../../common/referenceField/utils/filters';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import CollectionFieldWithFormik from '../../../common/detail/formik/CollectionFieldWithFormik';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_PLURAL_LABEL, DATA_SET_TYPE, useDataSetTypeEnumOptions } from '../../dataSet/types/dataSetTypes';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import { getLabelForValue } from '../../../common/childrenCountField/utils';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { defaultTestDraggedItem, defaultTestDroppedItem } from '../../../common/referenceField/utils/dragAndDrop';
import { PROCESS_MAPPING_ICON, PROCESS_MAPPING_LABEL, PROCESS_MAPPING_PLURAL_LABEL, PROCESS_MAPPING_TYPE } from '../../process/mapping/types/processMappingTypes';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import RelatedDataAssetRow from './RelatedDataAssetRow';
import GenerateProcessDiagramButton from '../../processDiagram/detail/GenerateProcessDiagramButton';
import { ProcessDiagramBasedOnType } from '../../processDiagram/types/processDiagramTypes';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';

interface DataAssetDetailProps {
  id?: string
}

const DataAssetDetail = ({ id }: DataAssetDetailProps) => {
  const hasFeature = useHasFeature();
  const multipleDataSetTypesAllowedFeature = hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED);
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  const generateProcessDiagramButton =
    <GenerateProcessDiagramButton
      key="processDiagramButton"
      basedOnType={ProcessDiagramBasedOnType.DATA_ASSETS}
      objectType={DATA_ASSET_TYPE}
      id={id}
    />;

  return (
    <AccurityDetailContainer<DataAsset>
      objectType={DATA_ASSET_TYPE}
      id={id}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions)}
              <DefaultDetailBottomBar
                objectType={DATA_ASSET_TYPE}
                detailBag={detailBag}
                customMenuItems={generateProcessDiagramButton}
              />
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<DataAsset>, detailBag: DetailBag<DataAsset>) => (
  <ConfirmRemovalDetail
    iconName={DATA_ASSET_ICON}
    detailBag={detailBag}
  >
    <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
      <ChildrenCountFieldWithFormik
        singularLabel={PROCESS_MAPPING_LABEL}
        pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
        value={formik.values.childrenCounts.processMappingsCount}
        iconName={PROCESS_MAPPING_ICON}
        pinIconName={DATA_ASSET_ICON}
        childFieldType={PROCESS_MAPPING_TYPE}
        childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        inverted={true}
      />
    </FeatureChecker>
  </ConfirmRemovalDetail>
);

const getDetailFields = (formik: FormikProps<DataAsset>,
                         multipleDataSetTypesAllowedFeature: boolean,
                         dataSetTypeEnumOptions: EnumOption[]) => {
  const dataSetsUniqueFilter = createUniqueWithinCollectionFilters('name', formik.values.dataSets);
  return (
    <FeatureChecker featureId={Feature.DATA_ASSETS}>
      <DetailHeader iconName={DATA_ASSET_ICON}>
        <NameFieldWithFormik label={'Data Asset Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik, multipleDataSetTypesAllowedFeature, dataSetTypeEnumOptions)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={DATA_ASSET_TYPE}/>
      <ReferenceFieldWithFormik
        name={'parent'}
        label={PARENT_DATA_ASSET_LABEL}
        objectType={DATA_ASSET_TYPE}
        icon={DATA_ASSET_ICON}
        showDefaultTooltip={false}
        customEndpoint={formik.values.id ? `${DATA_ASSET_TYPE}/parent-reference/${formik.values.id}` : undefined}
        additionalFilters={[excludeFieldValueFilter('name', formik.initialValues.name)]}
      />
      <CollectionFieldWithFormik
        name={'dataSets'}
        title={getLabelForValue(DATA_SET_LABEL, DATA_SET_PLURAL_LABEL, formik.values.dataSets.length, ChildrenCountWording.REFERENCES)}
        icon={DATA_SET_ICON}
        rowValidation={(value) => value && value.id}
        testDraggedItem={defaultTestDraggedItem(DATA_SET_TYPE)}
        testDroppedItem={defaultTestDroppedItem({ objectType: DATA_SET_TYPE, additionalFilters: dataSetsUniqueFilter })}
        renderRow={(elementName) =>
          <RelatedDataAssetRow
            elementName={elementName}
            additionalFilters={dataSetsUniqueFilter}
            dataSetTypeEnumOptions={dataSetTypeEnumOptions}
            multipleDataSetTypesAllowedFeature={multipleDataSetTypesAllowedFeature}
          />
        }
      />
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.DATA_ASSET}/>
      <ChildrenCountFieldWithFormik
        singularLabel={CHILD_DATA_ASSET_LABEL}
        pluralLabel={CHILD_DATA_ASSETS_LABEL}
        value={formik.values.childrenCounts.dataAssetsCount}
        iconName={DATA_ASSET_ICON}
        pinIconName={DATA_ASSET_ICON}
        childFieldType={DATA_ASSET_TYPE}
        childrenCountWording={ChildrenCountWording.HAS}
      />
      <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
        <ChildrenCountFieldWithFormik
          singularLabel={PROCESS_MAPPING_LABEL}
          pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.processMappingsCount}
          iconName={PROCESS_MAPPING_ICON}
          pinIconName={DATA_ASSET_ICON}
          childFieldType={PROCESS_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        />
      </FeatureChecker>
    </FeatureChecker>
  );
};

export default DataAssetDetail;
