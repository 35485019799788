import { DetailInlineGroup } from 'ts-components';
import React from 'react';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE } from '../../../dataStructure/types/dataStructureTypes';
import { DATA_FIELD_ICON, DATA_FIELD_LABEL, DATA_FIELD_TYPE, DataFieldReference } from '../../../dataField/types/dataFieldTypes';
import SingleLineFieldWithFormik from '../../../../common/detail/formik/SinglelineFieldWithFormik';
import {
  TECHNICAL_DATA_MAPPING_BASE_SELECTION_ITEMS_FIELD,
  TECHNICAL_DATA_MAPPING_SELECTION_CRITERIA_FIELD,
  TECHNICAL_DATA_MAPPING_SELECTION_CRITERIA_LABEL,
  TechnicalDataMapping,
  TechnicalDataMappingBaseSelectionItem
} from '../../types/technicalDataMappingTypes';
import { FormikProps, useField } from 'formik';
import { createReferenceFieldFilter, createUniqueWithinCollectionFilters } from '../../../../common/referenceField/utils/filters';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { useVersionBrowserColoring } from '../../../../common/versionBrowserField/hooks/versionBrowserColoringHook';

interface DataStructureDataFieldSelectionProps {
  elementName: string;
  index: number;
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<TechnicalDataMapping>;
}

const TechnicalDataMappingBaseDataStructureDataFieldSelection = ({ elementName, index, additionalFilters = [], formik }: DataStructureDataFieldSelectionProps) => {

  const dataStructuresFilter = [
    createReferenceFieldFilter('dataSet.id', formik.values.baseDataSet?.id),
    createReferenceFieldFilter('childrenCounts.dataFieldsCount', '>=1'),
    ...additionalFilters,
  ];

  const [field] = useField<TechnicalDataMappingBaseSelectionItem[]>(TECHNICAL_DATA_MAPPING_BASE_SELECTION_ITEMS_FIELD);
  const dataStructures = field.value.map(row => row?.dataStructure);
  const dataFields = field.value.map(row => row?.dataField);
  const dataStructureId = dataStructures[index]?.id;

  const dataFieldsFilter = createUniqueWithinCollectionFilters('name', dataFields);

  dataFieldsFilter.push(...additionalFilters,
    createReferenceFieldFilter('dataSet.id', formik.values.baseDataSet?.id));

  if (dataStructureId) {
    dataFieldsFilter.push(
      createReferenceFieldFilter('dataStructure.id', dataStructureId)
    );
  }

  const coloring = useVersionBrowserColoring(elementName);

  return (
    <>
      <DetailInlineGroup
        childGridSizes={[6, 6]}
        coloring={coloring}
      >
        <ReferenceFieldWithFormik
          name={elementName + '.dataStructure'}
          label={DATA_STRUCTURE_LABEL}
          objectType={DATA_STRUCTURE_TYPE}
          icon={DATA_STRUCTURE_ICON}
          additionalFilters={dataStructuresFilter}
          setValue={(dataStructure) => {
            formik.setFieldValue(elementName + '.dataStructure', dataStructure);
            formik.setFieldValue(elementName + '.dataField', null);
          }}
          disableColoring={true}
        />
        <ReferenceFieldWithFormik<DataFieldReference>
          name={elementName + '.dataField'}
          label={DATA_FIELD_LABEL}
          objectType={DATA_FIELD_TYPE}
          icon={DATA_FIELD_ICON}
          additionalFilters={dataFieldsFilter}
          setValue={(dataField) => {
            if (!dataStructureId) {
              formik.setFieldValue(elementName + '.dataStructure', dataField?.dataStructure);
            }
            formik.setFieldValue(elementName + '.dataField', dataField);
          }}
          disableColoring={true}
        />
      </DetailInlineGroup>
      <DetailInlineGroup
        indentLeft={true}
        coloring={coloring}
      >
        <SingleLineFieldWithFormik
          name={elementName + '.' + TECHNICAL_DATA_MAPPING_SELECTION_CRITERIA_FIELD}
          label={TECHNICAL_DATA_MAPPING_SELECTION_CRITERIA_LABEL}
          disableColoring={true}
        />
      </DetailInlineGroup>
    </>
  )
};

export default TechnicalDataMappingBaseDataStructureDataFieldSelection;
