export enum Feature {
  BUSINESS_TERMS = 'business-terms',
  BUSINESS_TERMS_RELATED_VALUES = 'business-terms-related-values',
  BUSINESS_RULES = 'business-rules',
  ENTITIES = 'entities',
  COMPOSITE_TYPES = 'composite-types',
  ATTRIBUTE_DEFINITIONS = 'attribute-definitions',
  ATTRIBUTES = 'attributes',
  DATA_SOURCES = 'data-sources',
  DATA_ASSETS = 'data-assets',
  MULTIPLE_DATA_SET_TYPES_ALLOWED = 'multiple-data-set-types-allowed',
  PHYSICAL_DATA_SETS = 'physical-data-sets',
  LOGICAL_DATA_SETS = 'logical-data-sets',
  DATA_FIELDS = 'data-fields',
  DATA_STRUCTURES = 'data-structures',
  TECHNICAL_DATA_MAPPINGS = 'technical-data-mappings',
  VALUE_TYPES = 'value-types',
  DATA_LINEAGE_DIAGRAMS = 'data-lineage-diagrams',
  VERSION_BROWSER = 'version-browser',
  COMMENTS = 'comments',
  ALWAYS_EDITABLE_EMAIL = 'always-editable-email',
  REQUIREMENTS = 'requirements',
  BUSINESS_MODEL_MAPPINGS = 'business-model-mappings',
  CALCULATION_RULES = 'calculation-rules',
  BUSINESS_MODEL_DIAGRAMS = 'business-model-diagrams',
  PROCESSES = 'processes',
  PROCESS_DIAGRAMS = 'process-diagrams',
  PROCESS_STEPS = 'process-steps',
  PROCESS_MAPPINGS = 'process-mappings',
  PROCESS_MAPPING_TARGET_ENTITIES = 'process-mapping-target-entities',
  PROCESS_MAPPING_TARGET_ATTRIBUTES = 'process-mapping-target-attributes',
  PROCESS_MAPPINGS_TARGET_BT = 'process-mappings-target-bt',
  ADVANCED_CUSTOM_PROPERTIES = 'advanced-custom-properties',
  OBJECT_SUGGESTION = 'object-suggestion',
  CUSTOM_LOGO = 'custom-logo',
  SCHEDULE_DEMO = 'schedule-demo',
  USERS_MANAGEMENT = 'users-management',
  COLUMN_PROFILING = 'column-profiling',
  BROWSER_EXTENSION = 'browser-extension',
}
