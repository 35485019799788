import React from 'react';
import { AccurityButton } from 'ts-components';
import { useCatalogSyncPermission } from '../hooks/useCatalogSyncPermission';
import { useFormikContext } from 'formik';
import { DataSetReference, DataSetType, isDataSet } from '../../../businessGlossary/dataSet/types/dataSetTypes';
import { isDataStructure } from '../../../businessGlossary/dataStructure/types/dataStructureTypes';
import { AccurityCoreEntity } from '../../types/accurityTypes';

type DataSyncCheckUpdatesButtonProps = {
  onClick: () => void;
}

export const DataSyncCheckUpdatesButton = ({ onClick }: DataSyncCheckUpdatesButtonProps) => {

  const { canPerformSync } = useCatalogSyncPermission();
  const { values: detailObject, status } = useFormikContext<AccurityCoreEntity>();

  if (!isDataSet(detailObject) && !isDataStructure(detailObject)) {
    console.error('DataSyncCheckUpdatesButton can be used only in Data Set or Data Structure Detail');
    return null;
  }

  const dataSetReference: DataSetReference = isDataStructure(detailObject) ? detailObject.dataSet : detailObject;
  const { isUpdateDetail, isEntityInDetailDeleted, isEntityInDetailOutdated } = status;
  const isValidFormikStatus = isUpdateDetail && !isEntityInDetailDeleted && !isEntityInDetailOutdated;
  if (
    !canPerformSync ||
    !isValidFormikStatus ||
    dataSetReference.dataSetType !== DataSetType.PHYSICAL
  ) {
    return null;
  }

  // todo we do not support Data Structure in first phase. Remove this condition when #24949 is implemented
  if (isDataStructure(detailObject)) {
    return null;
  }

  return (
    <AccurityButton
      onClick={onClick}
      color={'secondary'}
    >
      Synchronize
    </AccurityButton>
  )
};
