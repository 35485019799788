import React from 'react';
import { useEntityListColumns } from './entityListColumns';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { ENTITY_ICON, ENTITY_PLURAL_LABEL, ENTITY_TYPE } from '../types/entityTypes';

const EntityList = () => {
  const entityColumns = useEntityListColumns();
  const entityListSettings = getDefaultListSettings(ENTITY_TYPE, entityColumns);

  return (
    <AccurityListContainer
      objectType={ENTITY_TYPE}
      title={ENTITY_PLURAL_LABEL}
      icon={ENTITY_ICON}
      columns={entityColumns}
      defaultListSettings={entityListSettings}
    />
  );
};

export default EntityList;
