import React from 'react';
import { useDataAssetListColumns } from './dataAssetListColumns';
import { DATA_ASSET_ICON, DATA_ASSET_PLURAL_LABEL, DATA_ASSET_TYPE } from '../types/dataAssetTypes';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';

const DataAssetList = () => {
  const dataAssetColumns = useDataAssetListColumns();
  const dataAssetListSettings = getDefaultListSettings(DATA_ASSET_TYPE, dataAssetColumns);

  return (
    <AccurityListContainer
      objectType={DATA_ASSET_TYPE}
      title={DATA_ASSET_PLURAL_LABEL}
      icon={DATA_ASSET_ICON}
      columns={dataAssetColumns}
      defaultListSettings={dataAssetListSettings}
      allowBulkOperations={true}
    />
  );
};

export default DataAssetList;
