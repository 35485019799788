import { EnumOption, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../list/commonColumns/columnDefinitions';
import { Feature } from '../../../userSettings/features/features';
import { useHasFeature } from '../../../userSettings/hooks/features';
import { useIsOracleDb } from '../../../appSettings/hooks/environmentInfoHooks';

export const useCustomPropertyListColumns = (customPropertyObjectTypeOptions: EnumOption[],
                                             customPropertyPropertyTypeOptions: EnumOption[],
                                             customPropertyTargetObjectTypeOptions: EnumOption[]) => {
  const hasFeature = useHasFeature();
  const isOracle = useIsOracleDb();

  return [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description(!isOracle),
    columnFactory.createEnumColumn('forObjectType', 'Object Type', customPropertyObjectTypeOptions),
    columnFactory.createEnumColumn('propertyType', 'Property Type', customPropertyPropertyTypeOptions),
    ...(hasFeature(Feature.ADVANCED_CUSTOM_PROPERTIES) ? [columnFactory.createEnumColumn('targetObjectType', 'Target Object Type', customPropertyTargetObjectTypeOptions, { hidden: true })] : []),
    columnFactory.createTextColumn('options.value', 'Enumeration Options', { sortable: false, hidden: true }),
    columnFactory.createTextColumn('customPropertyGroup.name', 'Custom Property Group'),
    columnFactory.createBooleanColumn('multiselection', 'Multiselection'),
    ...(hasFeature(Feature.ADVANCED_CUSTOM_PROPERTIES) ? [columnFactory.createBooleanColumn('mandatory', 'Mandatory', { hidden: true })] : []),
    columnFactory.createNumberColumn('valueOrder', 'Order', undefined, NumericFormat.POSITIVE_NUMERIC),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ];

};