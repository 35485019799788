import { Align, Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import {
  TECHNICAL_DATA_MAPPING_BASE_JOIN_PLURAL_LABEL,
  TECHNICAL_DATA_MAPPING_BASE_LABEL,
  TECHNICAL_DATA_MAPPING_BASE_SELECTION_PLURAL_LABEL,
  TECHNICAL_DATA_MAPPING_CRITERIA_BASE_SELECTIONS_LABEL,
  TECHNICAL_DATA_MAPPING_CRITERIA_TARGET_SELECTIONS_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_FIELDS_BASE_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_FIELDS_BASE_SELECTIONS_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_FIELDS_TARGET_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_FIELDS_TARGET_SELECTIONS_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_SET_BASE_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_SET_TARGET_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_SET_TYPE_BASE_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_SET_TYPE_TARGET_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_BASE_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_BASE_SELECTIONS_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_TARGET_LABEL,
  TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_TARGET_SELECTIONS_LABEL,
  TECHNICAL_DATA_MAPPING_LEFT_DATA_FIELDS_BASE_JOINS_LABEL,
  TECHNICAL_DATA_MAPPING_LEFT_DATA_FIELDS_TARGET_JOINS_LABEL,
  TECHNICAL_DATA_MAPPING_LEFT_DATA_STRUCTURE_BASE_JOINS_LABEL,
  TECHNICAL_DATA_MAPPING_LEFT_DATA_STRUCTURE_TARGET_JOINS_LABEL,
  TECHNICAL_DATA_MAPPING_OPERATIONS_BASE_LABEL,
  TECHNICAL_DATA_MAPPING_OPERATIONS_TARGET_LABEL,
  TECHNICAL_DATA_MAPPING_RIGHT_DATA_FIELDS_BASE_JOINS_LABEL,
  TECHNICAL_DATA_MAPPING_RIGHT_DATA_FIELDS_TARGET_JOINS_LABEL,
  TECHNICAL_DATA_MAPPING_RIGHT_DATA_STRUCTURE_BASE_JOINS_LABEL,
  TECHNICAL_DATA_MAPPING_RIGHT_DATA_STRUCTURE_TARGET_JOINS_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_JOIN_PLURAL_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_LABEL,
  TECHNICAL_DATA_MAPPING_TARGET_SELECTION_PLURAL_LABEL
} from '../types/technicalDataMappingTypes';
import { Feature } from '../../../common/userSettings/features/features';
import { useDataSetTypeEnumOptions } from '../../dataSet/types/dataSetTypes';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

export const useTechnicalDataMappingStartListColumns = (): Column[] => {
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  return [
    columnFactory.createNumberColumn('id', 'ID', {
      align: Align.LEFT,
      hideComparisonSelector: true
    }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createTextColumn('description.plainTextValue', 'Description', { hidden: true, sortable: !isOracle }),
    commonColumns.tags(navigationController),
  ];
};

export const useTechnicalDataMappingBaseListColumns = (): Column[] => {
  const hasFeature = useHasFeature();
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  return [
    columnFactory.createTextColumn('baseDataSet.name', TECHNICAL_DATA_MAPPING_DATA_SET_BASE_LABEL),
    ...(hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED) ?
      [columnFactory.createEnumColumn('baseDataSet.dataSetType',
        TECHNICAL_DATA_MAPPING_DATA_SET_TYPE_BASE_LABEL,
        dataSetTypeEnumOptions, { hidden: true })] : []),
    columnFactory.createTextColumn('technicalDataMappingBaseDataStructureDataFields.dataStructure.name',
      TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_BASE_LABEL,
      {
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingBaseDataStructureDataFields.dataField.name',
      TECHNICAL_DATA_MAPPING_DATA_FIELDS_BASE_LABEL,
      {
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingBaseDataStructureDataFields.operation',
      TECHNICAL_DATA_MAPPING_OPERATIONS_BASE_LABEL,
      {
        hidden: true, sortable: false
      }),
  ].map(column => {
    return { ...column, group: TECHNICAL_DATA_MAPPING_BASE_LABEL };
  });
};

export const useTechnicalDataMappingBaseSelectionsListColumns = (): Column[] => {

  return [
    columnFactory.createTextColumn('technicalDataMappingBaseSelectionItems.dataStructure.name',
      TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_BASE_SELECTIONS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingBaseSelectionItems.dataField.name',
      TECHNICAL_DATA_MAPPING_DATA_FIELDS_BASE_SELECTIONS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingBaseSelectionItems.criteria',
      TECHNICAL_DATA_MAPPING_CRITERIA_BASE_SELECTIONS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
  ].map(column => {
    return { ...column, group: TECHNICAL_DATA_MAPPING_BASE_SELECTION_PLURAL_LABEL };
  });
};

export const useTechnicalDataMappingBaseJoinsListColumns = (): Column[] => {

  return [
    columnFactory.createTextColumn('technicalDataMappingBaseJoinDataStructureDataField.leftDataStructure.name',
      TECHNICAL_DATA_MAPPING_LEFT_DATA_STRUCTURE_BASE_JOINS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingBaseJoinDataStructureDataField.rightDataStructure.name',
      TECHNICAL_DATA_MAPPING_RIGHT_DATA_STRUCTURE_BASE_JOINS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingBaseJoinDataStructureDataField.technicalDataMappingBaseJoinDataFields.leftDataField.name',
      TECHNICAL_DATA_MAPPING_LEFT_DATA_FIELDS_BASE_JOINS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingBaseJoinDataStructureDataField.technicalDataMappingBaseJoinDataFields.rightDataField.name',
      TECHNICAL_DATA_MAPPING_RIGHT_DATA_FIELDS_BASE_JOINS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
  ].map(column => {
    return { ...column, group: TECHNICAL_DATA_MAPPING_BASE_JOIN_PLURAL_LABEL };
  });
};

export const useTechnicalDataMappingTargetListColumns = (): Column[] => {
  const hasFeature = useHasFeature();
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();

  return [
    columnFactory.createTextColumn('targetDataSet.name', TECHNICAL_DATA_MAPPING_DATA_SET_TARGET_LABEL),
    ...(hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED) ?
      [columnFactory.createEnumColumn('targetDataSet.dataSetType',
        TECHNICAL_DATA_MAPPING_DATA_SET_TYPE_TARGET_LABEL,
        dataSetTypeEnumOptions, { hidden: true })] : []),
    columnFactory.createTextColumn('technicalDataMappingTargetDataStructureDataFields.dataStructure.name',
      TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_TARGET_LABEL,
      {
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingTargetDataStructureDataFields.dataField.name',
      TECHNICAL_DATA_MAPPING_DATA_FIELDS_TARGET_LABEL,
      {
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingTargetDataStructureDataFields.operation',
      TECHNICAL_DATA_MAPPING_OPERATIONS_TARGET_LABEL,
      {
        hidden: true, sortable: false
      }),
  ].map(column => {
    return { ...column, group: TECHNICAL_DATA_MAPPING_TARGET_LABEL };
  });
};

export const useTechnicalDataMappingTargetSelectionsListColumns = (): Column[] => {

  return [
    columnFactory.createTextColumn('technicalDataMappingTargetSelectionItems.dataStructure.name',
      TECHNICAL_DATA_MAPPING_DATA_STRUCTURES_TARGET_SELECTIONS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingTargetSelectionItems.dataField.name',
      TECHNICAL_DATA_MAPPING_DATA_FIELDS_TARGET_SELECTIONS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingTargetSelectionItems.criteria',
      TECHNICAL_DATA_MAPPING_CRITERIA_TARGET_SELECTIONS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
  ].map(column => {
    return { ...column, group: TECHNICAL_DATA_MAPPING_TARGET_SELECTION_PLURAL_LABEL };
  });
};

export const useTechnicalDataMappingTargetJoinsListColumns = (): Column[] => {

  return [
    columnFactory.createTextColumn('technicalDataMappingTargetJoinDataStructureDataField.leftDataStructure.name',
      TECHNICAL_DATA_MAPPING_LEFT_DATA_STRUCTURE_TARGET_JOINS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingTargetJoinDataStructureDataField.rightDataStructure.name',
      TECHNICAL_DATA_MAPPING_RIGHT_DATA_STRUCTURE_TARGET_JOINS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingTargetJoinDataStructureDataField.technicalDataMappingTargetJoinDataFields.leftDataField.name',
      TECHNICAL_DATA_MAPPING_LEFT_DATA_FIELDS_TARGET_JOINS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
    columnFactory.createTextColumn('technicalDataMappingTargetJoinDataStructureDataField.technicalDataMappingTargetJoinDataFields.rightDataField.name',
      TECHNICAL_DATA_MAPPING_RIGHT_DATA_FIELDS_TARGET_JOINS_LABEL,
      {
        hidden: true,
        sortable: false
      }),
  ].map(column => {
    return { ...column, group: TECHNICAL_DATA_MAPPING_TARGET_JOIN_PLURAL_LABEL };
  });
};

export const useTechnicalDataMappingEndListColumns = () => {

  const technicalDataMappingListColumns: Column[] = [
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ];

  return useValidColumns(technicalDataMappingListColumns, GlossaryCustomPropertyObjectType.TECHNICAL_DATA_MAPPING);
};
