import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CoreEntityState } from '../../redux/types';
import { AccurityCoreEntity, AccurityLocationState, AccurityPinFilter } from '../../types/accurityTypes';
import { ListSettings } from '../types/types';
import AccurityList from '../components/AccurityList';
import { listSaveSettings } from '../actions/listActions';
import { useLocation } from 'react-router-dom';
import { ExportFormat, ExportSearch } from '../../dataTransfer/types';
import { fileImport, listExport } from '../../dataTransfer/actions';
import { Column } from 'ts-components';
import { useAccurityNavigation } from '../../navigation/hooks';
import { mergeStoredListSettingsOntoDefault } from '../listUtils';
import { CrudPermissions, UserSettingsState } from '../../userSettings/types/types';
import { globalPinFiltersUpdate } from '../../userSettings/actions/userSettingsActions';
import { getSuccessfulRememberSettingsMessage, getSuccessfulRestoreSettingsMessage } from '../../userMessages/commonMessages';
import { CustomListSearchSteps, useListSearchAction } from '../actions/listSearch';
import { BULK_OPERATION_STORE_NAME, BulkOperationsState } from '../../bulkOperations/types';

interface AccurityListContainerProps {
  objectType: string;
  title: string;
  icon: string;
  allowImport?: boolean;
  allowExport?: boolean;
  columns: Column[];
  defaultListSettings: ListSettings;
  partialImportOnly?: boolean;
  hideAddButton?: boolean;
  hideObjectCounter?: boolean;
  permissionsOverwrite?: CrudPermissions;
  customListSearchSteps?: CustomListSearchSteps;
  handleRowSelectOverwrite?: (row: any) => void;
  allowBulkOperations?: boolean;
}

const AccurityListContainer = ({
                                 objectType,
                                 title,
                                 icon,
                                 allowImport,
                                 allowExport,
                                 columns,
                                 defaultListSettings,
                                 partialImportOnly,
                                 hideAddButton,
                                 hideObjectCounter,
                                 permissionsOverwrite,
                                 customListSearchSteps,
                                 handleRowSelectOverwrite,
                                 allowBulkOperations
                               }: AccurityListContainerProps) => {
  const navigationController = useAccurityNavigation();
  const location = useLocation<AccurityLocationState>();
  const dispatch = useDispatch();

  // LIST ACTIONS
  const customEndpoint = location?.state?.list?.customEndpoint;
  const doListSearch = useListSearchAction(objectType, customEndpoint, customListSearchSteps);

  const saveListSettings = useCallback((listSettings: ListSettings) => {
    const snackbarMessage = getSuccessfulRememberSettingsMessage(listSettings.type);
    dispatch(listSaveSettings(listSettings, snackbarMessage));
  }, [dispatch]);

  const restoreListSettings = useCallback(() => {
    const snackbarMessage = getSuccessfulRestoreSettingsMessage(defaultListSettings.type);
    dispatch(listSaveSettings(defaultListSettings, snackbarMessage, navigationController.reopenCurrentList));
  }, [dispatch, defaultListSettings, navigationController.reopenCurrentList]);

  const setGlobalPinFilters = useCallback((globalPinFilters: AccurityPinFilter[]) => {
    dispatch(globalPinFiltersUpdate(globalPinFilters));
  }, [dispatch]);

  const exportList = useCallback((format: ExportFormat, search: ExportSearch) => {
    dispatch(listExport(format, search));
  }, [dispatch]);

  const importFile = useCallback((file: File, partialImport: boolean) => {
    dispatch(fileImport(objectType, file, partialImport));
  }, [dispatch, objectType]);

  // GET SEARCH RESULTS FROM STORE
  const {
    rowIds,
    totalRows,
    existingObjects,
    maximumObjects,
    didInvalidateListSearch,
    additionalMaximumObjects,
    additionalExistingObjects,
    isListLoading,
    objectState
  } = useSelector((state: any) => {
    const objectState = state[objectType] as CoreEntityState<AccurityCoreEntity>;
    const bulkOperationsState = state[BULK_OPERATION_STORE_NAME] as BulkOperationsState;
    const searchResult = objectState.currentListSearchResult;
    const didInvalidateListSearch = objectState.invalidatedAt > searchResult.fetchedAt;
    const rowIds = searchResult ? searchResult.ids : [];
    const totalRows = searchResult ? searchResult.totalRows : 0;
    const existingObjects = searchResult ? searchResult.existingObjects : 0;
    const maximumObjects = searchResult ? searchResult.maximumObjects : -1;
    const isListLoading = objectState.isListLoading || bulkOperationsState.isInProgress;
    const { additionalMaximumObjects, additionalExistingObjects } = searchResult;
    return { rowIds, totalRows, existingObjects, maximumObjects, didInvalidateListSearch, additionalMaximumObjects, additionalExistingObjects, isListLoading, objectState };
  });

  const rows = useMemo(() =>
      rowIds.map(rowId => objectState.byId[rowId] ? objectState.byId[rowId].data : null)
    , [rowIds, objectState.byId]);

  const globalPinFilters: AccurityPinFilter[] = useSelector((state: { userSettings: UserSettingsState }) => state.userSettings.globalPinFilters);

  // GET LIST SETTINGS FROM STORE
  const listSettingsFromStore: ListSettings = useSelector((state: any) => state.userSettings.listSettings[defaultListSettings.type]);
  const listSettings = useMemo(() =>
      mergeStoredListSettingsOntoDefault(defaultListSettings, listSettingsFromStore),
    [defaultListSettings, listSettingsFromStore]);

  // GET FILTERS FROM NAVIGATION eg. ChildrenCountField, ReferenceField icon click
  const navigationPinFilters = location?.state?.list?.pinFilters;
  const navigationReferenceFilters = location?.state?.list?.referenceFilters;
  const navigationTagFilters = location?.state?.list?.columnFilters;

  const listOpenedAt = location?.state?.list?.openedAt || -1;

  return (
    <AccurityList
      key={listOpenedAt}
      listType={objectType}
      title={title}
      icon={icon}
      rows={rows}
      columns={columns}
      totalRows={totalRows}
      existingObjects={existingObjects}
      maximumObjects={maximumObjects}
      additionalMaximumObjects={additionalMaximumObjects}
      additionalExistingObjects={additionalExistingObjects}
      isLoading={isListLoading}
      allowImport={allowImport}
      allowExport={allowExport}
      rememberedListSettings={listSettings}
      globalPinFilters={globalPinFilters}
      setGlobalPinFilters={setGlobalPinFilters}
      navigationPinFilters={navigationPinFilters}
      navigationReferenceFieldFilters={navigationReferenceFilters}
      navigationColumnFilters={navigationTagFilters}
      hideAddButton={hideAddButton}
      hideObjectCounter={hideObjectCounter}
      handleRememberButton={saveListSettings}
      handleRestoreButton={restoreListSettings}
      handleExportButton={exportList}
      handleImportButton={importFile}
      handleRowSelectOverwrite={handleRowSelectOverwrite}
      doBackendListSearch={doListSearch}
      didInvalidateListSearch={didInvalidateListSearch}
      partialImportOnly={partialImportOnly}
      permissionsOverwrite={permissionsOverwrite}
      allowBulkOperations={allowBulkOperations}
    />
  );
};

export default AccurityListContainer;
