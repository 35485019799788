import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import {
  RELATED_BUSINESS_TERM_REFERENCED_BY_LABEL,
  RELATED_BUSINESS_TERM_REFERENCES_LABEL,
  RELATED_BUSINESS_TERM_RELATION_TYPES_LABEL,
  RELATED_VALUE_DESCRIPTIONS_LABEL,
  RELATED_VALUE_NAMES_LABEL,
  BusinessTermRelatedBusinessTermRelationTypeOptions
} from '../types/businessTermTypes';
import { Feature } from '../../../common/userSettings/features/features';
import { DATA_STRUCTURE_PLURAL_LABEL } from '../../dataStructure/types/dataStructureTypes';
import { DATA_FIELD_PLURAL_LABEL } from '../../dataField/types/dataFieldTypes';
import { ENTITY_PLURAL_LABEL } from '../../entity/types/entityTypes';
import { ATTRIBUTE_DEFINITION_PLURAL_LABEL } from '../../attributeDefinition/types/attributeDefinitionTypes';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { REQUIREMENT_PLURAL_LABEL } from '../../requirement/types/requirementTypes';
import { PROCESS_MAPPING_PLURAL_LABEL } from '../../process/mapping/types/processMappingTypes';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';
import { BUSINESS_RULE_PLURAL_LABEL } from '../../businessRule/types/businessRuleTypes';

export const useBusinessTermListColumns = () => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const businessTermListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    columnFactory.createTextColumn('synonym', 'Synonym', { hidden: true }),
    commonColumns.description(!isOracle),
    commonColumns.tags(navigationController),
    columnFactory.createEnumColumn('relatedBusinessTerms.relationType', RELATED_BUSINESS_TERM_RELATION_TYPES_LABEL, BusinessTermRelatedBusinessTermRelationTypeOptions, {
      hidden: true,
      sortable: false
    }),
    commonColumns.collection('relatedBusinessTerms.relatedBusinessTerm.name', RELATED_BUSINESS_TERM_REFERENCES_LABEL),
    commonColumns.collection('referencedBy.parent.name', RELATED_BUSINESS_TERM_REFERENCED_BY_LABEL),
    ...(hasFeature(Feature.BUSINESS_TERMS_RELATED_VALUES) ?
      [columnFactory.createCollectionColumn('relatedValues.name', RELATED_VALUE_NAMES_LABEL, { hidden: false })] : []),
    ...(hasFeature(Feature.BUSINESS_TERMS_RELATED_VALUES) ?
      [columnFactory.createCollectionColumn('relatedValues.description', RELATED_VALUE_DESCRIPTIONS_LABEL, { hidden: true })] : []),
    ...(hasFeature(Feature.REQUIREMENTS) ?
      [columnFactory.createCollectionColumn('requirements.name', REQUIREMENT_PLURAL_LABEL, { hidden: true })] : []),
    ...(hasFeature(Feature.BUSINESS_RULES) ?
      [columnFactory.createNumberColumn('childrenCounts.businessRulesCount', BUSINESS_RULE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.DATA_STRUCTURES) ?
      [columnFactory.createNumberColumn('childrenCounts.dataStructuresCount', DATA_STRUCTURE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.DATA_FIELDS) ?
      [columnFactory.createNumberColumn('childrenCounts.dataFieldsCount', DATA_FIELD_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.ENTITIES) ?
      [columnFactory.createNumberColumn('childrenCounts.entitiesCount', ENTITY_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.ATTRIBUTE_DEFINITIONS) ?
      [columnFactory.createNumberColumn('childrenCounts.attributeDefinitionsCount', ATTRIBUTE_DEFINITION_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.PROCESS_MAPPINGS_TARGET_BT) ?
      [columnFactory.createNumberColumn('childrenCounts.processMappingsCount', PROCESS_MAPPING_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ];

  return useValidColumns(businessTermListColumns, GlossaryCustomPropertyObjectType.BUSINESS_TERM);

};
