import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { Column, NumericFormat } from 'ts-components';
import {
  BASED_ON_DESCRIPTION_LABEL,
  BASED_ON_LABEL,
  CHILD_ENTITY_PLURAL_LABEL,
  ENTITY_DESCRIPTION,
  ENTITY_TYPE_LABEL,
  EntityTypeOptions,
  PARENT_ENTITY_LABEL
} from '../types/entityTypes';
import { ATTRIBUTE_PLURAL_LABEL, REFERENCE_ATTRIBUTE_PLURAL_LABEL } from '../../attribute/types/attributeTypes';
import { ATTRIBUTE_DEFINITION_PLURAL_LABEL } from '../../attributeDefinition/types/attributeDefinitionTypes';
import { COMPOSITE_TYPE_PLURAL_LABEL } from '../../compositeType/types/compositeTypeTypes';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { Feature } from '../../../common/userSettings/features/features';
import { BUSINESS_MODEL_MAPPING_PLURAL_LABEL } from '../../businessModelMapping/types/businessModelMappingTypes';
import { PROCESS_MAPPING_PLURAL_LABEL } from '../../process/mapping/types/processMappingTypes';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

export const useEntityListColumns = () => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const entityListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    columnFactory.createTextColumn('description.plainTextValue', ENTITY_DESCRIPTION, { sortable: !isOracle }),
    ...(hasFeature(Feature.BUSINESS_TERMS) ?
      [columnFactory.createTextColumn('basedOnBusinessTerm.name', BASED_ON_LABEL)] : []),
    ...(hasFeature(Feature.BUSINESS_TERMS) ?
      [columnFactory.createTextColumn('basedOnBusinessTerm.description.plainTextValue', BASED_ON_DESCRIPTION_LABEL, { hidden: true, sortable: !isOracle })] : []),
    commonColumns.tags(navigationController),
    columnFactory.createEnumColumn('entityType', ENTITY_TYPE_LABEL, EntityTypeOptions, { hidden: true }),
    columnFactory.createTextColumn('parent.name', PARENT_ENTITY_LABEL, { hidden: true }),
    ...(hasFeature(Feature.ENTITIES) ?
      [columnFactory.createNumberColumn('childrenCounts.entitiesCount', CHILD_ENTITY_PLURAL_LABEL, undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.ATTRIBUTES) ?
      [columnFactory.createNumberColumn('childrenCounts.attributesCount', ATTRIBUTE_PLURAL_LABEL, undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.ATTRIBUTES) ?
      [columnFactory.createNumberColumn('childrenCounts.referenceAttributeTargetEntitiesCount', REFERENCE_ATTRIBUTE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.ATTRIBUTE_DEFINITIONS) ?
      [columnFactory.createNumberColumn('childrenCounts.attributeDefinitionsCount', ATTRIBUTE_DEFINITION_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.COMPOSITE_TYPES) ?
      [columnFactory.createNumberColumn('childrenCounts.compositeTypesCount', COMPOSITE_TYPE_PLURAL_LABEL, { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.BUSINESS_MODEL_MAPPINGS) ?
      [columnFactory.createNumberColumn('childrenCounts.businessModelMappingsCount', BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
        { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.PROCESS_MAPPING_TARGET_ENTITIES) ?
      [columnFactory.createNumberColumn('childrenCounts.processMappingsCount', PROCESS_MAPPING_PLURAL_LABEL,
        { hidden: true }, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ];

  return useValidColumns(entityListColumns, GlossaryCustomPropertyObjectType.ENTITY);

};
