import { FieldInputProps, FormikProps } from 'formik';
import { BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_JOIN_DATA_FIELDS, BusinessModelMapping } from '../types/businessModelMappingTypes';
import { AccurityReference } from '../../../common/types/accurityTypes';
import { HasEntityAttributeDefinition } from '../../attribute/types/attributeTypes';

export const getBusinessModelMappingCountLabel = (collection: any[], label: string, pluralLabel: string) => {

  if (collection && collection.length) {
    const length = collection.length;

    if (length !== 1) {
      return length + ' ' + pluralLabel;
    } else {
      return length + ' ' + label;
    }
  } else {
    return '0 ' + pluralLabel;
  }
};

export const getBusinessModelMappingDataStructureDataFieldJoinCountLabel = (collection: any[], label: string, pluralLabel: string) => {

  if (collection && collection.length) {
    return '1 ' + label;
  } else {
    return '0 ' + pluralLabel;
  }
};

export const resetValuesOnBusinessModelMappingTypeChange = (formik: FormikProps<BusinessModelMapping>, isDataSetToBeCleared: boolean) => {
  formik.setValues({
    ...formik.values,
    baseDataSet: isDataSetToBeCleared ? undefined : formik.values.baseDataSet,
    baseEntity: undefined,
    baseAttributeDefinition: undefined,
    baseAttribute: undefined,
    baseDataStructure: undefined,
    baseDataField: undefined,
    targetDataSet: isDataSetToBeCleared ? undefined : formik.values.targetDataSet,
    targetDataStructureDataFields: [],
    businessModelMappingTargetEntityAttributeDefinitions: [],
    selectionItems: [],
    joinEntityAttributeDefinitions: [],
    joinDataStructureDataField: undefined
  });

  formik.setErrors({
    ...formik.errors,
    baseDataSet: isDataSetToBeCleared ? undefined : formik.errors.baseDataSet,
    baseEntity: undefined,
    baseAttributeDefinition: undefined,
    baseAttribute: undefined,
    baseDataStructure: undefined,
    baseDataField: undefined,
    targetDataSet: undefined,
    targetDataStructureDataFields: [],
    businessModelMappingTargetEntityAttributeDefinitions: [],
    selectionItems: [],
    joinEntityAttributeDefinitions: [],
    joinDataStructureDataField: undefined
  });
};

export const resetValuesOnBaseDataSetChange = (formik: FormikProps<BusinessModelMapping>, isDataSetToBeCleared: boolean) => {
  formik.setValues({
    ...formik.values,
    baseDataSet: isDataSetToBeCleared ? undefined : formik.values.baseDataSet,
    baseEntity: undefined,
    baseAttributeDefinition: undefined,
    baseAttribute: undefined,
    baseDataStructure: undefined,
    baseDataField: undefined,
  });

  formik.setErrors({
    ...formik.errors,
    baseDataSet: isDataSetToBeCleared ? undefined : formik.errors.baseDataSet,
    baseEntity: undefined,
    baseAttributeDefinition: undefined,
    baseAttribute: undefined,
    baseDataStructure: undefined,
    baseDataField: undefined,
  });
};

export const resetValuesOnTargetDataSetChange = (formik: FormikProps<BusinessModelMapping>, isDataSetToBeCleared: boolean) => {
  formik.setValues({
    ...formik.values,
    targetDataSet: isDataSetToBeCleared ? undefined : formik.values.targetDataSet,
    targetDataStructureDataFields: [],
    businessModelMappingTargetEntityAttributeDefinitions: [],
    selectionItems: [],
    joinEntityAttributeDefinitions: [],
    joinDataStructureDataField: undefined
  });

  formik.setErrors({
    ...formik.errors,
    targetDataSet: undefined,
    targetDataStructureDataFields: [],
    businessModelMappingTargetEntityAttributeDefinitions: [],
    selectionItems: [],
    joinEntityAttributeDefinitions: [],
    joinDataStructureDataField: undefined
  });
};

export const clearDataFieldJoinsOnDataStructureRemove = (formik: FormikProps<BusinessModelMapping>,
                                                         fieldName: string,
                                                         dataStructure?: AccurityReference | null,
                                                         oppositeDataStructureId?: string) => {
  if (dataStructure) {
    formik.setFieldValue(fieldName, dataStructure);
  } else {
    formik.setFieldValue(fieldName, null);
  }

  if (!dataStructure || !oppositeDataStructureId) {
    formik.setFieldValue(BUSINESS_MODEL_MAPPING_TARGET_JOIN_DATA_STRUCTURE_DATA_FIELD_JOIN_DATA_FIELDS, []);
  }
};

export const assembleAttributeNames = (field: FieldInputProps<HasEntityAttributeDefinition[]>) => {
  const attributeNames = field.value.filter(value => value?.entity?.name !== undefined && value?.attributeDefinition?.name !== undefined)
    .map(row => row?.entity?.name + '.' + row?.attributeDefinition?.name);

  let attributes = [];

  for (let i = 0; i < attributeNames.length; i++) {
    attributes[i] = { name: attributeNames[i] }
  }

  return attributes;
};
