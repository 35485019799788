import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { useValueTypeListColumns } from './valueTypeListColumns';
import { VALUE_TYPE_ICON, VALUE_TYPE_PLURAL_LABEL, VALUE_TYPE_TYPE } from '../types/valueTypeTypes';

const ValueTypeList = () => {
  const valueTypeListSettings = getDefaultListSettings(VALUE_TYPE_TYPE, useValueTypeListColumns());
  const valueTypeListColumns = useValueTypeListColumns();

  return (
    <AccurityListContainer
      objectType={VALUE_TYPE_TYPE}
      title={VALUE_TYPE_PLURAL_LABEL}
      icon={VALUE_TYPE_ICON}
      columns={valueTypeListColumns}
      defaultListSettings={valueTypeListSettings}
      allowBulkOperations={true}
    />
  );
};

export default ValueTypeList;
