import React from 'react';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, IT_IS_CURRENTLY_LABEL } from '../../../../common/detail/types/types';
import AccurityDetailContainer from '../../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../../common/detail/components/DefaultDetailBottomBar';
import ConfirmRemovalDetail from '../../../../common/detail/commonFields/ConfirmRemovalDetail';
import { FormikProps } from 'formik';
import FeatureChecker from '../../../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../../../common/userSettings/features/features';
import NameFieldWithFormik from '../../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../../common/detail/formik/DescriptionFieldWithFormik';
import HistoryGroupWithFormik from '../../../../common/detail/formik/HistoryGroupWithFormik';
import { VersionBrowserButton } from '../../../../common/versionBrowserField/components/VersionBrowserButton';
import StatusFieldWithFormik from '../../../../common/detail/formik/StatusFieldWithFormik';
import TagsFieldWithFormik from '../../../../common/detail/formik/TagsFieldWithFormik';
import { GlossaryCustomPropertyObjectType } from '../../../customProperties/types';
import CustomPropertyFieldsContainer from '../../../../common/customProperties/CustomPropertyFieldsContainer';
import { PROCESS_STEP_ICON, PROCESS_STEP_TYPE, ProcessStep } from '../types/processStepTypes';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { PROCESS_ICON, PROCESS_LABEL, PROCESS_TYPE } from '../../types/processTypes';
import ChildrenCountFieldWithFormik from '../../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { PROCESS_MAPPING_ICON, PROCESS_MAPPING_LABEL, PROCESS_MAPPING_PLURAL_LABEL, PROCESS_MAPPING_TYPE } from '../../mapping/types/processMappingTypes';
import { ChildrenCountWording } from '../../../../common/childrenCountField/types';
import { DetailHeader, StaticField } from 'ts-components';
import GenerateProcessDiagramButton from '../../../processDiagram/detail/GenerateProcessDiagramButton';
import { ProcessDiagramBasedOnType } from '../../../processDiagram/types/processDiagramTypes';
import DetailTopBar from '../../../../common/detail/components/DetailTopBar';

interface ProcessStepDetailProps {
  id?: string,
}

const ProcessStepDetail = ({ id }: ProcessStepDetailProps) => {

  const generateProcessDiagramButton =
    <GenerateProcessDiagramButton
      key="processDiagramButton"
      basedOnType={ProcessDiagramBasedOnType.PROCESS_STEPS}
      objectType={PROCESS_STEP_TYPE}
      id={id}
    />;

  return (
    <AccurityDetailContainer<ProcessStep>
      objectType={PROCESS_STEP_TYPE}
      id={id}>
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik)}
              <DefaultDetailBottomBar
                objectType={PROCESS_STEP_TYPE}
                detailBag={detailBag}
                customMenuItems={generateProcessDiagramButton}
              />
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<ProcessStep>, detailBag: DetailBag<ProcessStep>) => (
  <ConfirmRemovalDetail
    iconName={PROCESS_STEP_ICON}
    detailBag={detailBag}
  >
    <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
      <StaticField
        name={'removalDescription'}
        value={IT_IS_CURRENTLY_LABEL}
        inverted={true}
      />
      <ChildrenCountFieldWithFormik
        singularLabel={PROCESS_MAPPING_LABEL}
        pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
        value={formik.values.childrenCounts.processMappingsCount}
        iconName={PROCESS_MAPPING_ICON}
        pinIconName={PROCESS_STEP_ICON}
        childFieldType={PROCESS_MAPPING_TYPE}
        childrenCountWording={ChildrenCountWording.REFERENCED_BY}
        inverted={true}
      />
    </FeatureChecker>
  </ConfirmRemovalDetail>
);

const getDetailFields = (formik: FormikProps<ProcessStep>) => {
  return (
    <FeatureChecker featureId={Feature.PROCESS_STEPS}>
      <DetailHeader iconName={PROCESS_STEP_ICON}>
        <NameFieldWithFormik label={'Process Step Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={PROCESS_STEP_TYPE}/>
      <ReferenceFieldWithFormik
        name={PROCESS_TYPE}
        label={PROCESS_LABEL}
        icon={PROCESS_ICON}
        objectType={PROCESS_TYPE}
        readOnly={formik.status.isUpdateDetail}
      />
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.PROCESS_STEP}/>
      <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
        <ChildrenCountFieldWithFormik
          singularLabel={PROCESS_MAPPING_LABEL}
          pluralLabel={PROCESS_MAPPING_PLURAL_LABEL}
          value={formik.values.childrenCounts.processMappingsCount}
          iconName={PROCESS_MAPPING_ICON}
          pinIconName={PROCESS_STEP_ICON}
          childFieldType={PROCESS_MAPPING_TYPE}
          childrenCountWording={ChildrenCountWording.BASE_FOR}
        />
      </FeatureChecker>
    </FeatureChecker>
  );
};

export default ProcessStepDetail;
