import {
  AccurityFilterType,
  Align,
  BooleanColumn,
  columnTypes,
  DateColumn,
  DownloadColumn,
  EnumColumn,
  IconColumn,
  NumberColumn,
  NumericFormat,
  PercentageColumn,
  TagColumn,
  TextColumn,
  TimeColumn
} from 'ts-components';
import { COLUMN_DEFAULT_WIDTH_POINTS, Tag } from 'ts-components';
import { EmailNotificationActionType } from '../../types/accurityTypes';
import { requestTagFieldSearch } from '../../tagField/rest';
import { useAccurityNavigation } from '../../navigation/hooks';
import Analytics from '../../analytics';

const commonColumnProps = {
  hidden: false,
  width: COLUMN_DEFAULT_WIDTH_POINTS,
  index: 0,
  filterable: true,
};

export const columnFactory = {
  createTextColumn: (name: string, label: string, overwrites?: Partial<TextColumn>): TextColumn => ({
    ...commonColumnProps,
    ...overwrites,
    name,
    label,
    type: columnTypes.TEXT_TYPE,
  }),

  createEnumColumn: (name: string, label: string, options: any[], overwrites?: Partial<EnumColumn>): EnumColumn => ({
    ...commonColumnProps,
    align: Align.LEFT,
    ...overwrites,
    options,
    name,
    label,
    type: columnTypes.ENUM_TYPE,
  }),

  createBooleanColumn: (name: string, label: string, overwrites?: Partial<BooleanColumn>): BooleanColumn => ({
    ...commonColumnProps,
    align: Align.CENTER,
    ...overwrites,
    name,
    label,
    type: columnTypes.BOOL_TYPE,
  }),

  createNumberColumn: (name: string, label: string, overwrites?: Partial<NumberColumn>, numericFormat?: NumericFormat): NumberColumn => ({
    ...commonColumnProps,
    align: Align.RIGHT,
    ...overwrites,
    name,
    label,
    type: columnTypes.NUMBER_TYPE,
    numericFormat: numericFormat,
  }),

  createDateColumn: (name: string, label: string, overwrites?: Partial<DateColumn>): DateColumn => ({
    ...commonColumnProps,
    align: Align.RIGHT,
    ...overwrites,
    name,
    label,
    type: columnTypes.DATE_TYPE,
  }),

  createPercentageColumn: (name: string, label: string, overwrites?: Partial<PercentageColumn>): PercentageColumn => ({
    ...commonColumnProps,
    align: Align.RIGHT,
    ...overwrites,
    name,
    label,
    type: columnTypes.PERCENTAGE_TYPE,
  }),

  createTimeColumn: (name: string, label: string, overwrites?: Partial<TimeColumn>): TimeColumn => ({
    ...commonColumnProps,
    align: Align.RIGHT,
    ...overwrites,
    name,
    label,
    type: columnTypes.TIME_TYPE,
  }),

  createDownloadColumn: (name: string, label: string, overwrites?: Partial<DownloadColumn>): DownloadColumn => ({
    ...commonColumnProps,
    ...overwrites,
    name,
    label,
    type: columnTypes.DOWNLOAD_LINK_TYPE,
  }),

  createIconColumn: (name: string, label: string, overwrites?: Partial<IconColumn>): IconColumn => ({
    ...commonColumnProps,
    ...overwrites,
    name,
    label,
    type: columnTypes.ICON_TYPE,
  }),

  createTagsColumn: (
    name: string,
    label: string,
    searchProperty: string,
    navigation: ReturnType<typeof useAccurityNavigation>,
    overwrites?: Partial<TagColumn>
  ): TagColumn => ({
    ...commonColumnProps,
    ...overwrites,
    name,
    label,
    searchProperty,
    filterValueProperty: 'id',
    doSearch: search => requestTagFieldSearch(search).then(response => response.rows),
    onTagClick: (tag: Tag) => {
      navigation.openListForTag(tag.name);
      Analytics.event(`List: ${navigation.getCurrentNavigation().listType}`, 'Tag click', tag.name);
    },
    type: columnTypes.TAG_TYPE,
    sortable: false
  }),

  createHyperlinkColumn: (name: string, label: string, overwrites?: Partial<TextColumn>): TextColumn => ({ // TODO add HyperlinkColumn type in ts-components
    ...commonColumnProps,
    ...overwrites,
    name,
    label,
    type: columnTypes.HYPERLINK_TYPE,
  }),

  createEmailNotificationColumn: (notificationType: EmailNotificationActionType, label: string): BooleanColumn => ({
    ...commonColumnProps,
    align: Align.CENTER,
    name: `activeNotifications.${notificationType}`,
    label,
    hidden: true,
    filterType: AccurityFilterType.ACTIVE_NOTIFICATION,
    sortable: false,
    type: columnTypes.BOOL_TYPE
  }),

  createCollectionColumn: (name: string, label: string, overwrites?: Partial<TextColumn>): TextColumn =>
    columnFactory.createTextColumn(name, label, { ...overwrites, sortable: false }),
};

export const commonColumns = {
  id: () => columnFactory.createNumberColumn('id', 'ID', { hidden: true, align: Align.LEFT, hideComparisonSelector: true }, NumericFormat.POSITIVE_NUMERIC),
  name: () => columnFactory.createTextColumn('name', 'Name'),
  tags: (navigation: ReturnType<typeof useAccurityNavigation>, overwrites?: Partial<TagColumn>) =>
    columnFactory.createTagsColumn('tags', 'Tags', 'tags.id', navigation, overwrites),
  description: (isSortable: boolean) => columnFactory.createTextColumn('description.plainTextValue', 'Description', { sortable: isSortable }),
  createdTime: () => columnFactory.createDateColumn('createdDate', 'Created', { hidden: true }),
  createdBy: () => columnFactory.createTextColumn('createdBy.name', 'Created by', { hidden: true }),
  lastChangedTime: () => columnFactory.createDateColumn('changedDate', 'Last Changed', { hidden: true }),
  lastChangedBy: () => columnFactory.createTextColumn('changedBy.name', 'Changed by', { hidden: true }),
  status: () => columnFactory.createTextColumn('status.name', 'Status', { hidden: true }),
  collection: (name: string, label: string) => columnFactory.createTextColumn(name, label, { sortable: false }),
  commentsNotification: () => columnFactory.createEmailNotificationColumn(EmailNotificationActionType.COMMENT_UPDATE, 'Notifications - Comments'),
  objectUpdatesNotification: () => columnFactory.createEmailNotificationColumn(EmailNotificationActionType.OBJECT_UPDATE, 'Notifications - Updates'),
};
