import React from 'react';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import { STATUS_ICON, STATUS_PLURAL_LABEL, STATUS_TYPE } from '../types/statusTypes';
import { useStatusListColumns } from './statusListColumns';

const StatusList = () => {
  const statusListColumns = useStatusListColumns();
  const defaultListSettings = getDefaultListSettings(STATUS_TYPE, useStatusListColumns());

  return (
    <AccurityListContainer
      objectType={STATUS_TYPE}
      title={STATUS_PLURAL_LABEL}
      icon={STATUS_ICON}
      columns={statusListColumns}
      defaultListSettings={defaultListSettings}
      partialImportOnly={true}
      allowBulkOperations={true}
    />
  );
};
export default StatusList;
