import { Align, Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../../common/list/commonColumns/columnDefinitions';
import AccurityListContainer from '../../../../common/list/redux/AccurityListContainer';
import React from 'react';
import { getDefaultListSettings, useValidColumns } from '../../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../../customProperties/types';
import { useAccurityNavigation } from '../../../../common/navigation/hooks';
import { PROCESS_LABEL } from '../../types/processTypes';
import {
  PROCESS_MAPPING_ICON,
  PROCESS_MAPPING_PLURAL_LABEL,
  PROCESS_MAPPING_PROCESS_MAPPING_BASED_ON_TYPE_LABEL,
  PROCESS_MAPPING_PROCESS_MAPPING_TYPE_LABEL,
  PROCESS_MAPPING_TYPE,
  ProcessMappingBasedOnTypeOptions,
  ProcessMappingTypeOptions
} from '../types/processMappingTypes';
import { PROCESS_STEP_LABEL } from '../../step/types/processStepTypes';
import { DATA_ASSET_LABEL } from '../../../dataAsset/types/dataAssetTypes';
import { ENTITY_PLURAL_LABEL } from '../../../entity/types/entityTypes';
import { ATTRIBUTE_PLURAL_LABEL } from '../../../attribute/types/attributeTypes';
import { AccuritySortType } from '../../../../common/types/accurityTypes';
import { Feature } from '../../../../common/userSettings/features/features';
import { useHasFeature } from '../../../../common/userSettings/hooks/features';
import { BUSINESS_TERM_PLURAL_LABEL } from '../../../businessTerm/types/businessTermTypes';
import { useIsOracleDb } from '../../../../common/appSettings/hooks/environmentInfoHooks';

const ProcessMappingList = () => {
  const hasFeature = useHasFeature();
  const navigationController = useAccurityNavigation();
  const isOracle = useIsOracleDb();

  const processListColumns: Column[] = [
    columnFactory.createNumberColumn('id', 'ID', {
      align: Align.LEFT,
      hideComparisonSelector: true
    }, NumericFormat.POSITIVE_NUMERIC),
    columnFactory.createTextColumn('description.plainTextValue', 'Description', { hidden: true, sortable: !isOracle }),
    commonColumns.tags(navigationController),
    columnFactory.createTextColumn('process.name', PROCESS_LABEL),
    columnFactory.createTextColumn('processStep.name', PROCESS_STEP_LABEL),
    columnFactory.createTextColumn('dataAsset.name', DATA_ASSET_LABEL, { hidden: true }),
    columnFactory.createEnumColumn('processMappingType', PROCESS_MAPPING_PROCESS_MAPPING_TYPE_LABEL, ProcessMappingTypeOptions, { hidden: true }),
    columnFactory.createEnumColumn('processMappingBasedOnType', PROCESS_MAPPING_PROCESS_MAPPING_BASED_ON_TYPE_LABEL, ProcessMappingBasedOnTypeOptions, { hidden: true }),
    ...(hasFeature(Feature.PROCESS_MAPPINGS_TARGET_BT) ?
      [columnFactory.createTextColumn('targetBusinessTerms.name', BUSINESS_TERM_PLURAL_LABEL, { sortable: false })] : []),
    ...(hasFeature(Feature.PROCESS_MAPPING_TARGET_ENTITIES) ?
      [columnFactory.createTextColumn('processMappingTargetEntities.entity.name', ENTITY_PLURAL_LABEL, { sortable: false })] : []),
    ...(hasFeature(Feature.PROCESS_MAPPING_TARGET_ATTRIBUTES) ?
      [columnFactory.createTextColumn('processMappingTargetAttributes.attribute.name', ATTRIBUTE_PLURAL_LABEL, { sortable: false })] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ];

  const processMappingColumns = useValidColumns(processListColumns, GlossaryCustomPropertyObjectType.PROCESS_MAPPING);
  const processMappingListSettings = getDefaultListSettings(PROCESS_MAPPING_TYPE, processMappingColumns, {
    property: 'id',
    type: AccuritySortType.ASCENDING
  });

  return (
    <AccurityListContainer
      objectType={PROCESS_MAPPING_TYPE}
      title={PROCESS_MAPPING_PLURAL_LABEL}
      icon={PROCESS_MAPPING_ICON}
      columns={processMappingColumns}
      defaultListSettings={processMappingListSettings}
      allowBulkOperations={true}
    />
  );
};

export default ProcessMappingList;
