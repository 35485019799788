import React, { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';

import { CollectionSuggestionRow, ObjectSuggestionType } from 'ts-components';
import {
  BUSINESS_TERM_LABEL,
  BusinessTerm,
  DataCatalogRelatedBusinessTermRelationType,
  DataCatalogRelatedBusinessTermRelationTypeOptions
} from '../../../businessGlossary/businessTerm/types/businessTermTypes';
import { useHasFeature } from '../../userSettings/hooks/features';
import { Feature } from '../../userSettings/features/features';
import RelatedBusinessTermReferencesFieldWithFormik from './RelatedBusinessTermReferencesFieldWithFormik';
import { DataStructure } from '../../../businessGlossary/dataStructure/types/dataStructureTypes';
import { DataField } from '../../../businessGlossary/dataField/types/dataFieldTypes';

const RELATED_BUSINESS_TERMS_FIELD_NAME = 'relatedBusinessTerms';

export type SuggestionFetchFn = (
  objectName: string,
  excludedSuggestionsNames?: string[],
  maxResults?: number
) => Promise<ObjectSuggestionType[]>;

interface RelatedBusinessTermsWithSuggestionsProps {
  objectName: string;
  fetchFn: SuggestionFetchFn;
  hideSuggestions?: boolean;
}

const RelatedBusinessTermsWithSuggestions = ({
                                               objectName,
                                               fetchFn,
                                               hideSuggestions
                                             }: RelatedBusinessTermsWithSuggestionsProps) => {

  const [suggestionsData, setSuggestionsData] = useState<ObjectSuggestionType[]>([]);
  const formikContext = useFormikContext<BusinessTerm | DataField | DataStructure>();
  const isSuggestionsActive = useHasFeature()(Feature.OBJECT_SUGGESTION) && formikContext.status.permissions.hasUpdatePermission && !hideSuggestions;
  // filter undefined related BT out. Undefined occurs in relatedBusinessTerms right after user clicks on + button in collection
  const relatedBusinessTerms = formikContext.values.relatedBusinessTerms
    ?.map(relatedBusinessTerms => relatedBusinessTerms?.relatedBusinessTerm)
    .filter(businessTerm => !!businessTerm) || [];

  const findSuggestions = useCallback(() => {
    // exclude existing BTs from search
    const namesToExclude = [...relatedBusinessTerms, ...suggestionsData]
      .map(q => q.name);

    fetchFn(objectName, namesToExclude)
      .then(data => setSuggestionsData(q => [...q, ...data]));
  }, [objectName, relatedBusinessTerms, suggestionsData, fetchFn]);

  const declineSuggestedObject = (suggestion: ObjectSuggestionType) => {
    setSuggestionsData(q => q.filter(w => w !== suggestion));
  };

  const addSuggestedObject = (suggestion: ObjectSuggestionType) => {
    setSuggestionsData(q => q.filter(w => w !== suggestion));
    const newValues = [...formikContext.values.relatedBusinessTerms || [],
      {
        relationType: DataCatalogRelatedBusinessTermRelationType.RELATED_TO,
        relatedBusinessTerm: suggestion
      }];
    formikContext.setFieldValue(RELATED_BUSINESS_TERMS_FIELD_NAME, newValues)
  };

  const suggestions = suggestionsData
    .sort((a, b) => b.relevance - a.relevance)
    .filter(suggestion => !relatedBusinessTerms.find(q => q.name === suggestion.name))
    .map(suggestion => (
      <CollectionSuggestionRow
        onAccept={addSuggestedObject}
        onDecline={declineSuggestedObject}
        item={suggestion}
        label={BUSINESS_TERM_LABEL}
        key={suggestion.name}
      />
    ));

  return (
    <RelatedBusinessTermReferencesFieldWithFormik
      enableSuggestions={isSuggestionsActive}
      findSuggestions={findSuggestions}
      suggestions={suggestions}
      relationTypeOptions={DataCatalogRelatedBusinessTermRelationTypeOptions}
      relationTypeDefaultValue={DataCatalogRelatedBusinessTermRelationType.RELATED_TO}
    />
  )
};

export default RelatedBusinessTermsWithSuggestions;
