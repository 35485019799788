import React from 'react';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../../common/detail/types/types';
import AccurityDetailContainer from '../../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../../common/detail/components/DefaultDetailBottomBar';
import ConfirmRemovalDetail from '../../../../common/detail/commonFields/ConfirmRemovalDetail';
import { FormikProps } from 'formik';
import FeatureChecker from '../../../../common/userSettings/components/FeatureChecker';
import { Feature } from '../../../../common/userSettings/features/features';
import NameFieldWithFormik from '../../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../../common/detail/formik/DescriptionFieldWithFormik';
import HistoryGroupWithFormik from '../../../../common/detail/formik/HistoryGroupWithFormik';
import { VersionBrowserButton } from '../../../../common/versionBrowserField/components/VersionBrowserButton';
import StatusFieldWithFormik from '../../../../common/detail/formik/StatusFieldWithFormik';
import TagsFieldWithFormik from '../../../../common/detail/formik/TagsFieldWithFormik';
import { GlossaryCustomPropertyObjectType } from '../../../customProperties/types';
import CustomPropertyFieldsContainer from '../../../../common/customProperties/CustomPropertyFieldsContainer';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import { PROCESS_FIELD, PROCESS_ICON, PROCESS_LABEL, PROCESS_TYPE } from '../../types/processTypes';
import {
  PROCESS_MAPPING_BASE_FIELD,
  PROCESS_MAPPING_BASE_LABEL,
  PROCESS_MAPPING_ICON,
  PROCESS_MAPPING_PROCESS_MAPPING_BASED_ON_TYPE_FIELD,
  PROCESS_MAPPING_PROCESS_MAPPING_BASED_ON_TYPE_LABEL,
  PROCESS_MAPPING_PROCESS_MAPPING_TYPE_FIELD,
  PROCESS_MAPPING_PROCESS_MAPPING_TYPE_LABEL,
  PROCESS_MAPPING_TARGET_ATTRIBUTES_FIELD,
  PROCESS_MAPPING_TARGET_ATTRIBUTES_LABEL,
  PROCESS_MAPPING_TARGET_ATTRIBUTES_PLURAL_LABEL,
  PROCESS_MAPPING_TARGET_ENTITIES_FIELD,
  PROCESS_MAPPING_TARGET_FIELD,
  PROCESS_MAPPING_TARGET_LABEL,
  PROCESS_MAPPING_TYPE,
  ProcessMapping,
  ProcessMappingBasedOnType,
  ProcessMappingBasedOnTypeOptions,
  ProcessMappingTypeOptions
} from '../types/processMappingTypes';
import EnumerationFieldWithFormik from '../../../../common/detail/formik/EnumerationFieldWithFormik';
import { getProcessMappingCountLabel, resetValuesOnTypeChange } from './processMappingDetailUtils';
import { CollapsingGroup, DetailHeader } from 'ts-components';
import { PROCESS_STEP_FIELD, PROCESS_STEP_ICON, PROCESS_STEP_LABEL, PROCESS_STEP_TYPE, ProcessStepReference } from '../../step/types/processStepTypes';
import { DATA_ASSET_ICON, DATA_ASSET_LABEL, DATA_ASSET_TYPE } from '../../../dataAsset/types/dataAssetTypes';
import CollectionFieldWithFormik from '../../../../common/detail/formik/CollectionFieldWithFormik';
import { ENTITY_ICON, ENTITY_LABEL, ENTITY_TYPE } from '../../../entity/types/entityTypes';
import ProcessMappingTargetAttributeCollection from './ProcessMappingTargetAttributeCollection';
import { createReferenceFieldFilter, createUniqueWithinCollectionFilters } from '../../../../common/referenceField/utils/filters';
import TargetBusinessTermsFieldWithFormik from '../../../../common/detail/formik/TargetBusinessTermsFieldWithFormik';
import {
  BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_LABEL,
  BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_PLURAL_LABEL
} from '../../../businessModelMapping/types/businessModelMappingTypes';
import { defaultTestDraggedItem, defaultTestDroppedItem } from '../../../../common/referenceField/utils/dragAndDrop';
import { ATTRIBUTE_ICON } from '../../../attribute/types/attributeTypes';
import DetailTopBar from '../../../../common/detail/components/DetailTopBar';

interface ProcessMappingDetailProps {
  id?: string,
}

const ProcessMappingDetail = ({ id }: ProcessMappingDetailProps) => {
  return (
    <AccurityDetailContainer<ProcessMapping>
      objectType={PROCESS_MAPPING_TYPE}
      id={id}>
      {(formik, detailBag) => {
        const processMappingDetailBag: DetailBag<ProcessMapping> = {
          ...detailBag,
          copyAction: () => detailBag.copyAction({ name: undefined })
        };

        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, processMappingDetailBag);
        } else {
          return (
            <>
              {getDetailFields(formik)}
              <DefaultDetailBottomBar objectType={PROCESS_MAPPING_TYPE} detailBag={processMappingDetailBag}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );
};

const getConfirmRemovalDetail = (formik: FormikProps<ProcessMapping>, detailBag: DetailBag<ProcessMapping>) => (
  <ConfirmRemovalDetail
    iconName={PROCESS_MAPPING_ICON}
    detailBag={detailBag}
  >
  </ConfirmRemovalDetail>
);

const getDetailFields = (formik: FormikProps<ProcessMapping>) => {
  const entities = formik.values.processMappingTargetEntities.map(row => row?.entity);
  const entitiesFilter = [
    ...createUniqueWithinCollectionFilters('name', Array.from(entities)),
  ];

  return (
    <FeatureChecker featureId={Feature.PROCESS_MAPPINGS}>
      <DetailHeader iconName={PROCESS_MAPPING_ICON}>
        <NameFieldWithFormik
          label={'Process Mapping ID'}
          readOnly={true}
        />
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={PROCESS_MAPPING_TYPE}/>
      <EnumerationFieldWithFormik
        name={PROCESS_MAPPING_PROCESS_MAPPING_TYPE_FIELD}
        label={PROCESS_MAPPING_PROCESS_MAPPING_TYPE_LABEL}
        options={ProcessMappingTypeOptions}
      />
      <EnumerationFieldWithFormik
        name={PROCESS_MAPPING_PROCESS_MAPPING_BASED_ON_TYPE_FIELD}
        label={PROCESS_MAPPING_PROCESS_MAPPING_BASED_ON_TYPE_LABEL}
        options={ProcessMappingBasedOnTypeOptions}
        readOnly={formik.status.isUpdateDetail}
        onChange={() => {
          resetValuesOnTypeChange(formik);
        }}
      />
      {formik.values.processMappingBasedOnType &&
      <CollapsingGroup
        name={PROCESS_MAPPING_BASE_FIELD}
        title={PROCESS_MAPPING_BASE_LABEL}
        icon={PROCESS_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        <ReferenceFieldWithFormik
          name={PROCESS_TYPE}
          label={PROCESS_LABEL}
          icon={PROCESS_ICON}
          objectType={PROCESS_TYPE}
          setValue={(process) => {
            formik.setFieldValue(PROCESS_FIELD, process);
            formik.setFieldValue(PROCESS_STEP_FIELD, null);
          }}
        />
        {formik.values.processMappingBasedOnType === ProcessMappingBasedOnType.PROCESS_STEP &&
        <ReferenceFieldWithFormik<ProcessStepReference>
          name={PROCESS_STEP_FIELD}
          label={PROCESS_STEP_LABEL}
          icon={PROCESS_STEP_ICON}
          objectType={PROCESS_STEP_TYPE}
          additionalFilters={[createReferenceFieldFilter('process.id', formik.values.process?.id)]}
          setValue={(processStep) => {
            formik.setFieldValue(PROCESS_STEP_FIELD, processStep);
            if (!formik.values.process) {
              formik.setFieldValue(PROCESS_FIELD, processStep?.process)
            }
          }}
        />}
      </CollapsingGroup>}
      {formik.values.processMappingBasedOnType &&
      <CollapsingGroup
        name={PROCESS_MAPPING_TARGET_FIELD}
        title={PROCESS_MAPPING_TARGET_LABEL}
        icon={PROCESS_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        <FeatureChecker featureId={Feature.PROCESS_MAPPINGS_TARGET_BT}>
          {formik.values.processMappingBasedOnType &&
          <TargetBusinessTermsFieldWithFormik/>
          }
        </FeatureChecker>
        <FeatureChecker featureId={Feature.PROCESS_MAPPING_TARGET_ENTITIES}>
          {formik.values.processMappingBasedOnType &&
          <CollectionFieldWithFormik
            name={PROCESS_MAPPING_TARGET_ENTITIES_FIELD}
            title={getProcessMappingCountLabel(formik.values.processMappingTargetEntities,
              BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_LABEL,
              BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_PLURAL_LABEL)}
            icon={ENTITY_ICON}
            testDraggedItem={defaultTestDraggedItem(ENTITY_TYPE)}
            testDroppedItem={defaultTestDroppedItem({ objectType: ENTITY_TYPE, additionalFilters: entitiesFilter })}
            onValidDrop={(addRow, rowItem) => addRow({ entity: rowItem })}
            rowValidation={(value) => value && value.entity && value.entity.id}
            renderRow={(elementName) =>
              <ReferenceFieldWithFormik
                name={elementName + '.entity'}
                label={ENTITY_LABEL}
                icon={ENTITY_ICON}
                objectType={ENTITY_TYPE}
                additionalFilters={entitiesFilter}
                hideRemoveButton={true}
                coloringOverwrite={elementName}
              />
            }
          />
          }
        </FeatureChecker>
        <FeatureChecker featureId={Feature.PROCESS_MAPPING_TARGET_ATTRIBUTES}>
          {formik.values.processMappingBasedOnType &&
          <CollectionFieldWithFormik
            name={PROCESS_MAPPING_TARGET_ATTRIBUTES_FIELD}
            title={getProcessMappingCountLabel(formik.values.processMappingTargetAttributes,
              PROCESS_MAPPING_TARGET_ATTRIBUTES_LABEL,
              PROCESS_MAPPING_TARGET_ATTRIBUTES_PLURAL_LABEL)}
            icon={ATTRIBUTE_ICON}
            rowValidation={(value) => value && value.entity && value.entity.id && value.attributeDefinition && value.attributeDefinition.id}
            renderRow={(elementName, index) =>
              <ProcessMappingTargetAttributeCollection
                elementName={elementName}
                index={index}
              />
            }
          />
          }
        </FeatureChecker>
      </CollapsingGroup>}
      <ReferenceFieldWithFormik
        name={'dataAsset'}
        label={DATA_ASSET_LABEL}
        icon={DATA_ASSET_ICON}
        objectType={DATA_ASSET_TYPE}
      />
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.PROCESS_MAPPING}/>
    </FeatureChecker>
  );
};

export default ProcessMappingDetail;
