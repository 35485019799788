import React from 'react';
import { ListBulkOperations } from 'ts-components';
import { BulkOperationsManager } from '../hooks/bulkOperationsHook';
import { CrudPermissions } from '../../userSettings/types/types';
import { getRemovalDialogMessage } from '../messages';
import { TagsEditDialogContent } from './TagsEditDialogContent';

interface BulkOperationsBarProps {
  bulkOperationsManager: BulkOperationsManager;
  permissions: CrudPermissions;
  listType: string;
}

const BulkOperationsBar = ({
                                      bulkOperationsManager,
                                      permissions,
                                      listType
                                    }: BulkOperationsBarProps) => {

  if (!bulkOperationsManager.isBulkActionsVisible) {
    return null;
  }

  return (
    <ListBulkOperations
      isNotificationsActive={bulkOperationsManager.isNotificationsActive}
      inProgress={bulkOperationsManager.isInProgress}
      editTagsDialogContent={<TagsEditDialogContent bulkOperationsManager={bulkOperationsManager}/>}
      removalDialogContent={getRemovalDialogMessage(listType, bulkOperationsManager.selectedRowsIds.length)}
      onRemove={bulkOperationsManager.removeItems}
      onNotificationChange={bulkOperationsManager.toggleNotification}
      onTagsUpdate={bulkOperationsManager.saveTags}
      canRemove={permissions.hasDeletePermission}
      canUpdateTags={permissions.hasUpdatePermission && bulkOperationsManager.objectHasTags}
    />
  );
};

export default BulkOperationsBar;

