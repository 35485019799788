import React from 'react';
import { ENTITY_ICON, ENTITY_LABEL, ENTITY_TYPE } from '../../../entity/types/entityTypes';
import ReferenceFieldWithFormik from '../../../../common/detail/formik/ReferenceFieldWithFormik';
import CollectionFieldWithFormik from '../../../../common/detail/formik/CollectionFieldWithFormik';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_TYPE, DataStructureReference } from '../../../dataStructure/types/dataStructureTypes';
import {
  BUSINESS_MODEL_MAPPING_BASE_DATA_SET_FIELD,
  BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_FIELD,
  BUSINESS_MODEL_MAPPING_BASE_LABEL,
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_JOIN_ATTRIBUTE_ENTITY_FIELD,
  BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_LABEL,
  BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_ENTITY_ATTRIBUTE_DEFINITIONS_FIELD_NAME,
  BUSINESS_MODEL_MAPPING_TARGET_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_ICON,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_JOIN_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ICON,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_LABEL,
  BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL,
  BusinessModelMapping,
  BusinessModelMappingTargetEntityAttributeDefinition
} from '../../types/businessModelMappingTypes';
import { AccurityFilter } from '../../../../common/types/accurityTypes';
import { FormikProps, useField } from 'formik';
import { createReferenceFieldFilter, createUniqueWithinCollectionFilters } from '../../../../common/referenceField/utils/filters';
import EntityAttributeSelection from '../misc/EntityAttributeSelection';
import { CollapsingGroup, DetailInlineGroup, EnumOption } from 'ts-components';
import { getBusinessModelMappingCountLabel, resetValuesOnBaseDataSetChange } from '../businessModelMappingDetailUtils';
import EntityAttributeJoin from '../misc/EntityAttributeJoin';
import { defaultTestDraggedItem, defaultTestDroppedItem } from '../../../../common/referenceField/utils/dragAndDrop';
import { DATA_SET_ICON, DATA_SET_LABEL, DATA_SET_TYPE, DATA_SET_TYPE_LABEL } from '../../../dataSet/types/dataSetTypes';
import EnumerationFieldWithFormik from '../../../../common/detail/formik/EnumerationFieldWithFormik';

interface BasedOnDataStructureProps {
  additionalFilters?: AccurityFilter[];
  formik: FormikProps<BusinessModelMapping>;
  multipleDataSetTypesAllowedFeature: boolean;
  dataSetTypeEnumOptions: EnumOption[];
}

const BasedOnDataStructure = ({
                                additionalFilters = [],
                                formik,
                                multipleDataSetTypesAllowedFeature,
                                dataSetTypeEnumOptions
                              }: BasedOnDataStructureProps) => {

  const [entitiesField] = useField<BusinessModelMappingTargetEntityAttributeDefinition[]>
  (BUSINESS_MODEL_MAPPING_TARGET_ENTITY_ATTRIBUTE_DEFINITIONS_FIELD_NAME);

  const dataSetId = formik.values.baseDataSet?.id;
  const entities = entitiesField.value.map(row => row?.entity);
  const selections = formik.getFieldProps(BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD).value;
  const joins = formik.getFieldProps(BUSINESS_MODEL_MAPPING_JOIN_ATTRIBUTE_ENTITY_FIELD).value;
  const isVersionBrowser = formik.status.isVersionBrowser;

  const entitiesFilter = [
    ...additionalFilters,
    ...createUniqueWithinCollectionFilters('name', Array.from(entities)),
  ];

  const dataStructureFilter = [
    ...additionalFilters,
    createReferenceFieldFilter('dataSet.id', formik.values.baseDataSet?.id),
  ];

  return (
    <>
      <CollapsingGroup
        name={'base'}
        title={BUSINESS_MODEL_MAPPING_BASE_LABEL}
        icon={BUSINESS_MODEL_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        {formik.values.businessModelMappingType &&
        <DetailInlineGroup
          childGridSizes={[8, 4]}
        >
          <ReferenceFieldWithFormik
            name={BUSINESS_MODEL_MAPPING_BASE_DATA_SET_FIELD}
            label={DATA_SET_LABEL}
            objectType={DATA_SET_TYPE}
            icon={DATA_SET_ICON}
            setValue={(newValue) => {
              resetValuesOnBaseDataSetChange(formik, false);
              formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_DATA_SET_FIELD, newValue);
            }}
          />
          {multipleDataSetTypesAllowedFeature && <EnumerationFieldWithFormik
            name={'baseDataSet.dataSetType'}
            label={DATA_SET_TYPE_LABEL}
            options={dataSetTypeEnumOptions}
            readOnly={true}
          />}
        </DetailInlineGroup>}
        <ReferenceFieldWithFormik<DataStructureReference>
          name={BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_FIELD}
          label={DATA_STRUCTURE_LABEL}
          icon={DATA_STRUCTURE_ICON}
          objectType={DATA_STRUCTURE_TYPE}
          additionalFilters={dataStructureFilter}
          setValue={(dataStructure) => {
            if (!dataSetId) {
              formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_DATA_SET_FIELD, dataStructure?.dataSet);
            }
            formik.setFieldValue(BUSINESS_MODEL_MAPPING_BASE_DATA_STRUCTURE_FIELD, dataStructure);
          }}
        />
      </CollapsingGroup>
      <CollapsingGroup
        name={BUSINESS_MODEL_MAPPING_TARGET_FIELD}
        title={BUSINESS_MODEL_MAPPING_TARGET_LABEL}
        icon={BUSINESS_MODEL_MAPPING_ICON}
        expandedByDefault={true}
        expandable={true}
      >
        <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_TARGET_ENTITY_ATTRIBUTE_DEFINITIONS_FIELD_NAME}
          title={getBusinessModelMappingCountLabel(entities,
            BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_LABEL,
            BUSINESS_MODEL_MAPPING_JOIN_TARGET_ENTITY_PLURAL_LABEL)}
          icon={ENTITY_ICON}
          testDraggedItem={defaultTestDraggedItem(ENTITY_TYPE)}
          testDroppedItem={defaultTestDroppedItem({ objectType: ENTITY_TYPE, additionalFilters: entitiesFilter })}
          onValidDrop={(addRow, rowItem) => addRow({ entity: rowItem })}
          rowValidation={(value) => value && value.entity && value.entity.id}
          renderRow={(elementName) =>
            <TargetEntityRow
              elementName={elementName}
              entitiesFilter={entitiesFilter}
            />
          }
        />
        <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ITEMS_FIELD}
          title={getBusinessModelMappingCountLabel(selections,
            BUSINESS_MODEL_MAPPING_TARGET_SELECTION_LABEL,
            BUSINESS_MODEL_MAPPING_TARGET_SELECTION_PLURAL_LABEL)}
          icon={BUSINESS_MODEL_MAPPING_TARGET_SELECTION_ICON}
          expandedByDefault={isVersionBrowser}
          rowValidation={(value) =>
            value && value.entity && value.entity.id &&
            value.attributeDefinition && value.attributeDefinition.id &&
            value.criteria
          }
          renderRow={(elementName, index) =>
            <EntityAttributeSelection
              elementName={elementName}
              index={index}
            />
          }
        />
        <CollectionFieldWithFormik
          name={BUSINESS_MODEL_MAPPING_JOIN_ATTRIBUTE_ENTITY_FIELD}
          title={getBusinessModelMappingCountLabel(joins,
            BUSINESS_MODEL_MAPPING_TARGET_JOIN_LABEL,
            BUSINESS_MODEL_MAPPING_TARGET_JOIN_PLURAL_LABEL)}
          icon={BUSINESS_MODEL_MAPPING_TARGET_JOIN_ICON}
          expandedByDefault={isVersionBrowser}
          rowValidation={(value) => value && value.entity && value.entity.id &&
            value.attributeDefinition && value.attributeDefinition.id &&
            value.targetEntity && value.targetEntity.id}
          renderRow={(elementName, index) =>
            <EntityAttributeJoin
              elementName={elementName}
              index={index}
              formik={formik}
            />
          }/>
      </CollapsingGroup>
    </>
  )
};

type TargetEntityRowProps = {
  elementName: string;
  entitiesFilter?: AccurityFilter[];
}

const TargetEntityRow = ({ elementName, entitiesFilter }: TargetEntityRowProps) => {
  return (
    <ReferenceFieldWithFormik
      name={elementName + '.entity'}
      label={ENTITY_LABEL}
      icon={ENTITY_ICON}
      objectType={ENTITY_TYPE}
      additionalFilters={entitiesFilter}
      hideRemoveButton={true}
      coloringOverwrite={elementName}
    />
  );
};

export default BasedOnDataStructure;
