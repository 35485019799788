import React from 'react';
import { AccurityButton, AccurityMenuItem, DetailBottomBar, DetailMenu } from 'ts-components';

import { User } from '../types/userTypes';
import { DetailBag } from '../../../common/detail/types/types';
import { FormikProps } from 'formik';

type UserDetailBottomBarProps = {
  formik: FormikProps<User>,
  detailBag: DetailBag<User>,
  lockAccount: () => void,
  unlockAccount: () => void,
}

export const UserDetailBottomBar = ({ detailBag, formik, lockAccount, unlockAccount }: UserDetailBottomBarProps) => {

  const showRemoveButton = formik.status.isUpdateDetail && detailBag.showRemoveAction && !detailBag.isRemoveDisabled;
  // see #24978
  // const accountStatus = formik.values.accountStatus;
  // const showLockUnlockButton = formik.status.isUpdateDetail && accountStatus !== AccountStatus.INVITED;
  const showReloadButton = formik.status.isUpdateDetail;

  return (
    <DetailBottomBar
      menu={showRemoveButton && (
        <DetailMenu>
          <AccurityMenuItem onClick={detailBag.removeAction}>
            Remove
          </AccurityMenuItem>
        </DetailMenu>
      )}
    >
      {/* see #24978 */}
      {/*{showLockUnlockButton && <DetailButton*/}
      {/*label={accountStatus === AccountStatus.ACTIVE ? 'Lock' : 'Unlock'}*/}
      {/*disabled={detailBag.isSaveDisabled}*/}
      {/*onClick={accountStatus === AccountStatus.ACTIVE ? lockAccount : unlockAccount}*/}
      {/*/>}*/}
      {showReloadButton && (
        <AccurityButton
          onClick={detailBag.reloadAction}
          disabled={detailBag.isReloadDisabled}
          color="secondary"
        >
          Reload
        </AccurityButton>
      )}
      <AccurityButton
        onClick={() => detailBag.saveAction()}
        disabled={detailBag.isSaveDisabled}
      >
        {getSaveButtonLabel(formik)}
      </AccurityButton>
    </DetailBottomBar>
  );
};

const getSaveButtonLabel = (formik: FormikProps<User>) => {
  if (!formik.status.isUpdateDetail) {
    return 'SEND INVITATION';
  } else {
    return 'SAVE';
  }
};
