import React from 'react';
import { useDataStructureListColumns } from './dataStructureListColumns';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_PLURAL_LABEL, DATA_STRUCTURE_TYPE } from '../types/dataStructureTypes';
import { getDefaultListSettings } from '../../../common/list/listUtils';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';

const DataStructureList = () => {
  const dataStructureColumns = useDataStructureListColumns();
  const dataStructureListSettings = getDefaultListSettings(DATA_STRUCTURE_TYPE, dataStructureColumns);

  return (
    <AccurityListContainer
      objectType={DATA_STRUCTURE_TYPE}
      title={DATA_STRUCTURE_PLURAL_LABEL}
      icon={DATA_STRUCTURE_ICON}
      columns={dataStructureColumns}
      defaultListSettings={dataStructureListSettings}
      allowBulkOperations={true}
    />
  );
};

export default DataStructureList;
