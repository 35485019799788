import { isEqual } from 'lodash';
import { Tag } from 'ts-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { requestBulkNotificationUpdate, requestBulkRemove, requestUpdateTags } from '../rest/rest';
import { showSnackbarMessage } from '../../userMessages/actions';
import { getBulkNotificationsUpdatedMessage } from '../messages';
import { pushBulkOperationInProgressNotification } from '../notifications/actions';
import { BulkOperationActionType, CoreEntityWithTags } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { resetState, selectIsInProgress, selectRows, selectSelectedEntities, setIsInProgress, unselectRows } from '../redux/bulkOperationsSlice';
import { setNotificationForCoreEntity } from '../../redux/commonReducers';
import { AccurityCoreEntity, EmailNotificationActionType } from '../../types/accurityTypes';

export const useBulkOperations = (isEnabled: boolean, listType: string, visibleRows: AccurityCoreEntity[]): BulkOperationsManager => {

  const dispatch = useDispatch();
  const selectedEntities = useSelector(selectSelectedEntities(listType), isEqual);
  const isInProgress = useSelector(selectIsInProgress);

  const [tags, setTags] = useState<Tag[]>([]);

  const allObjectsHaveNotificationsOn = selectedEntities.every(q => q.activeNotifications.OBJECT_UPDATE);

  useEffect(() => {
    const entitiesToUnselect = selectedEntities
      .filter(selected => !visibleRows.find(visible => visible.id === selected.id))
      .map(q => q.id);
    if (entitiesToUnselect.length > 0) {
      dispatch(unselectRows(entitiesToUnselect));
    }
  }, [visibleRows, selectedEntities, dispatch])

  // reset values when the list is changed
  useEffect(() => {
    dispatch(resetState());
    setTags([]);
  }, [listType, dispatch]);

  // update the collection of common tags when row selection changes
  useEffect(() => {
    setTags(getCommonTags(selectedEntities));
  }, [selectedEntities]);

  const handleRowSelectionChanged = useCallback((ids: string[]) => {
    dispatch(selectRows(ids))
  }, [dispatch, selectRows]);

  const handleBulkRemove = () => {
    dispatch(setIsInProgress(true));
    pushBulkOperationInProgressNotification(listType, BulkOperationActionType.DELETE);
    requestBulkRemove(selectedEntities, listType)
      .catch(() => dispatch(setIsInProgress(false)));
  };

  const handleBulkTagsUpdate = () => {
    dispatch(setIsInProgress(true));
    pushBulkOperationInProgressNotification(listType, BulkOperationActionType.UPDATE_TAGS);
    requestUpdateTags(selectedEntities, tags, listType)
      .catch(() => dispatch(setIsInProgress(false)));
  };

  const handleBulkNotificationUpdate = () => {
    dispatch(setIsInProgress(true));
    const nextState = !allObjectsHaveNotificationsOn;
    requestBulkNotificationUpdate(selectedEntities, nextState, listType)
      .then(() => {
        showSnackbarMessage(getBulkNotificationsUpdatedMessage(selectedEntities.length, listType, nextState));

        const updateStorePayload = selectedEntities.map(entity => ({
          notificationType: EmailNotificationActionType.OBJECT_UPDATE,
          enabled: nextState,
          id: entity.id,
          objectType: entity.objectType
        }));
        dispatch(setNotificationForCoreEntity(updateStorePayload));
      })
      .finally(() => dispatch(setIsInProgress(false)));
  };

  const resetTagsSelection = () => {
    setTags(getCommonTags(selectedEntities));
  };

  const selectedRowIds = useMemo(() => selectedEntities.map(entity => entity.id), [selectedEntities]);

  return {
    isEnabled,
    isBulkActionsVisible: selectedEntities.length > 0,
    isNotificationsActive: allObjectsHaveNotificationsOn,
    selectedRowsIds: selectedRowIds,
    tags: tags,
    isInProgress,
    objectHasTags: hasObjectTags(selectedEntities),
    handleRowSelectionChanged: handleRowSelectionChanged,
    handleTagsChange: (tags: Tag[]) => setTags(tags),
    toggleNotification: handleBulkNotificationUpdate,
    removeItems: handleBulkRemove,
    saveTags: handleBulkTagsUpdate,
    resetTagsSelection: resetTagsSelection
  };
};

export type BulkOperationsManager = {
  isEnabled: boolean;
  isBulkActionsVisible: boolean;
  isNotificationsActive: boolean;
  isInProgress: boolean;
  selectedRowsIds: string[];
  objectHasTags: boolean;
  tags: Tag[];
  handleTagsChange: (tags: Tag[]) => void;
  removeItems: () => void;
  toggleNotification: () => void;
  saveTags: () => void;
  handleRowSelectionChanged: (rowIds: string[]) => void;
  resetTagsSelection: () => void;
}

const hasObjectTags = (entities: CoreEntityWithTags[]) => {
  return entities.length > 0 && 'tags' in entities[0];
};

const getCommonTags = (entities: CoreEntityWithTags[]) => {
  return entities[0]?.tags?.filter(tag => {
    return entities.every(row => row.tags?.find(q => String(q.id) === String(tag.id)));
  }) || [];
};
