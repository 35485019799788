import React from 'react';

import {
  AdvancedCustomPropertyPropertyTypeOptions,
  CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD,
  CUSTOM_PROPERTY_ICON,
  CUSTOM_PROPERTY_TYPE,
  CustomProperty,
  CustomPropertyPropertyType,
  CustomPropertyPropertyTypeOptions,
  getObjectTypeLabel,
} from '../types/customPropertyTypes';
import NameFieldWithFormik from '../../../detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../detail/formik/DescriptionFieldWithFormik';
import { DetailHeader, EnumOption, NumericFormat, StaticField } from 'ts-components';
import HistoryGroupWithFormik from '../../../detail/formik/HistoryGroupWithFormik';
import AccurityDetailContainer from '../../../detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../detail/components/DefaultDetailBottomBar';
import ConfirmRemovalDetail from '../../../detail/commonFields/ConfirmRemovalDetail';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag } from '../../../detail/types/types';
import SingleLineFieldWithFormik from '../../../detail/formik/SinglelineFieldWithFormik';
import SwitchFieldWithFormik from '../../../detail/formik/SwitchFieldWithFormik';
import ReferenceFieldWithFormik from '../../../detail/formik/ReferenceFieldWithFormik';
import EnumerationFieldWithFormik from '../../../detail/formik/EnumerationFieldWithFormik';
import CollectionFieldWithFormik from '../../../detail/formik/CollectionFieldWithFormik';
import { CUSTOM_PROPERTY_GROUP_ICON, CUSTOM_PROPERTY_GROUP_TYPE } from '../../customPropertyGroup/types/customPropertyGroupTypes';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../versionBrowserField/components/VersionBrowserButton';
import { Feature } from '../../../userSettings/features/features';
import FeatureChecker from '../../../userSettings/components/FeatureChecker';
import { useHasFeature } from '../../../userSettings/hooks/features';
import DefaultValue from './defaultValue/DefaultValue';
import DetailTopBar from '../../../detail/components/DetailTopBar';

interface CustomPropertyDetailProps {
  id?: string,
  customPropertyObjectTypeOptions: EnumOption[],
  customPropertyTargetObjectTypeOptions: EnumOption[],
}

const CustomPropertyDetail = ({ id, customPropertyObjectTypeOptions, customPropertyTargetObjectTypeOptions }: CustomPropertyDetailProps) => {

  const hasFeature = useHasFeature();

  const customPropertyPropertyTypes = hasFeature(Feature.ADVANCED_CUSTOM_PROPERTIES) ? AdvancedCustomPropertyPropertyTypeOptions : CustomPropertyPropertyTypeOptions;

  return (
    <AccurityDetailContainer<CustomProperty>
      objectType={CUSTOM_PROPERTY_TYPE}
      id={id}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(detailBag, formik.values.forObjectType);
        } else {
          return (
            <>
              {getDetailFields(formik, customPropertyObjectTypeOptions, customPropertyTargetObjectTypeOptions, customPropertyPropertyTypes)}
              <DefaultDetailBottomBar objectType={CUSTOM_PROPERTY_TYPE} detailBag={detailBag}/>
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );

};

const getConfirmRemovalDetail = (detailBag: DetailBag<CustomProperty>, objectType: string) => {
  const objectTypeLabel = getObjectTypeLabel(objectType, true);
  return (
    <ConfirmRemovalDetail
      iconName={CUSTOM_PROPERTY_ICON}
      detailBag={detailBag}
    >
      <StaticField
        name={'removalDescription'}
        value={`Doing so will also remove the Custom Property fields from all existing ${objectTypeLabel} and you will irreversibly lose access to the content of those fields.`}
        inverted={true}
      />
    </ConfirmRemovalDetail>
  )
};

const getDetailFields = (formik: FormikProps<CustomProperty>,
                         customPropertyObjectTypeOptions: EnumOption[],
                         customPropertyTargetObjectTypes: EnumOption[],
                         customPropertyPropertyTypes: EnumOption[]) => {

  return (
    <>
      <DetailHeader iconName={CUSTOM_PROPERTY_ICON}>
        <NameFieldWithFormik label={'Custom Property Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={formik => getDetailFields(formik, customPropertyObjectTypeOptions, customPropertyTargetObjectTypes, customPropertyPropertyTypes)}
        />}
      />
      <EnumerationFieldWithFormik
        name={'forObjectType'}
        label={'Object Type'}
        options={customPropertyObjectTypeOptions}
        readOnly={formik.status.isUpdateDetail}
      />
      <EnumerationFieldWithFormik
        name={'propertyType'}
        label={'Property Type'}
        options={customPropertyPropertyTypes}
        readOnly={formik.status.isUpdateDetail}
        onChange={() => {
          formik.setFieldValue('options', []);
          formik.setFieldValue('defaultValue', null);
          formik.setFieldValue('targetObjectType', null);
        }}
      />
      {formik.values.propertyType === CustomPropertyPropertyType.RELATION &&
      <EnumerationFieldWithFormik
        name={'targetObjectType'}
        label={'Target Object Type'}
        options={customPropertyTargetObjectTypes}
        readOnly={formik.status.isUpdateDetail}
        onChange={(newValue) => {
          formik.setFieldValue('targetObjectType', newValue);
          formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD, undefined);
        }}
      />
      }
      {formik.values.propertyType === CustomPropertyPropertyType.ENUMERATION &&
      <CollectionFieldWithFormik
        name={'options'}
        title={'Enumeration Options'}
        rowValidation={(value) => !!value?.value}
        renderRow={elementName => {
          return (
            <SingleLineFieldWithFormik
              name={elementName + '.value'}
              label={'Enumeration Option'}
              onChange={() => formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD, null)}
              coloringOverwrite={elementName}
            />
          )
        }}
        onChange={() => formik.setFieldValue(CUSTOM_PROPERTY_DEFAULT_VALUE_FIELD, null)}
      />
      }
      <ReferenceFieldWithFormik
        name={'customPropertyGroup'}
        label={'Custom Property Group'}
        objectType={CUSTOM_PROPERTY_GROUP_TYPE}
        icon={CUSTOM_PROPERTY_GROUP_ICON}
      />
      <SwitchFieldWithFormik
        name={'multiselection'}
        label={'Multiselection'}
        readOnly={formik.initialValues.multiselection && formik.status.isUpdateDetail}
      />
      <FeatureChecker featureId={Feature.ADVANCED_CUSTOM_PROPERTIES}>
        <SwitchFieldWithFormik
          name={'mandatory'}
          label={'Mandatory'}
        />
      </FeatureChecker>
      <FeatureChecker featureId={Feature.ADVANCED_CUSTOM_PROPERTIES}>
        {formik.values.propertyType && <DefaultValue formik={formik}/>}
      </FeatureChecker>
      <SingleLineFieldWithFormik
        name={'valueOrder'}
        label={'Order'}
        type={'text'}
        format={NumericFormat.POSITIVE_NUMERIC}
      />

    </>
  );
};

export default CustomPropertyDetail;
