import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

export const useStatusListColumns = () => {

  const isOracle = useIsOracleDb();

  return [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description(!isOracle),
    columnFactory.createBooleanColumn('defaultIndicator', 'Default'),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification()
  ];
};
