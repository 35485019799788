import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import AccurityListContainer from '../../../common/list/redux/AccurityListContainer';
import React from 'react';
import { getDefaultListSettings, useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { PROCESS_ICON, PROCESS_PLURAL_LABEL, PROCESS_TYPE } from '../types/processTypes';
import { Feature } from '../../../common/userSettings/features/features';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { PROCESS_STEP_PLURAL_LABEL } from '../step/types/processStepTypes';
import { PROCESS_MAPPING_PLURAL_LABEL } from '../mapping/types/processMappingTypes';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';

const ProcessList = () => {
  const navigationController = useAccurityNavigation();
  const hasFeature = useHasFeature();
  const isOracle = useIsOracleDb();

  const processListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description(!isOracle),
    commonColumns.tags(navigationController),
    ...(hasFeature(Feature.PROCESS_STEPS) ?
      [columnFactory.createNumberColumn('childrenCounts.processStepsCount', PROCESS_STEP_PLURAL_LABEL,
        undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.PROCESS_MAPPINGS) ?
      [columnFactory.createNumberColumn('childrenCounts.processMappingsCount', PROCESS_MAPPING_PLURAL_LABEL,
        undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ];

  const processColumns = useValidColumns(processListColumns, GlossaryCustomPropertyObjectType.PROCESS);
  const processListSettings = getDefaultListSettings(PROCESS_TYPE, processColumns);

  return (
    <AccurityListContainer
      objectType={PROCESS_TYPE}
      title={PROCESS_PLURAL_LABEL}
      icon={PROCESS_ICON}
      columns={processColumns}
      defaultListSettings={processListSettings}
      allowBulkOperations={true}
    />
  );
};

export default ProcessList;
