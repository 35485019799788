import { Column, NumericFormat } from 'ts-components';
import { columnFactory, commonColumns } from '../../../common/list/commonColumns/columnDefinitions';
import {
  DataCatalogRelatedBusinessTermRelationTypeOptions,
  RELATED_BUSINESS_TERM_RELATION_TYPES_LABEL,
  RELATED_BUSINESS_TERMS_LABEL
} from '../../businessTerm/types/businessTermTypes';
import { useHasFeature } from '../../../common/userSettings/hooks/features';
import { useValidColumns } from '../../../common/list/listUtils';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { Feature } from '../../../common/userSettings/features/features';
import { useAccurityNavigation } from '../../../common/navigation/hooks';
import { DataFieldConstraintOptions } from '../types/dataFieldTypes';
import { DATA_SET_TYPE_LABEL, useDataSetTypeEnumOptions } from '../../dataSet/types/dataSetTypes';
import { BUSINESS_MODEL_MAPPING_PLURAL_LABEL } from '../../businessModelMapping/types/businessModelMappingTypes';
import { TECHNICAL_DATA_MAPPING_PLURAL_LABEL } from '../../technicalModelMapping/types/technicalDataMappingTypes';
import { useIsOracleDb } from '../../../common/appSettings/hooks/environmentInfoHooks';
import { DATA_STRUCTURE_TYPE_FIELD, DATA_STRUCTURE_TYPE_FIELD_LABEL, dataStructureTypeOptions } from '../../dataStructure/types/dataStructureTypes';
import { dataCatalogSyncDataFieldStatusOptions } from '../../../common/dataCatalogSync/types';

export const useDataFieldListColumns = () => {
  const navigationController = useAccurityNavigation();
  const hasFeature = useHasFeature();
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();
  const isOracle = useIsOracleDb();

  const dataFieldListColumns: Column[] = [
    commonColumns.id(),
    commonColumns.name(),
    commonColumns.description(!isOracle),
    commonColumns.tags(navigationController),
    columnFactory.createTextColumn('dataSet.name', 'Data Set'),
    columnFactory.createEnumColumn(`dataStructure.${DATA_STRUCTURE_TYPE_FIELD}`, DATA_STRUCTURE_TYPE_FIELD_LABEL, dataStructureTypeOptions, { hidden: true }),
    // todo uncomment when comments are implemented #26083
    // columnFactory.createTextColumn(DATA_FIELD_COMMENT_FIELD, DATA_FIELD_COMMENT_FIELD_LABEL, { hidden: true }),
    ...(hasFeature(Feature.MULTIPLE_DATA_SET_TYPES_ALLOWED) ?
      [columnFactory.createEnumColumn('dataSet.dataSetType', DATA_SET_TYPE_LABEL, dataSetTypeEnumOptions, { hidden: true })] : []),
    columnFactory.createTextColumn('dataStructure.name', 'Data Structure'),
    columnFactory.createTextColumn('dataType', 'Data Type', { hidden: true }),
    columnFactory.createEnumColumn('notNull', 'Not Null', DataFieldConstraintOptions, { hidden: true }),
    ...(hasFeature(Feature.BUSINESS_TERMS) ?
      [columnFactory.createEnumColumn('relatedBusinessTerms.relationType', RELATED_BUSINESS_TERM_RELATION_TYPES_LABEL, DataCatalogRelatedBusinessTermRelationTypeOptions, {
        hidden: true,
        sortable: false
      }),
        commonColumns.collection('relatedBusinessTerms.relatedBusinessTerm.name', RELATED_BUSINESS_TERMS_LABEL)] : []),
    ...(hasFeature(Feature.BUSINESS_MODEL_MAPPINGS) ?
      [columnFactory.createNumberColumn('childrenCounts.businessModelMappingsCount', BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
        undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    ...(hasFeature(Feature.TECHNICAL_DATA_MAPPINGS) ?
      [columnFactory.createNumberColumn('childrenCounts.technicalDataMappingsCount', TECHNICAL_DATA_MAPPING_PLURAL_LABEL,
        undefined, NumericFormat.POSITIVE_NUMERIC)] : []),
    commonColumns.createdTime(),
    commonColumns.createdBy(),
    commonColumns.lastChangedTime(),
    commonColumns.lastChangedBy(),
    commonColumns.status(),
    ...(hasFeature(Feature.PHYSICAL_DATA_SETS) ?
      [
        columnFactory.createEnumColumn('syncResult.status', 'Last Sync. Result', dataCatalogSyncDataFieldStatusOptions, { hidden: true }),
        columnFactory.createDateColumn('syncResult.syncRun.triggeredDate', 'Last Sync. Triggered at', { hidden: true }),
        columnFactory.createTextColumn('syncResult.syncRun.triggeredBy.name', 'Last Sync. Triggered by', { hidden: true }),
        columnFactory.createTextColumn('syncResult.syncRun.triggeredFrom.name', 'Last Sync. Triggered from', { hidden: true }),
      ] : []),
    commonColumns.objectUpdatesNotification(),
    commonColumns.commentsNotification(),
  ];

  return useValidColumns(dataFieldListColumns, GlossaryCustomPropertyObjectType.DATA_FIELD);

};
