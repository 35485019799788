import { AccurityCoreEntity } from '../types/accurityTypes';
import { Tag } from 'ts-components';

export interface CoreEntityWithTags extends AccurityCoreEntity {
  tags?: Tag[];
}

export enum BulkOperationActionType {
  DELETE = 'DELETE',
  UPDATE_TAGS = 'UPDATE_TAGS'
}

export const BULK_OPERATION_STORE_NAME = 'bulkOperations';

export type BulkOperationsState = {
  selectedRows: string[];
  isInProgress: boolean;
}
