import React from 'react';

import { DATA_SET_ICON, DATA_SET_TYPE, DATA_SET_TYPE_LABEL, DataSet, DataSetType, useDataSetTypeEnumOptions } from '../types/dataSetTypes';
import NameFieldWithFormik from '../../../common/detail/formik/NameFieldWithFormik';
import DescriptionFieldWithFormik from '../../../common/detail/formik/DescriptionFieldWithFormik';
import { DetailHeader, EnumOption, StaticField } from 'ts-components';
import HistoryGroupWithFormik from '../../../common/detail/formik/HistoryGroupWithFormik';
import StatusFieldWithFormik from '../../../common/detail/formik/StatusFieldWithFormik';
import { DATA_STRUCTURE_ICON, DATA_STRUCTURE_LABEL, DATA_STRUCTURE_PLURAL_LABEL, DATA_STRUCTURE_TYPE } from '../../dataStructure/types/dataStructureTypes';
import AccurityDetailContainer from '../../../common/detail/redux/AccurityDetailContainer';
import DefaultDetailBottomBar from '../../../common/detail/components/DefaultDetailBottomBar';
import ChildrenCountFieldWithFormik from '../../../common/detail/formik/ChildrenCountFieldWithFormik';
import { CONFIRM_REMOVE_SETTINGS_DETAIL, DetailBag, IT_CURRENTLY_LABEL } from '../../../common/detail/types/types';
import ConfirmRemovalDetail from '../../../common/detail/commonFields/ConfirmRemovalDetail';
import { DATA_FIELD_ICON, DATA_FIELD_LABEL, DATA_FIELD_PLURAL_LABEL, DATA_FIELD_TYPE } from '../../dataField/types/dataFieldTypes';
import CustomPropertyFieldsContainer from '../../../common/customProperties/CustomPropertyFieldsContainer';
import { GlossaryCustomPropertyObjectType } from '../../customProperties/types';
import { FormikProps } from 'formik';
import { VersionBrowserButton } from '../../../common/versionBrowserField/components/VersionBrowserButton';
import {
  BUSINESS_MODEL_MAPPING_ICON,
  BUSINESS_MODEL_MAPPING_LABEL,
  BUSINESS_MODEL_MAPPING_PLURAL_LABEL,
  BUSINESS_MODEL_MAPPING_TYPE
} from '../../businessModelMapping/types/businessModelMappingTypes';
import { Feature } from '../../../common/userSettings/features/features';
import FeatureChecker from '../../../common/userSettings/components/FeatureChecker';
import { ChildrenCountWording } from '../../../common/childrenCountField/types';
import FeatureCheckerOr from '../../../common/userSettings/components/FeatureCheckerOr';
import { DATA_ASSET_ICON, DATA_ASSET_LABEL, DATA_ASSET_PLURAL_LABEL, DATA_ASSET_TYPE } from '../../dataAsset/types/dataAssetTypes';
import ReferenceFieldWithFormik from '../../../common/detail/formik/ReferenceFieldWithFormik';
import { DATA_SOURCE_ICON, DATA_SOURCE_LABEL, DATA_SOURCE_TYPE } from '../../dataSource/types/dataSourceTypes';
import EnumerationFieldWithFormik from '../../../common/detail/formik/EnumerationFieldWithFormik';
import TagsFieldWithFormik from '../../../common/detail/formik/TagsFieldWithFormik';
import {
  TECHNICAL_DATA_MAPPING_ICON,
  TECHNICAL_DATA_MAPPING_LABEL,
  TECHNICAL_DATA_MAPPING_PLURAL_LABEL,
  TECHNICAL_DATA_MAPPING_TYPE
} from '../../technicalModelMapping/types/technicalDataMappingTypes';
import DetailTopBar from '../../../common/detail/components/DetailTopBar';
import { DataSetSyncContainer } from '../../../common/dataCatalogSync/components/DataSetSyncContainer';
import { useDataSetSyncDialogState } from '../../../common/dataCatalogSync/hooks/useDataSetSyncDialogState';
import { DataSyncCheckUpdatesButton } from '../../../common/dataCatalogSync/components/DataSyncCheckUpdatesButton';
import { DataSyncResultField } from '../../../common/dataCatalogSync/components/DataSyncResultField';
import ChildrenCountsContainer from '../../../common/detail/components/ChildrenCountsContainer';
import { useDetailReloadAfterSync } from '../../../common/dataCatalogSync/hooks/useDetailReloadAfterSync';

interface DataSetDetailProps {
  id?: string
}

const DataSetDetail = ({ id }: DataSetDetailProps) => {
  const dataSetTypeEnumOptions = useDataSetTypeEnumOptions();
  const { isOpen: isDataCatalogSyncOpen, setOpen: setIsDataCatalogSyncOpen } = useDataSetSyncDialogState(id);
  const { customHandleExternalUpdate } = useDetailReloadAfterSync(DATA_SET_TYPE, id);

  return (
    <AccurityDetailContainer<DataSet>
      objectType={DATA_SET_TYPE}
      id={id}
      customHandleExternalUpdate={customHandleExternalUpdate}
    >
      {(formik, detailBag) => {
        if (formik.status.settingsDetail === CONFIRM_REMOVE_SETTINGS_DETAIL) {
          return getConfirmRemovalDetail(formik, detailBag);
        } else {
          return (
            <>
              {getDetailFields(formik, dataSetTypeEnumOptions)}
              {isDataCatalogSyncOpen ? <DataSetSyncContainer
                dataSetId={formik.values.id}
                onClose={() => setIsDataCatalogSyncOpen(false)}
              /> : null}
              <DefaultDetailBottomBar
                objectType={DATA_SET_TYPE}
                detailBag={detailBag}
                customActionButtons={[
                  <DataSyncCheckUpdatesButton onClick={() => setIsDataCatalogSyncOpen(true)} key={'data-sync-button'}/>
                ]}
              />
            </>
          );
        }
      }}
    </AccurityDetailContainer>
  );

};

const getConfirmRemovalDetail = (formik: FormikProps<DataSet>, detailBag: DetailBag<DataSet>) => (
  <ConfirmRemovalDetail
    iconName={DATA_SET_ICON}
    detailBag={detailBag}
  >
    <StaticField
      name={'removalDescription'}
      value={IT_CURRENTLY_LABEL}
      inverted={true}
    />
    <FeatureChecker featureId={Feature.DATA_STRUCTURES}>
      <ChildrenCountFieldWithFormik
        singularLabel={DATA_STRUCTURE_LABEL}
        pluralLabel={DATA_STRUCTURE_PLURAL_LABEL}
        value={formik.values.childrenCounts.dataStructuresCount}
        iconName={DATA_STRUCTURE_ICON}
        pinIconName={DATA_SET_ICON}
        childFieldType={DATA_STRUCTURE_TYPE}
        inverted={true}
        childrenCountWording={ChildrenCountWording.HAS}
      />
    </FeatureChecker>
    <FeatureChecker featureId={Feature.DATA_FIELDS}>
      <ChildrenCountFieldWithFormik
        singularLabel={DATA_FIELD_LABEL}
        pluralLabel={DATA_FIELD_PLURAL_LABEL}
        value={formik.values.childrenCounts.dataFieldsCount}
        iconName={DATA_FIELD_ICON}
        pinIconName={DATA_SET_ICON}
        childFieldType={DATA_FIELD_TYPE}
        inverted={true}
        childrenCountWording={ChildrenCountWording.HAS}
      />
    </FeatureChecker>
    <FeatureCheckerOr featureIds={[Feature.BUSINESS_MODEL_MAPPINGS, Feature.DATA_ASSETS]}>
      <StaticField
        name={'removalDescription1'}
        value={'It is also:'}
        inverted={true}
      />
    </FeatureCheckerOr>
    <FeatureChecker featureId={Feature.DATA_ASSETS}>
      <ChildrenCountFieldWithFormik
        singularLabel={DATA_ASSET_LABEL}
        pluralLabel={DATA_ASSET_PLURAL_LABEL}
        value={formik.values.childrenCounts.dataAssetsCount}
        iconName={DATA_ASSET_ICON}
        pinIconName={DATA_SET_ICON}
        childFieldType={DATA_ASSET_TYPE}
        inverted={true}
        childrenCountWording={ChildrenCountWording.RELATED_TO}
      />
    </FeatureChecker>
    <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
      <ChildrenCountFieldWithFormik
        singularLabel={BUSINESS_MODEL_MAPPING_LABEL}
        pluralLabel={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
        value={formik.values.childrenCounts.businessModelMappingsCount}
        iconName={BUSINESS_MODEL_MAPPING_ICON}
        pinIconName={DATA_SET_ICON}
        childFieldType={BUSINESS_MODEL_MAPPING_TYPE}
        inverted={true}
        childrenCountWording={ChildrenCountWording.RELATED_TO}
      />
    </FeatureChecker>
  </ConfirmRemovalDetail>
);

const getDetailFields = (formik: FormikProps<DataSet>,
                         dataSetTypeEnumOptions: EnumOption[]) => {
  return (
    <>
      <DetailHeader iconName={DATA_SET_ICON}>
        <NameFieldWithFormik label={'Data Set Name'}/>
        <DetailTopBar/>
      </DetailHeader>
      <DescriptionFieldWithFormik/>
      <HistoryGroupWithFormik
        VersionBrowserButton={<VersionBrowserButton
          getDetailFields={(formik) => getDetailFields(formik, dataSetTypeEnumOptions)}
        />}
      />
      <StatusFieldWithFormik/>
      <TagsFieldWithFormik objectType={DATA_SET_TYPE}/>
      <EnumerationFieldWithFormik
        name={'dataSetType'}
        label={DATA_SET_TYPE_LABEL}
        options={dataSetTypeEnumOptions}
        readOnly={formik.status.isUpdateDetail}
      />
      {formik.values.dataSetType === DataSetType.PHYSICAL
        ? <ReferenceFieldWithFormik
          name={'dataSource'}
          label={DATA_SOURCE_LABEL}
          objectType={DATA_SOURCE_TYPE}
          icon={DATA_SOURCE_ICON}
          maxResult={1000}
          readOnly={formik.status.isUpdateDetail}
        />
        : null}
      <DataSyncResultField/>
      <CustomPropertyFieldsContainer forObjectType={GlossaryCustomPropertyObjectType.DATA_SET}/>
      <ChildrenCountsContainer>
        {(childrenCounts) => <>
          <FeatureChecker featureId={Feature.DATA_STRUCTURES}>
            <ChildrenCountFieldWithFormik
              singularLabel={DATA_STRUCTURE_LABEL}
              pluralLabel={DATA_STRUCTURE_PLURAL_LABEL}
              value={childrenCounts.dataStructuresCount}
              iconName={DATA_STRUCTURE_ICON}
              pinIconName={DATA_SET_ICON}
              childFieldType={DATA_STRUCTURE_TYPE}
              childrenCountWording={ChildrenCountWording.HAS}
            />
          </FeatureChecker>
          <FeatureChecker featureId={Feature.DATA_FIELDS}>
            <ChildrenCountFieldWithFormik
              singularLabel={DATA_FIELD_LABEL}
              pluralLabel={DATA_FIELD_PLURAL_LABEL}
              value={childrenCounts.dataFieldsCount}
              iconName={DATA_FIELD_ICON}
              pinIconName={DATA_SET_ICON}
              childFieldType={DATA_FIELD_TYPE}
              childrenCountWording={ChildrenCountWording.HAS}
            />
          </FeatureChecker>
          <FeatureChecker featureId={Feature.DATA_ASSETS}>
            <ChildrenCountFieldWithFormik
              singularLabel={DATA_ASSET_LABEL}
              pluralLabel={DATA_ASSET_PLURAL_LABEL}
              value={childrenCounts.dataAssetsCount}
              iconName={DATA_ASSET_ICON}
              pinIconName={DATA_SET_ICON}
              childFieldType={DATA_ASSET_TYPE}
              childrenCountWording={ChildrenCountWording.REFERENCED_BY}
            />
          </FeatureChecker>
          <FeatureChecker featureId={Feature.BUSINESS_MODEL_MAPPINGS}>
            <ChildrenCountFieldWithFormik
              singularLabel={BUSINESS_MODEL_MAPPING_LABEL}
              pluralLabel={BUSINESS_MODEL_MAPPING_PLURAL_LABEL}
              value={childrenCounts.businessModelMappingsCount}
              iconName={BUSINESS_MODEL_MAPPING_ICON}
              pinIconName={DATA_SET_ICON}
              childFieldType={BUSINESS_MODEL_MAPPING_TYPE}
              childrenCountWording={ChildrenCountWording.REFERENCED_BY}
            />
          </FeatureChecker>
          <FeatureChecker featureId={Feature.TECHNICAL_DATA_MAPPINGS}>
            <ChildrenCountFieldWithFormik
              singularLabel={TECHNICAL_DATA_MAPPING_LABEL}
              pluralLabel={TECHNICAL_DATA_MAPPING_PLURAL_LABEL}
              value={childrenCounts.technicalDataMappingsCount}
              iconName={TECHNICAL_DATA_MAPPING_ICON}
              pinIconName={DATA_SET_ICON}
              childFieldType={TECHNICAL_DATA_MAPPING_TYPE}
              childrenCountWording={ChildrenCountWording.REFERENCED_BY}
            />
          </FeatureChecker>
        </>}
      </ChildrenCountsContainer>
    </>
  );
};

export default DataSetDetail;
